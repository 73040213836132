import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type ChartApiResponse = {
  total_completed_bookings: number,
  total_bookings: number,
  monthly_sales_data: {
    data: {
      labels: string[]
      data: number[],
    }
  },
  online_offline_bookings_per_year: {
    online_bookings: number,
    offline_bookings: number
  },
  get_bookings_by_geolocation: {
    total_bookings_locally: number,
    total_bookings_internationally: number
  },
  booking_by_categories: {
    id: number,
    name: string,
    booked_slots_count: number,
  }[],
  bookings_by_gender: {
    female: number,
    male: number
  },
};

const thisYear = 'This Year';
const currentYear = new Date().getFullYear();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  totalReport: any;
  fullReport: ChartApiResponse | null;
  data: any;
  token: any;
  googleChartData: any;
  activeAnalysisTypeIndex: number;
  activeTabIndex: number,
  isLoading: boolean,
  year: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      totalReport: {
        labels: [],
        data: [],
        barColors: [],
      },
      fullReport: null,
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      activeAnalysisTypeIndex: 0,
      activeTabIndex: 0,
      isLoading: false,
      year: thisYear
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const calledMessageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        if (calledMessageId === this.apiGetDataCallId) {
          this.setState({
            fullReport: responseJson,
            isLoading: false
          });
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getFullReport(currentYear);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  authToken = localStorage.getItem("authToken");

  getFullReport = async (year: number | string) => {
    this.setState({ isLoading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.spaStatisticAPiEndPoint}?token=${this.authToken}&type=${configJSON.spaStatisticType}&year=${year}&time_frame=yearly`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setActiveAnalysisTypeStyle = (index: number) =>
    this.state.activeAnalysisTypeIndex === index ? ({
      backgroundColor: '#B6E6DF',
      borderColor: '#398378'
    }) : {}

  setActiveAnalysisTypeIndex = (index: number) => this.setState({ activeAnalysisTypeIndex: index });
  setActiveTabIndex = (index: number) => this.setState({ activeTabIndex: index });

  getA11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  getYearOptions() {
    const startYear = 2023;

    const years = [{
      name: thisYear,
      value: String(currentYear)
    }];

    let initYear = currentYear - 1;

    while (initYear >= startYear) {
      years.push({
        name: String(initYear),
        value: String(initYear)
      });
      initYear--;
    }
    return years
  }

  setYear(event: { target: { name?: string | undefined; value: unknown; } }) {
    const year = String(event.target.value);

    this.setState({ year });
    this.getFullReport(year)
  }
  // Customizable Area End
}
