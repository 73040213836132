import { Box, Link } from '@material-ui/core';
import React from 'react';
import { getTranslationConfig } from './helpers';
class MapLocation extends React.Component<{ address: string | undefined }, {}> {
  render() {
    const { lang } = getTranslationConfig();

    const address = this.props.address || '';
    const url = `https://maps.google.com/maps?width=100%25&height=600&hl=${lang}&q=${address}&t=&z=14&ie=UTF8&iwloc=B&output=embed`;
    const mapUrl = `https://www.google.com/maps/place/${address}&hl=${lang}`

    return (
      <Box position='relative' width='100%'>
        <Link
          href={mapUrl}
          target='blank'
          style={{
            height: '100%',
            width: '100%',
            display: 'block',
            position: 'absolute'
          }}
        />
        <iframe
          width="100%"
          height="400"
          style={{
            margin: '0',
            border: 'none'
          }}
          src={url}
        />
      </Box>
    );
  }
}

export default MapLocation;