import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from '../../../framework/src/Utilities';
import React from 'react';
import { DateObject } from 'react-multi-date-picker';
import { addZeroToTime } from '../../../components/src/datesRangeHelper';
import { Catalogue } from '../../../blocks/settings1/src/Settings1Controller';
import { CircularProgress } from '@material-ui/core';

type Service = {
  id: number,
  name: string
};

enum DiscountType {
  percentage = 'percentage',
  fixedAmount = 'fixed amount'
};

export type Coupon = {
  id: number;
  attributes: {
    title: string;
    description: string;
    code: string;
    discount_type: string;
    discount: string;
    valid_from: string;
    valid_to: string;
    min_cart_value: string;
    max_cart_value: string;
    services: Service[],
    usage_type: string;
    usage_limit: null | number
  }
};

type CouponFormData = {
  couponName: string,
  couponCode: string,
  discountType: string,
  percentage: string | undefined,
  validFrom: string,
  validTo: string,
  service: { id: number, name: string },
  minPurchase: string | undefined,
  usageType: string,
  usageLimit: null | number
};

export const allServices = 'All Services';

export const initCouponFormValues = {
  couponName: '',
  couponCode: '',
  discountType: '',
  percentage: undefined,
  validFrom: '',
  validTo: '',
  service: { id: 0, name: '' },
  minPurchase: undefined,
  usageType: '',
  usageLimit: null
};

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  searchedCoupons: Coupon[];
  coupons: Coupon[];
  pageCoupons: Coupon[];
  popoverAnchors: any[];
  currentPage: number;
  lastPage: number;
  searchString: string;
  calendarDays: any;
  cataloguesList: {
    id: number | string,
    name: string
  }[],
  showCouponFormModal: boolean;
  couponId: number,
  isUpdateMode: boolean,
  couponIndex: number
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  couponsCount = 8;
  apiGetCouponsId = '';
  authToken = localStorage.getItem("authToken");
  isSearchMode = false;
  currency = '';
  apiGetCataloguesCallId = '';
  saveUpdateCouponsApiCallId = '';
  deleteCouponsApiCallId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      searchedCoupons: [],
      coupons: [],
      pageCoupons: [],
      popoverAnchors: [],
      currentPage: 1,
      lastPage: 0,
      searchString: '',
      calendarDays: [],
      cataloguesList: [],
      showCouponFormModal: false,
      couponId: 0,
      isUpdateMode: false,
      couponIndex: 0
    };

    (async () => {
      this.currency = await getStorageData('currency');
    })();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getCatalogues();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCouponsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ token: this.authToken })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.couponsApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.couponsApiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ loading: false });

      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson?.data?.attributes?.errors || webErrorReponse) {
        this.setState({
          errorMsg: webErrorReponse,
          loading: false
        });
        this.showAlert(webConfigJSON.commonErrorText, webConfigJSON.commonErrorDescription);
      } else {
        this.handleSuccesResponse(webApiRequestCallId, webResponseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const searchStr = this.state.searchString;
    const coupons = this.state.coupons;
    const isSearchChanged = prevState.searchString !== searchStr;

    if (isSearchChanged) {
      this.isSearchMode = Boolean(searchStr) && searchStr.length > 2;
      if (this.isSearchMode) {
        const searchedCoupons = coupons.filter((coupon: Coupon) => {
          const { title, code } = coupon.attributes;
          return title.includes(searchStr) || code.includes(searchStr)
        });
        this.setState({
          searchedCoupons,
          pageCoupons: searchedCoupons.slice(0, this.couponsCount),
          currentPage: 1,
          lastPage: this.getLastPage(searchedCoupons)
        })
      } else {
        this.setState({
          searchedCoupons: [],
          pageCoupons: coupons.slice(0, this.couponsCount),
          lastPage: this.getLastPage(coupons)
        })
      }
    }
  }

  handleSuccesResponse = (webApiRequestCallId: string, webResponseJson: any) => {
    if (webApiRequestCallId === this.apiGetCouponsId) {
      const sortedCoupons = webResponseJson?.data.reverse();

      this.setState({
        coupons: sortedCoupons,
        pageCoupons: sortedCoupons.slice(0, this.couponsCount),
        currentPage: 1,
        lastPage: this.getLastPage(sortedCoupons)
      })
    }
    if (webApiRequestCallId === this.apiGetCataloguesCallId) {
      const cataloguesList = (webResponseJson?.data as Catalogue[])
        .map((catalogue) => ({
          id: catalogue.id,
          name: catalogue.attributes.name
        }));
      cataloguesList.unshift({ id: 0, name: allServices });
      this.setState({ cataloguesList });
    }
    if (webApiRequestCallId === this.saveUpdateCouponsApiCallId) {
      this.getDashboardData();
      this.setState({ showCouponFormModal: false });
    }
    if (webApiRequestCallId === this.deleteCouponsApiCallId) {
      this.getDashboardData();
    }
  }

  async getCatalogues() {
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.userCataloguesApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ token: this.authToken })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.userCataloguesGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async saveUpdateCoupons(body: any) {
    this.setState({ loading: true });
    const isUpdateMode = this.state.isUpdateMode;

    const apiMethod = isUpdateMode ? webConfigJSON.couponsApiPATCHMethod : webConfigJSON.couponsApiPOSTMethod;
    const parameter = isUpdateMode ? `/${this.state.couponId}` : '';

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveUpdateCouponsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": webConfigJSON.dashboarContentType,
        token: this.authToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.couponsApiUrl}${parameter}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteCoupons() {
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCouponsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ token: this.authToken })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.couponsApiUrl}/${this.state.couponId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.couponsApiDELETEMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  callSaveUpdateCoupons = (formData: CouponFormData) => {
    // Remove fake "All Services" entity with 0 id to correct request body data
    const services = formData.service.name === allServices ? this.state.cataloguesList.slice(1) : [formData.service]
    const catalogueIds = services.map((service) => service.id);

    const {
      couponName,
      couponCode,
      discountType,
      percentage,
      validFrom,
      validTo,
      minPurchase
    } = formData;

    const couponRegBody = {
      data: {
        title: couponName,
        code: couponCode,
        discount_type: discountType.toLowerCase(),
        discount: percentage,
        valid_from: validFrom,
        valid_to: validTo,
        min_cart_value: minPurchase,
        catalogue_ids: catalogueIds,
        usage_type: formData.usageType.toLowerCase(),
        usage_limit: formData.usageLimit,
      }
    };
    this.saveUpdateCoupons(JSON.stringify(couponRegBody));
  }

  getLastPage = (coupons: Coupon[]) => Math.ceil(coupons.length / this.couponsCount)

  setPopoverAnchor = (index: number, event?: React.MouseEvent<SVGSVGElement | HTMLElement>) => {
    const popoverAnchors = this.state.popoverAnchors;
    popoverAnchors[index] = event?.currentTarget || null;
    this.setState({ popoverAnchors })
  };

  getCoupons = () => this.isSearchMode ? this.state.searchedCoupons : this.state.coupons;

  getCouponsIndexes = () => {
    const startIndex = this.state.currentPage * this.couponsCount;
    const endIndex = startIndex + this.couponsCount;
    return {
      startIndex,
      endIndex
    }
  }

  goForward = () => {
    const coupons = this.getCoupons();
    const { startIndex, endIndex } = this.getCouponsIndexes();

    const couponsResult = coupons.slice(startIndex, endIndex);
    if (couponsResult.length) {
      this.setState({
        currentPage: this.state.currentPage + 1,
        pageCoupons: couponsResult,
      });
    }
  };

  goBack = () => {
    const coupons = this.getCoupons();
    const endIndex = (this.state.currentPage - 1) * this.couponsCount;
    const startIndex = endIndex - this.couponsCount;
    const couponsResult = coupons.slice(startIndex, endIndex);
    if (couponsResult.length) {
      this.setState({
        currentPage: this.state.currentPage - 1,
        pageCoupons: couponsResult,
      });
    }
  };

  getGoBackColor = () => (this.state.currentPage > 1 ? '#398378' : '#94A3B8');

  getGoForwardColor = () =>
    this.state.currentPage * this.couponsCount <
      this.state.coupons.length
      ? '#398378'
      : '#94A3B8';

  getDiscountString = (discount: string, discountType: string) =>
    `${discountType === DiscountType.fixedAmount ? this.currency : ''}${Number(discount).toFixed()}${discountType === DiscountType.percentage ? '%' : ''} off`;

  getDateString = (date: string) =>
    new Date(date).toLocaleString('en-UK', {
      day: '2-digit',
      month: 'short',
      year: "numeric",
    })

  setSearchString = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ searchString: event.target.value });

  renderInitSelect = (value: string, placeholder: string) =>
    value
      ? undefined
      : () => (
        <div style={{ color: '#94A3B8' } as React.CSSProperties}>
          {placeholder}
        </div>
      );

  getErrorText(touched?: any, errorText?: string) {
    return touched && errorText;
  }

  getErrorBorder = (isError: boolean) => ({
    color: '#000000',
    ...(isError && { border: "1px solid #DC2626" })
  })

  getFullDate = (multiDateObj: any) => {
    const { year, month, day } = multiDateObj;
    return `${year}-${addZeroToTime(Number(month))}-${addZeroToTime(
      Number(day)
    )}`;
  };

  convertDateToIso = (date: Date) => date.toISOString().split("T")[0];

  getUniqArray = (targetArray: string[]) => Array.from(new Set(targetArray))

  setDates = (dates: DateObject[][], callback: (dates: string[]) => void) => {
    const oneDayMs = 86400000;
    const allDatesRange: string[] = [];
    Array.isArray(dates) &&
      dates.forEach((dateObject: any) => {
        if (dateObject.length) {
          const beginDate = new Date(this.getFullDate(dateObject[0]));

          allDatesRange.push(this.convertDateToIso(beginDate));

          if (dateObject.length - 1) {
            const endDate = new Date(this.getFullDate(dateObject[1]));
            const datesRange =
              ((endDate as any) - (beginDate as any) + oneDayMs) / oneDayMs;

            Array.from(Array(datesRange)).forEach((_, index) => {
              const beginDataCopy = new Date(beginDate);
              beginDataCopy.setDate(beginDataCopy.getDate() + index);
              allDatesRange.push(this.convertDateToIso(beginDataCopy));
            });
          }
        }
      });

    const openingDatesList = this.getUniqArray(allDatesRange).sort();
    callback(openingDatesList);
  };

  getAutocompleteStyle = (isError: boolean) => ({
    padding: "12px 16px",
    borderRadius: "8px",
    minHeight: "56px",
    ...(isError && { border: "1px solid red" }),
  });

  isFirstUpdate = (couponName: string) => !couponName && this.state.isUpdateMode;
  isFirstCreate = (firstFormLoadRef: React.RefObject<{}>) => !(firstFormLoadRef.current || this.state.isUpdateMode);

  detectUsageType = (discountTypeInit: string) => discountTypeInit.toLocaleLowerCase() === 'percentage' ? 'Percentage' : 'Fixed Amount';
  getService = (resServices: Service[]) => resServices.length > 1 ? { id: 0, name: allServices } : resServices[0];
  showBtnWithLoading = () => this.state.loading ? <CircularProgress style={{ color: '#FFFFFF' }} /> : 'Done';

  setCalendarDays = (calendarDays: any) => this.setState({ calendarDays });
  setCouponFormModal = (showCouponFormModal: boolean) => this.setState({ showCouponFormModal });
  setCouponId = (couponId: number) => this.setState({ couponId });
  setIsUpdateMode = (isUpdateMode: boolean) => this.setState({ isUpdateMode });
  setCouponIndex = (couponIndex: number) => this.setState({ couponIndex });
  // Customizable Area End
}
