import React from "react";

import {
  Box,
  Button,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
// Customizable Area End

import SubscriptionbillingController, { Props } from "./SubscriptionbillingController";
import { imgSpaWarrior } from './assets';

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} position='relative' className='landing-venue-block'>
          <AppHeader context={this} />
          <Box
            className='search-location-block'
            height='calc(100vh - 56px)'
            boxSizing='border-box'
            display='flex'
            justifyContent='center'
            alignItems='center'
            py={6}
            px='140px'
          >
            <Box width='360px' textAlign='center' position='relative' height='100%'>
              <img src={imgSpaWarrior} alt='subscription plan' />
              <Typography style={{ fontWeight: 700, margin: '16px 0' }}>
                Congratulations! You have been verified!
              </Typography>
              <Typography>
                SPAroof welcomes you aboard! 🎉 Set up your spa and let the bookings roll in!
              </Typography>
              <Box
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
              >
                <Button
                  data-test-id={"btnAcceptTerms"}
                  type='submit'
                  variant="contained"
                  color="primary"
                  className="agreeButton"
                  style={{
                    fontSize: '16px',
                    padding: '8px 16px',
                    width: '327px',
                    height: '56px'
                  }}
                  onClick={this.gotoSubscriptionPlans}
                >
                  Get Subscription
                </Button>
                <Box mt={8} mb={2}>
                  <Typography
                    className="account"
                    style={{
                      color: '##334155'
                    }}
                  >
                    Back to
                    <span
                      data-test-id='login-link'
                      className="signUp"
                      style={{
                        color: '#145249',
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}
                      onClick={this.gotoLogin}
                    >Login</span></Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
