import React from "react";

//Customizable Area Start
import { Box, MobileStepper, Button,Snackbar, InputLabel, TextField, ThemeProvider, Typography, createTheme, styled, } from "@material-ui/core";
import { headerLogoImg ,backgroundSpaImg} from "./assets";
import WebForgotPasswordController, {Props} from "./WebForgotPasswordController";
import { getTranslationConfig } from '../../../components/src/helpers';

const styles = {
  paperCont: {
      backgroundImage: `url(${backgroundSpaImg})`,
      width:'100%',
      height:'1920px',
      backgroundSize: 'cover',
  }
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const ForgotPasswordView = styled(Box)({
  width: '45%',
  borderRadius: '24px 24px 0px 0px',
  background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  position:'absolute',
  right:'70px',
  bottom:'0px',
  '& .mainView':{
    padding: '40px',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    background:'var(--Neutrals-Cool-gray-50, #F8FAFC)'
  },
  '& .formHeader':{
    'display': 'flex',
    padding: '27px 172px 26px 172px',
    borderRadius: '24px 24px 0px 0px',
    background: 'var(--Primary, #398378)',
    alignItems:'center !important',
    justifyContent: 'center',
  },
  '& .logo':{
    width:'176px',
    height:'72px'
  },

  '& .inputeStyle':{
    borderRadius: '8px !important',
    border: '2px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)'
  },
  '& .testLabel':{
    paddingTop:'18px',
    paddingBottom:'12px',
    fontWeight:'700',
    color:'#334155',
    fontSize:'16px',
    fontFamily:'Ubuntu',
    
  },
  '& .testStar':{
    color:'red',
    paddingTop:'18px',
    paddingBottom:'12px',
    paddingLeft:'5px',
    fontWeight:'700',
    fontSize:'16px',
    fontFamily:'Ubuntu',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
    border:'2px solid #398378 !important'
  },
  '& .PrivateNotchedOutline-root-10':{
      border:'0px'
  },
  '& .button':{
    background:'#398378',
    padding:'16px',
    textTransform:'none',
    color:'#FFF',
    fontWeight: '700',
    fontSize: '16px'
  },
  '& .MuiButton-textPrimary':{
      color:'#FFFFF'
  },
  '& .txtRemember':{
    color:'#334155'
  },
  '& .MuiBox-root-19':{
    color:'#1B5950 !important'
  },
  '& .MuiBox-root-14':{
    color:'#334155 !important'
  },
  '& .PrivateSwitchBase-root-18':{
    paddingLeft:'0px'
  },
  '& .forgotPwdText':{
    fontSize: "14px",
    color: "#1B5950",
    fontWeight: "400",
    marginTop: "5px",
  },
  '& .signUp':{
    fontSize:'16px',
    color:'#0F172A',
    fontWeight:'bold',
    fontFamily:'Ubuntu',
    paddingLeft:'5px'

  },
  '& .MuiSnackbarContent-root':{
    background:'#FEE2E2',
    color:'#DC2626',
    borderLeft:'4px solid #DC2626',
    borderRadius: '4px',
    width: '405px',
  },
  '& .remember':{
    fontSize: "14px",
    color: "#334155",
    fontWeight: "400",
    marginTop: "5px",
  },
  '& .accountText':{
    fontSize:'16px',
    color:'#0F172A',
    fontWeight:400,
    fontFamily:'Ubuntu'

  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    marginTop: '20px'
  },
  '& .MuiPaper-root':{
    background:'#D9EEEB',
    width:'180px',
    left:'860px'
  },
  '& .PrivateSwitchBase-root-25':{
    paddingLeft:'0px'
  },
  '& .MuiMobileStepper-dotActive':{
    background:'red !important'
  }
  // MuiOutlinedInput-root.Mui-focused MuiOutlinedInput-notchedOutline

});
export const StepperView = styled(Box)({
  '& .MuiMobileStepper-dotActive':{
    background:'#FFF !important'
   },
   '& .MuiMobileStepper-dot':{
    background:'#398378'
   }
})
const TypographyView = styled(Typography)({
'& .heading':{
  fontSize:'36px',
  fontFamily:'Ubuntu',
  fontWeight:700,
  color:'#FFF'
},
'& .title':{
  color: `var(--Basic-Black, #000)`,
fontFamily: 'Ubuntu',
fontSize: '24px',
fontWeight: 700,
},
'& .link':{
  color: '#0F172A',
  fontFamily: 'Ubuntu',
  fontSize: '16px',
  fontWeight: 400,
  paddingTop:'20px'
},
'& .description':{
  fontSize:'16px',
  fontFamily:'Ubuntu',
  fontWeight:400,
  color:'#FFF'
},
'& .txtErrorMsg':{
  color:'#DC2626',
  fontSize:'12px',
  fontWeight:400,
  fontFamily:'Ubuntu',
  paddingTop:'5px'
},
'& .readAndAgree':{
  color:'#0F172A',
  fontFamily: 'Ubuntu',
  fontSize: '12px'
},
'& .termsAndCond':{
  color:'#50C',
  fontFamily: 'Ubuntu',
  fontSize: '12px',
  paddingRight:'2px',
  textDecoration:'underline'
}
});
//Customizable Area End

export default class ForgotPassword extends WebForgotPasswordController{
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    
    //Customizable Area End
  }

  forgotPasswordForm = () =>{
    const { t, dir } = getTranslationConfig();

    return(
      <ForgotPasswordView dir={dir}>
      <Box className="formHeader">
        <img src={`${headerLogoImg}`} className="logo" alt="Logo" />
      </Box>
      <Box className="mainView">
        <TypographyView>
          <Typography className="title">{t['forgot-password-form-heading']}</Typography>
          <Typography className="link">{t['forgot-password-form-description']}</Typography>
        </TypographyView>
        <Snackbar 
          open={this.state.open}
          message={this.state.apiMsg}
          key={'top' + 'right'}
          onClose={this.handleClose}
          />
       <Box >
         <Box sx={{flexDirection:'row',paddingTop:'15px',display:'flex',}}>
          <InputLabel className="testLabel">{t['forgot-password-form-email']}</InputLabel>
          <InputLabel className="testStar">{this.star}</InputLabel>
         </Box>
          <TextField
            data-test-id="txtInputEmail"
            placeholder={t['forgot-password-form-email-placeholder']}
            fullWidth={true}
            value={this.state.emailValue}
            onChange={(e)=>this.setEmail(e.target.value)}
            variant="outlined"
            className="inputeStyle"
            error={this.state.emailError}
          />
         {this.state.emailError&& <TypographyView>
           <Typography className="txtErrorMsg">{t['forgot-password-form-error-email']}</Typography>
          </TypographyView>}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 0px 0px",
          }}
        >
          <Button
            data-test-id={"btnSenLink"}
            className="button"
            fullWidth={true}
            onClick={this.handleButtonClick}
          >
            {t['forgot-password-form-recovery-btn']}
          </Button>
        </Box>
        <TypographyView>
          <Box sx={{flexDirection:'row',display:'flex'}} pt={2} pb={2}>
            <Typography className="account"> {t['forgot-password-form-back']} </Typography><Typography className="signUp" onClick={()=>this.goToLogin()}>{t['forgot-password-form-destination']}</Typography>
          </Box>
        </TypographyView>
        </Box>
    </ForgotPasswordView>
 
    )
  }
  render() {
    const { t, dir } = getTranslationConfig();

    return (
      <ThemeProvider theme={theme}>
        <div style={styles.paperCont}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent:'space-between',
              padding: "10px 73px 0px 120px",
              flexDirection: "row",
              
            }}
          >
            <Box dir={dir} width="40%" sx={{position:'absolute',bottom:'140px',paddingRight:'0px'}}>
              <TypographyView>
                 <Typography className="heading"> {t['login-heading']}</Typography>
                 <Typography className="description">{t['login-description']}</Typography>
              </TypographyView>
             <StepperView>
              <MobileStepper
                steps={3}
                position="static"
                variant="dots"
                activeStep={0}
                className="stepper"
                style={{background:'transparent',paddingTop:10,paddingLeft:0}}
                backButton={undefined} nextButton={undefined} 
                />
              </StepperView>
            </Box>
            {
              this.forgotPasswordForm()
            }
           </Box>
        </div>
      </ThemeProvider>
    );
  }
}