import React from "react";

import {
  Box,
  Button,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
// Customizable Area End

import SubscriptionbillingController, { Props } from "./SubscriptionbillingController";
import { imgAdvancedPlan, imgBasicPlan } from './assets';

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { name, type } = this.state.congratsData;
    const isUpdateJourney = this.state.paymentModalUpdateMode;
    const isAdvanced = type === 'Advanced';
    const imageSrc = isAdvanced ? imgAdvancedPlan : imgBasicPlan;
    const profilePrefix = isUpdateJourney ? 'Go to' : 'Setup';
    const resultDescription = isAdvanced ? 'Welcome to a world of premium features and exclusive benefits. Enjoy the journey with us! 🎉' : 'Enjoy the journey with us! 🎉'
    return (
      <TypographyView>
        <Box style={styles.SafeAreaView} position='relative' className='landing-venue-block'>
          <AppHeader context={this} />
          <Box className='search-location-block' height='calc(100vh - 56px)' boxSizing='border-box' display='flex' justifyContent='center' alignItems='center' py={6} px='140px'>
            <Box width='fit-content' textAlign='center' position='relative' height='100%' >
              <img src={imageSrc} alt='subscription plan' />
              <Typography style={{ fontWeight: 700, margin: '48px 0 16px' }}>
                {`Congratulations! You have activated ${name} Plan`}
              </Typography>
              <Typography>
                {`Congratulations!  You've successfully activated our ${type} Subscription Plan. ${resultDescription}`}
              </Typography>
              {/* Buttons */}
              <Box style={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)'
              }}>
                <Button
                  data-test-id='login-button'
                  type='submit'
                  variant="contained"
                  color="primary"
                  className="agreeButton"
                  style={{
                    width: 'auto',
                    fontSize: '16px',
                    padding: '8px 16px',
                    height: 'auto'
                  }}
                  startIcon={<ChevronLeftRoundedIcon style={{ ...styles.shevron, color: '#ffffff' }} />}
                  onClick={this.gotoSubscriptionPlans}
                >
                  Go to Subscription Plans
                </Button>
                <Button
                  data-test-id='nav-button'
                  type='button'
                  variant="contained"
                  color="primary"
                  className="agreeButton"
                  style={{
                    width: 'auto',
                    fontSize: '16px',
                    padding: '8px 16px',
                    height: 'auto',
                    border: 'none',
                    color: '#398378',
                    background: 'none',
                    boxShadow: 'none',
                    margin: 'auto'
                  }}
                  endIcon={<ChevronRightRoundedIcon style={{ ...styles.shevron, color: '#398378' }} />}
                  onClick={() => isUpdateJourney ? this.openSpaOwnerProfilePage() : this.gotoSettings()}
                >
                  {profilePrefix} your Profile
                </Button>
              </Box>
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
