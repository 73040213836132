Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.subscriptionApiUrl = "account_block/current_plan";
exports.subscriptionApiMethod = "GET";
exports.userDetailsAPIEndPoint = "account_block/get_user_details";
exports.retrieveApiMethod = "GET";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.textHeading = "Loreum ipsum dolor";
exports.formTiltle = "Log in";
exports.txtEmail  = "Email";
exports.txtPassword = "Password";
exports.star ="*";
exports.emailErrorMsg = "Please enter valid email id";
exports.accountText = "Don’t have an account?";
exports.signUpTxt = " Sign up";
exports.description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore";
exports.updateProfileMethod = "POST";
exports.updateProfilePicEndPoint = "account_block/update_profile";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End