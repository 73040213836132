import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Snackbar,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  InputLabel, TextField, MobileStepper
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { backgroundSpaImg, headerLogoImg, } from "./assets";
import { getTranslationConfig } from '../../../components/src/helpers';
import { LanguageSwitchDropdown } from '../../../components/src/LanguageSwitchDropdown';

const styles = {
  paperContnr: {
    backgroundImage: `url(${backgroundSpaImg})`,
    width: '100%',
    height: '1920px',
    backgroundSize: 'cover',
    display: "flex",
    justifyContent: 'center'
  }
};
const LoginFormView = styled(Box)({
  width: '44.7%',
  marginLeft: "6%",
  height: "782px",
  boxSizing: 'border-box',
  borderRadius: '24px 24px 0px 0px',
  background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  '& .formHeader': {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '26px 172px 26px 172px',
    borderRadius: '24px 24px 0px 0px',
    background: 'var(--Primary, #398378)',
    alignItems: 'center !important',
    justifyContent: 'center',
  },
  '& .logo': {
    width: '176px',
    height: '72px'
  },
  '& .mainView': {
    boxSizing: 'border-box',
    padding: '80px',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
    "@media only screen and (max-width: 1280px)": {
      padding: 40
    }
  },
  '& .MuiPaper-root': {
    color: 'red',
    backgroundColor: 'red !important',
  },
  '& .testLabel': {
    paddingTop: '18px',
    paddingBottom: '12px',
    fontWeight: '700',
    color: '#334155',
    fontSize: '16px',
    fontFamily: 'Ubuntu'
  },
  '& .testStar': {
    color: 'red',
    paddingTop: '18px',
    paddingBottom: '12px',
    paddingLeft: '5px',
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Ubuntu',
  },
  '& .inputeStyle': {
    borderRadius: '8px',
    border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)',
    fontFamily: 'Ubuntu',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #398378 !important'
  },
  '& .button': {
    background: '#398378',
    padding: '16px',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Ubuntu',
  },
  '& .txtRemember': {
    color: '#334155',
    paddingLeft: '0px'
  },
  '& .MuiBox-root-19': {
    color: '#1B5950 !important'
  },
  '& .MuiBox-root-14': {
    color: '#334155 !important'
  },
  '& .PrivateSwitchBase-root-18': {
    paddingLeft: '0px'
  },
  '& .PrivateSwitchBase-root-51': {
    paddingLeft: '0px'
  },
  '& .forgotPwdText': {
    fontSize: "14px",
    color: "#1B5950",
    fontWeight: "700",
    marginTop: "5px",
    fontFamily: 'Ubuntu',
    cursor: 'pointer'
  },
  '& .remember': {
    fontSize: "12px",
    color: "#334155",
    fontWeight: "400",
    marginTop: "5px",
    fontFamily: 'Ubuntu'
  },
  '& .accountText': {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: 'Ubuntu'

  },
  '& .signUp': {
    fontSize: '16px',
    color: '#398378',
    fontWeight: 'bold',
    fontFamily: 'Ubuntu',
    paddingLeft: '5px',
    cursor: 'pointer'

  },
  '& .MuiSnackbarContent-root': {
    background: '#FEE2E2 !important',
    color: '#DC2626',
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    width: '100%',
  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    marginTop: '20px'
  },

  '& .stepper': {
    background: 'red !important'
  }
  // MuiOutlinedInput-root.Mui-focused MuiOutlinedInput-notchedOutline

});

const TypographyView = styled(Typography)({
  "& .leftsideContainer": {
    width: 423,
    "@media only screen and (max-width: 1280px)": {
      width: 'fit-content'
    }
  },
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  }
});

const StepperView = styled(Box)({
  '& .MuiMobileStepper-dotActive': {
    background: '#FFF !important'
  },
  '& .MuiMobileStepper-dot': {
    background: '#398378'
  }
})

const TransparentButton = styled(Button)({
  backgroundColor: 'transparent',
  color: '#398378',
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: 'Ubuntu',
  padding: '10px',
  textTransform: 'none',
  marginTop: "20px",
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
});

let t: Record<string, string>;
let dir: string;
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  setTranslations = () => {
    t = getTranslationConfig().t;
    dir = getTranslationConfig().dir;
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.setTranslations()}
        <div style={styles.paperContnr}>
          <Box
            position='relative'
            top={32}
            left={32}
          >
            <LanguageSwitchDropdown forceUpdate={this.forceUpdate.bind(this)} />
          </Box>
          <Box
            sx={{
              width: '78.3%',
              display: "flex",
              justifyContent: 'space-between',
              padding: "118px 140px 0px 140px",
              flexDirection: "row",
              alignItems: "end"
            }}
          >
            <Box
              dir={dir}
              width="45%"
              style={{
                height: "100%",
                display: 'flex',
                flexDirection: "column",
                justifyContent: "center"
              }}>
              <TypographyView className="leftsideContainer">
                <Typography className="heading">{t['login-heading']}</Typography>
                <Typography className="description">{t['login-description']}</Typography>
              </TypographyView>
            </Box>
            <LoginFormView dir={dir}>
              <Box className="formHeader">
                <img src={`${headerLogoImg}`} className="logo" alt="Logo" />
              </Box>
              <Box className="mainView">
                <TypographyView>
                  <Typography className="title">{t['login-form-heading']}</Typography>
                </TypographyView>
                <Snackbar
                  open={this.state.open}
                  onClose={this.handleClose}
                  message={this.state.apiMsg}
                  key={'top' + 'right'}
                />
                <Box >
                  <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
                    <InputLabel className="testLabel">{t['login-form-email']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <TextField
                    fullWidth={true}
                    data-test-id="txtInputEmail"
                    placeholder={t['login-form-email-placeholder']}
                    value={this.state.email}
                    className="inputeStyle"
                    onChange={(e) => this.setEmail(e.target.value)}
                    defaultValue={this.state.email}
                    variant="outlined"
                    error={this.state.emailError}
                  />
                  {this.state.emailError && <TypographyView>
                    <Typography className="txtErrorMsg">{t['login-form-error-email']}</Typography>
                  </TypographyView>}
                </Box>
                <Box >
                  <Box sx={{ flexDirection: 'row', display: 'flex', }}>
                    <InputLabel className="testLabel">{t['login-form-password']}{"  "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <TextField
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={t['login-form-password-placeholder']}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.password ? (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ) : (
                            <VisibilityOff style={{ color: '#ccc', cursor: 'default' }}  />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    error={this.state.pwdError}
                  />
                  {this.state.pwdError && <TypographyView>
                    <Typography className="txtErrorMsg">{t['login-form-error-password']}</Typography>
                  </TypographyView>}
                </Box>
                <Box dir='ltr' sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box
                    className="remember"
                  >
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      checked={this.state.checkedRememberMe}
                      className="txtRemember"
                    />{" "}
                    {t['login-form-remember-checkbox']}
                  </Box>
                  <Box
                    data-test-id={"btnForgotPassword"}
                    className="forgotPwdText"
                    onClick={() => this.goToForgotPassword()}
                  >
                    {t['login-form-forgot-password']}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => this.login()}
                    className="button"
                  >
                    {t['login-form-submit-btn']}
                  </Button>
                  <TransparentButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => this.goToLandingPage()}
                  >
                    {t['login-form-skip']}
                  </TransparentButton>
                </Box>
                <TypographyView>
                  <Box sx={{ flexDirection: 'row', display: 'flex' }} pt={2} pb={2}>
                    <Typography className="account"> {t['login-form-have-account']} </Typography><Typography className="signUp" onClick={this.goToSignUp}> {t['login-form-sign-up']}</Typography>
                  </Box></TypographyView>
              </Box>
            </LoginFormView>
          </Box>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
