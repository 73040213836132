import React from 'react';
import { LocationAddress, Places } from '../../blocks/settings1/src/Settings1Controller';
import { mapMarker } from './assets';
import './mapStyles.css'

type AbstractObj = Record<string, string>;
export type LatLng = { lat: number, lng: number };
type AddrItem = Record<string, string>;

enum AddrType {
  streetNumber = 'street_number',
  streetName = 'route',
  city = 'locality',
  state = 'administrative_area_level_1',
  country = 'country',
  postalCode = 'postal_code',
  establishment = 'establishment',
  sublocality = 'sublocality',
  plusCode = 'plus_code'
}

// Initialize and add the map
async function initMap(
  setPrevLocationAddress?: (address: LocationAddress) => void,
  setPlaces?: (arg: Places) => void,
  coords?: LatLng | null,
  address?: string,
): Promise<void> {
  // The location of India
  // const position = { lat: 23.225, lng: 77.479 };
  let marker: any;
  let map: any;
  let currentLocation: LatLng;

  const geocoder = new (window as any).google.maps.Geocoder();

  const isInAddrList = (next: AddrItem, searchString: AddrType) => next.types.includes(searchString);

  const addMarkerListener = (marker: any, latLng: LatLng, name?: string,) => {
    const infoWindow = new google.maps.InfoWindow({
      content: name
    });

    marker.addListener("click", () => {
      infoWindow.open({
        anchor: marker,
        map,
      });

      getAddress(geocoder, latLng);
    });
  }

  async function nearbySearch(lat: number, lng: number) {
    let service;

    currentLocation = { lat, lng };

    var pyrmont = new google.maps.LatLng(lat, lng);

    var request = {
      location: pyrmont,
      radius: 5000,
      type: 'spa'
    };

    service = new google.maps.places.PlacesService(map);
    service.nearbySearch(request, callback);
  };

  function callback(results: google.maps.places.PlaceResult[] | null, status: google.maps.places.PlacesServiceStatus) {
    if (status == google.maps.places.PlacesServiceStatus.OK && results?.length) {
      const service = new google.maps.places.PlacesService(map);
      const calcDistance = google.maps.geometry.spherical.computeDistanceBetween;

      const places: Places = [];
      for (var i = 0; i < results?.length; i++) {
        const resObj = results[i];
        const { lat, lng } = resObj.geometry?.location || {};
        const latLng = { lat: lat?.() || 0, lng: lng?.() || 0 };

        const marker = new (window as any).google.maps.Marker({
          position: latLng,
          map,
          title: resObj.name,
          icon: mapMarker
        });

        marker.setMap(map);

        addMarkerListener(marker, latLng, resObj.name)

        var request = {
          placeId: String(resObj.place_id),
          fields: ['opening_hours']
        };

        service.getDetails(request, callbackServ);


        let weekDays: string[];

        function getOpenHours(weekDays: string[], currentDay: number) {
          const todayText = weekDays[currentDay];

          const [startHoursIn, startMinutes, endHoursIn, endMinutes] = todayText?.match(/\d+/g) || [''];
          const [startInd, endInd] = todayText?.match(/AM|PM/g) || [];

          const stertTimeNum = Number(startHoursIn);
          const endTimeNum = Number(endHoursIn);

          const startHours = startInd === 'AM' ? stertTimeNum : stertTimeNum + 12;
          const endHours = endInd === 'AM' ? endTimeNum + 12 : endTimeNum;

          return {
            startHours,
            startMinutes,
            endHours,
            endMinutes,
            todayText
          }
        }

        function callbackServ(place: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            const { lat, lng } = resObj.geometry?.location || {};
            const coords = { lat: lat?.() || 0, lng: lng?.() || 0 };

            let statusInfo = '';

            const today = new Date();
            weekDays = place?.opening_hours?.weekday_text || [];

            const currentDay = today.getDay();
            const currentHours = today.getHours();
            const currentMinutes = String(today.getMinutes());

            const { startHours, startMinutes, endHours, endMinutes, todayText } = getOpenHours(weekDays, currentDay);

            const isLateTime = currentHours > startHours || (currentHours === startHours && currentMinutes > startMinutes);
            const isEarlyTime = currentHours < startHours || (currentHours === startHours && currentMinutes < startMinutes);
            const isSameTime = currentHours >= startHours && currentHours < endHours;

            let status = isSameTime ? 'Open' : 'Closed';

            if (isSameTime) {
              statusInfo = `Closes at ${endHours}:${endMinutes} pm`;
            };

            if (isEarlyTime) {
              statusInfo = `Opens at ${startHours}:${startMinutes} am`;
            }

            if (isLateTime) {
              const { startHours, startMinutes } = getOpenHours(weekDays, currentDay + 1);
              statusInfo = `Opens at ${startHours}:${startMinutes} am`;
            }

            if (!startMinutes) {
              statusInfo = todayText;
              status = 'Open'
            }

            const name = String(resObj.name);
            const address = String(resObj.vicinity);
            const distance = Math.round(calcDistance({ lat: lat?.() || 0, lng: lng?.() || 0 }, currentLocation));

            places.push({
              name,
              address,
              distance,
              status,
              statusInfo,
              coords
            })
          }
        };
      }
      setTimeout(() => setPlaces?.(places), 200);
    }
  }

  // Initialize search input
  function initAutocomplete(map: any) {
    // Create the search box and link it to the UI element.
    const input = document.getElementById('addressAutocompleteField') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(originInput);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    let markers: google.maps.Marker[] = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places?.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places?.forEach((place) => {
        const geoLoc = place.geometry?.location;
        const latLng = { lat: geoLoc?.lat() || 0, lng: geoLoc?.lng() || 0 };

        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const marker = new google.maps.Marker({
          map,
          icon: mapMarker,
          title: place.name,
          position: geoLoc,
        });

        // Create a marker for each place.
        markers.push(marker);

        addMarkerListener(marker, latLng, place.name);

        getAddress(geocoder, latLng);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        const { lat, lng } = place.geometry.location;

        nearbySearch(lat(), lng());
      });
      map.fitBounds(bounds);
    });
  }

  function getCoordinates(address: string): LatLng | null | undefined {
    geocoder.geocode({ 'address': address }, function (results: Record<string, Record<string, LatLng | null | undefined>>[], status: string) {
      if (status == 'OK') {
        map.setCenter(results[0].geometry.location);
        new google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          icon: mapMarker
        });
        return results[0].geometry.location;
      }
    });
    return
  }

  function getAddress(
    geocoder: AbstractObj & { geocode: (latLng: { location: LatLng }) => Promise<void> },
    latlng: { lat: number, lng: number },
  ) {
    geocoder
      .geocode({ location: latlng })
      .then((response: any) => {
        if (response.results[0]) {
          const addressObject = (response.results[0].address_components as AddrItem[]).reduce((acc, next) => ({
            ...(isInAddrList(next, AddrType.streetNumber) && { streetNumber: next.long_name }),
            ...(isInAddrList(next, AddrType.streetName) && { streetName: next.long_name }),
            ...(isInAddrList(next, AddrType.city) && { city: next.long_name }),
            ...(isInAddrList(next, AddrType.state) && { state: next.long_name }),
            ...(isInAddrList(next, AddrType.country) && { country: next.long_name }),
            ...(isInAddrList(next, AddrType.postalCode) && { postalCode: next.long_name }),
            ...(isInAddrList(next, AddrType.establishment) && { establishment: next.long_name }),
            ...(isInAddrList(next, AddrType.sublocality) && { sublocality: next.long_name }),
            ...(isInAddrList(next, AddrType.plusCode) && { plusCode: next.long_name }),
            ...acc
          }), {});

          const streetNumber = addressObject.streetNumber || '';
          const streetName = addressObject.streetName || '';
          const city = addressObject.city || '';
          const country = addressObject.country || '';
          const state = addressObject.state || '';
          const postalCode = addressObject.postalCode || '';
          const establishment = addressObject.establishment || '';
          const sublocality = addressObject.sublocality || '';
          const plusCode = addressObject.plusCode || '';

          const address = `${establishment} ${streetNumber || plusCode} ${streetName} ${sublocality} ${city} ${postalCode || country}`.trim();

          setPrevLocationAddress?.({
            address,
            country,
            state,
            city
          })
        } else {
          window.alert("No results found");
        }
      })
      .catch((event: any) => window.alert("Geocoder failed due to: " + event));
  }

  // Request needed libraries.
  //@ts-ignore
  const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

  function placeMarkerAndPanTo(latLng: LatLng, map: AbstractObj) {
    if (marker) {
      marker.setMap(null);
    }
    marker = new (window as any).google.maps.Marker({
      position: latLng,
      map: map,
      icon: mapMarker
    });
    marker.setMap(map);
    (map as any).panTo(latLng);

    marker?.addListener("click", (event: any) => {
      marker.setMap(null);
    });
  }

  // The map, centered
  if (address) {
    const addressCoords = getCoordinates(address);

    map = new Map(
      document.getElementById('map') as HTMLElement,
      {
        zoom: 12,
        center: addressCoords || { lat: 0, lng: 0 },
        disableDefaultUI: true,
        mapId: 'location-map',
      }
    );
  } else {
    navigator.geolocation.getCurrentPosition((geoPosition: { coords: { latitude: number, longitude: number } }) => {
      const position = { lat: geoPosition.coords.latitude, lng: geoPosition.coords.longitude };

      map = new Map(
        document.getElementById('map') as HTMLElement,
        {
          zoom: 14,
          center: position,
          mapId: 'location-map',
        }
      );

      nearbySearch(geoPosition.coords.latitude, geoPosition.coords.longitude);

      getAddress(geocoder, coords || position);

      coords && placeMarkerAndPanTo(coords, map);

      map.addListener("click", (event: any) => {

        placeMarkerAndPanTo(event.latLng, map);
        getAddress(geocoder, event.latLng);
      });

      initAutocomplete(map);
    });
  }
}

type Props = {
  coords?: LatLng | null,
  setLocationAddress?: (address: LocationAddress) => void,
  setCoords?: (coords: LatLng | null) => void;
  setPlaces?: (arg: Places) => void,
  address?: string,
  customStyles?: React.CSSProperties
}

type State = {
  prevLocationAddress: LocationAddress
};

class MapLocationAdv extends React.Component<Props, State> {
  isSetCoords: any;
  customStyles: React.CSSProperties;

  constructor(props: Props) {
    super(props);
    this.customStyles = this.props.customStyles || {};
  }

  componentDidMount() {
    setTimeout(() => initMap(this.props.setLocationAddress, this.props.setPlaces, null, this.props.address), 100);
  };

  componentDidUpdate() {
    if (this.props.coords) {
      initMap(this.props.setLocationAddress, this.props.setPlaces, this.props.coords);
      this.props.setCoords?.(null)
    }
  };

  render() {
    return <div id='map' style={{ width: '100%', height: '80%', ...this.customStyles }} />
  }
}

export default MapLocationAdv;