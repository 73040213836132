import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  TextField,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import redColor from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

const loyaltyTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LoyaltySystemController, {
  Props,
  configJSON,
} from "./LoyaltySystemController";

export default class LoyaltySystem extends LoyaltySystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={loyaltyTheme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <div style={webStyle.header}>
              <button
                data-test-id="btnShopView"
                style={this.state.view === "reward" ? webStyle.headerBtn : webStyle.headerBtnActive}
                onClick={this.setShopView}
              >
                Shop
              </button>
              <button
                data-test-id="btnRewardView"
                style={this.state.view === "shop" ? webStyle.headerBtn : webStyle.headerBtnActive}
                onClick={this.setRewardView}
              >
                Reward Points
              </button>
            </div>
            <Container style={webStyle.messageBlock}>
              <Typography variant="h6" color="error">
                {this.state.errorMsg}
              </Typography>
              <Typography variant="h6" color="secondary">
                {this.state.successMsg}
              </Typography>
            </Container>
            {this.state.view === "shop" ? (
              <>
                <Grid container spacing={4}>
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      Products
                    </Grid>
                    <Grid item>Price</Grid>
                  </Grid>
                  {this.state.items?.map((item) => {
                    return (
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={3}
                        alignItems="center"
                        key={item.attributes.id}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel
                            data-test-id={`productList${item.attributes.id}`}
                            control={
                              <Checkbox
                                id="checkboxData"
                                onChange={(loyaltyEvent) =>
                                  this.onPressItem(parseInt(loyaltyEvent.target.value))
                                }
                                checked={this.state.selectedItems.includes(item?.attributes?.catalogue_variants[0].attributes.id)}
                                name={item?.attributes.name}
                                value={item?.attributes?.catalogue_variants[0].attributes.id}
                                color="secondary"
                              />
                            }
                            label={item?.attributes.name}
                          />
                        </Grid>
                        <Grid item>{item?.attributes?.price}</Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <button
                  color={"primary"}
                  data-test-id="btnshow"
                  onClick={this.onBuyClick}
                  style={webStyle.buttonStyle}
                  disabled={(this.state.selectedItems.length <= 0)}
                >
                  {configJSON.btnBuyTitle}
                </button>
              </>
            ) : (
              <>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Typography variant="h6">Reward points</Typography>
                    <Typography variant="h4" align="center">
                      {this.state.totalReward}
                    </Typography>
                    <button color={"primary"} style={webStyle.buttonStyle} onClick={this.handleOpen} data-test-id="redeemBtn">
                      {configJSON.Redeem}
                    </button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Recent transactions</Typography>
                    <div style={webStyle.lists}>
                      <List dense={true}>
                        {this.state.rewards?.map((rewardValue) => (
                          <ListItem key={rewardValue.id}>
                            <ListItemIcon>
                              {rewardValue.attributes.status === "credit" ? (
                                <ArrowUpwardIcon
                                  style={{ color: green[500] }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  style={{ color: redColor[500] }}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={rewardValue.attributes.title || "Item bought"}
                              secondary={moment(
                                new Date(rewardValue.attributes.created_at)
                              ).format("DD MMMM YYYY")}
                            />
                            <ListItemSecondaryAction>
                              {rewardValue?.attributes?.points}
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Container>

        <Modal
          style={webStyle.modalStyle}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          data-test-id="redeemModal"
        >
          <div style={webStyle.paperStyle}>
            <h2>Enter amount of points to redeem</h2>
            <TextField
              id="rewardRedeem"
              type="number"
              name="rewardRedeem"
              data-test-id="rewardRedeem"
              value={this.state.redeemPoints}
              onChange={(event) => {
                this.rewardChangeHandler(event.target.value)
              }}
              fullWidth
              variant="filled"
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                padding: "10px 0px",
              }}
            >
              <Button
                data-test-id="btnRedeemOk"
                variant="contained"
                style={webStyle.rewardPopupBtn}
                onClick={this.handleRewardRedeem}
              >
                Redeem
              </Button>
              <Button
                data-test-id="btnRedeemCancel"
                variant="contained"
                style={webStyle.rewardPopupBtn}
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </Box>
          </div>
        </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "20px",
    border: "none",
    backgroundColor: "#c8c9cc",
    cursor: "pointer",
    textTransform: "uppercase" as "uppercase",
  },
  header: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "flex-start",
    marginBottom: "30px",
  },
  headerBtn: {
    textAlign: "center" as "center",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    backgroundColor: "#c8c9cc",
    borderRadius: "3px",
    padding: "5px",
  },
  headerBtnActive: {
    textAlign: "center" as "center",
    margin: "10px",
    cursor: "pointer",
    borderRadius: "3px",
    padding: "5px",
    backgroundColor: "#5778db",
    color: 'white',
    border: '1px solid black'
  },
  lists: {
    backgroundColor: "#E9E9E9",
  },
  messageBlock: {
    marginBottom: "20px",
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperStyle: {
    backgroundColor: "white",
    border: '1px solid #000',
    boxShadow: "3px",
    padding: "15px",
  },
  rewardPopupBtn: {
    margin: "0px 10px"
  }

};
// Customizable Area End
