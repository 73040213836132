import React from "react";
import { Typography } from "@material-ui/core";

interface ErrorProps {
    error: string;
    visible: boolean;
    isArabic: boolean;
}

export const ErrorComponent: React.FC<ErrorProps> = ({ error, visible, isArabic }) => {
    if (!visible) return null;

    const borderStyle = isArabic ?
        { borderRight: "4px solid #DC2626", paddingRight: "12px" }
        : { borderLeft: "4px solid #DC2626", paddingLeft: "12px" };

    return (
        <Typography
            data-testid="error-typography"
            style={{
                backgroundColor: "#FEE2E2",
                color: "#DC2626",
                fontFamily: "Ubuntu",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                paddingLeft: "12px",
                marginTop: "20px",
                padding: "12px",
                ...borderStyle
            }}
        >
            {error}
        </Typography>
    );
};