import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from 'react';
import { getStorageData } from '../../../framework/src/Utilities';
import { string } from 'yup';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  title: string;
  description: string;
  account_id: number;
  anonymous: boolean;
  reviewsList: {
    id: number;
    attributes: {
      average_review: number,
      average_review_string: string,
      rating: number,
      comment: string,
      created_at: string,
      review_photos: {
        id: string,
        url: string,
        media_type: string
      }[],
      account: {
        attributes: {
          first_name: string,
          full_name: string
        }
      }
    }
    name: string;
    comment: string;
    created_at: string
  }[];
  activeCreatedAt: string;
  isVisible: boolean;
  accountReview: any;
  showCreateReviewModal: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewsController extends BlockComponent<Props, S, SS> {
  reviewsApiCallId: any;
  deleteReviewApiCallId: any;
  addReviewApiCallId: any;
  updateReviewApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      title: "",
      description: "",
      account_id: 1,
      anonymous: false,
      token: "",
      reviewsList: [],
      accountReview: null,
      activeCreatedAt: "",
      isVisible: false,
      showCreateReviewModal: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    string();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);

    if (message.id === navigationPayloadMessage) {
      const currentNavData = message.getData(navigationPayloadMessage)
      currentNavData === 'showReviewPrompt' && this.setState({ showCreateReviewModal: true });
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.apiGetUserReviewCallId) {
          this.setState({
            reviewsList: responseJson.data.data?.reverse()
          })
        }
        if (apiRequestCallId === this.apiCreateUserReviewCallId) {
          this.getUserReviews();
        }
      } else if (responseJson && responseJson.errors) {
        console.error(responseJson.errors);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiGetUserReviewCallId: any;
  apiCreateUserReviewCallId: any;
  pathParam = this.props.navigation.getParam('navigationBarTitleText', 0);

  async getUserReviews() {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reviewApiEndPoint}?spa_id=${this.pathParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createUserReview(formData: any) {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUserReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async gotoLogin() {
    const authToken = await getStorageData('authToken');

    if (!authToken) {
      const message: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        goBack: 'Reviews',
        param: this.pathParam
      });
      this.send(message);
    }
  }

  setShowCreateReviewModal(value: boolean) {
    this.setState({ showCreateReviewModal: value })
  }
  // Customizable Area End
}
