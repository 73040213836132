import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { DeleteButton, LogoutButton } from '../CutomerSettings.web';
import { ModalConfig } from '../UserProfileBasicController';

type Props = {
    t: Record<string, string>,
    handleOpenModal: (arg: ModalConfig) => void,
    handleLogout: () => void,
    handleDeleteAccount: () => void,
};

export const AccountButtons: FC<Props> = ({
    t,
    handleOpenModal,
    handleLogout,
    handleDeleteAccount
}) =>
    <Box display="flex" flexDirection="column" mt={4}>
        <DeleteButton onClick={() => handleOpenModal(
            {
                title: t['profile-settings-delete-account-title'],
                message: t['profile-settings-delete-account-message'],
                confirmText: t['profile-settings-delete-account-confirm-text'],
                cancelText: t['profile-settings-delete-account-cancel-text'],
                onConfirm: handleDeleteAccount,
            }
        )}
            data-test-id="delete-account-button"
        >
            {t['profile-settings-delete-account']}
        </DeleteButton>
        <LogoutButton style={{ marginTop: "1.5rem" }}
            data-test-id="logout-button"
            onClick={() => handleOpenModal(
                {
                    title: t['profile-settings-logout-title'],
                    message: '',
                    confirmText: t['profile-settings-logout-confirm-text'],
                    cancelText: t['profile-settings-logout-cancel-text'],
                    onConfirm: handleLogout,
                }
            )}
        >
            {t['profile-settings-logout']}
        </LogoutButton>
    </Box>
