import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';

export default class BookingPageAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationBooking),
      getName(MessageEnum.NavigationBookingForm),
      getName(MessageEnum.NavigationBookingDetails),
      getName(MessageEnum.NavigationBookingCalendar),
      getName(MessageEnum.NavigationCouponPage),
      getName(MessageEnum.NavigationMyBookings),
    ]);
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));

    to.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      from.getData(getName(MessageEnum.NavigationScreenNameMessage))
    );

    if (from.id === getName(MessageEnum.NavigationBooking)) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), 'SpaBooking');
    }

    if (from.id === getName(MessageEnum.NavigationBookingForm)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'SpaBookingForm'
      );
    }
    if (from.id === getName(MessageEnum.NavigationBookingDetails)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'BookingDetails'
      );
    }
    if (from.id === getName(MessageEnum.NavigationBookingCalendar)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'BookingSchedule'
      );
    }
    if (from.id === getName(MessageEnum.NavigationCouponPage)) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), 'Coupons');
    }
    if (from.id === getName(MessageEnum.NavigationMyBookings)) {
      to.addData(getName(MessageEnum.NavigationTargetMessage), 'MyBookings');
    }
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );

    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(
      getName(MessageEnum.NavigationPayLoadMessage),
      from.getData(getName(MessageEnum.NavigationPayLoadMessage))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
