import { sourceKey } from './consts';

export const loadScript = () => {
  const head = document.querySelector('head');
  const script = document.createElement('script');

  script.setAttribute(
    'src',
    `https://maps.googleapis.com/maps/api/js?key=${atob(
      sourceKey
    )}&libraries=places,geometry`
  );
  head?.appendChild(script);
};
