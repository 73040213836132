Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.accountsApaAPiEndPoint =
  "account_block/update_profile";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.formTiltle = "Sign up";
exports.txtEmail  = "Email";
exports.txtPassword = "Password";
exports.star ="*";
exports.emailErrorMsg = "Please enter your email id";
exports.accountText = "Already have an account?";
exports.loginTxt = " Log in";
exports.txtFullName = "Full Name";
exports.txtPhoneNumber = "Phone Number";
exports.nameErrorMsg = "Please enter your full name";
exports.phoneNumberErrorMsg = "Please enter your phone number";
exports.txtConfirmPassword = "Confirm Password";
exports.typeErrorMsg = "Please select your gender"
exports.txtGender = "Gender";
exports.confirmPwdErrorMsg = "Password doesn't match";
exports.txtSpaName = "Spa Name";
exports.txtSpaSumary = "Spa Summary";
exports.txtLocation = "ADD LOCATION";
exports.txtCountry = "Country";
exports.txtCity = "City";
exports.txtState = "State";
exports.txtAddress = "Complete Address";
exports.txtDocument = "ADD DOCUMENTS";
exports.txtUploadDocuments = "Upload Spa Documents";
exports.txtBusinessLicense = "Business Licenses";
exports.txtUploadDesc = "Drag and drop the files here  (File type - .pdf, .jpg, .jpeg or .png)";
exports.txtBrowseFile = "Browse file to upload";
exports.txtCertification = "Certifications";
exports.txtWebsiteLink = "Attach Website Link";
exports.txtAddOther = "Add Other";
exports.txtAddOtherFiles = "Add Files";
exports.spaformTiltle = "Registers as Spa";
exports.spaNameErrorMsg = "Please enter your spa name";
exports.spaSummaryErrorMsg = "Please enter spa summary";
exports.countryErrorMsg = "Please select your country";
exports.cityErrorMsg = "Please select your city";
exports.stateErrorMsg = "Please select your state";
exports.addressErrorMsg = "Please enter the complete address";
exports.addressErrorMsg = "Please enter the complete address";
exports.licenseErrorMsg = "Please upload Spa License";
exports.certificationsErrorMsg = "Please upload Certificate";
exports.invalidFileErrorMsg = "Please upload valid file";
// Customizable Area End
