Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.spaStatisticAPiEndPoint = "bx_block_visual_analytics/spa_statistics";
exports.spaStatisticType = "sales_analysis";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
// Customizable Area End