import { TextField, Typography, styled } from '@material-ui/core';

export const TypographyView = styled(Typography)({
    '& *': {
        fontFamily: 'Ubuntu',
        fontWeight: 400,
    },
    '& .venue-carousel': {
        position: 'relative'
    },
    '& .carousel__dot': {
        borderRadius: '50%', 
        border: 'none',
        padding: 0
    },
    '& .carousel__dot--selected': {
        backgroundColor: '#398378'
    },
    '& .MuiButton-root': {
        textTransform: 'none'
    },
    '& .MuiButton-label': {
        fontWeight: 700
    },
    '& .venue-service-carousel button': {
        border: 'none',
        background: 'none'
    },
    '& .venue-service-carousel button[disabled] div': {
        background: '#CBD5E1'
    },
    '& .venue-service-carousel button div': {
        background: '#398378'
    },
    '& .venue-service-carousel': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '& .buttons-carosel-tray': {
        width: '100%'
    },
    '& .agreeButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #398378)',
        fontFamily: 'Ubuntu',
        textTransform: 'none'
    },
    '& .declineButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #D9EEEB)',
        color: '#398378',
        fontFamily: 'Ubuntu',
        textTransform: 'none'
    }
})


export const styles = {
    SafeAreaView: {
      backgroundColor: '#e8f6f4ff',
    },
    inputSearch: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      border: '1px solid #CBD5E1',
      fontSize: '24px',
      color: '#334155',
      padding: '10px 8px',
      display: 'block',
      width: 'auto'
    },
    pageTile: {
      backgroundColor: '#ffffff',
      cursor: 'pointer'
    },
    boldSmall: {
      fontFamily: 'Ubuntu',
      fontSize: '14px',
      fontWeight: 700,
      color: '#000000'
    },
    regularMiddle: {
      fontFamily: 'Ubuntu',
      fontSize: '16px',
      fontWeight: 400,
      color: '#000000'
    },
    tileParagraph: {
      padding: '13px 0'
    },
    dotsOverflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    venueStatus: {
      color: '#34D399',
      marginRight: '10px'
    },
    serviceBlock: {
      marginTop: '20px',
      backgroundColor: '#ffffff',
      padding: '16px'
    },
    carouselButton: {
      borderRadius: '100px',
      boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)'
    },
    scheduleArrow: {
      background: 'none',
      border: 'none',
      padding: 0
    },
    arrowWrapper: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      style: {
        cursor: 'pointer'
      }
    },
    customerInfoItem: {
      color: '#000000',
      fontSize: '14px',
      width: '50%'
    },
    popperStyle: {
      bgcolor: 'background.paper',
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
      marginTop: '2px'
    }
  };


export const PriceInput = styled(TextField)({
  ...styles.regularMiddle,
  display: "inline-block",
  verticalAlign: "text-bottom",
  color: "#334155",
  "& .MuiOutlinedInput-input": {
    padding: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    background: "#F8FAFC",
  },
});
