import { Box, Button, Modal, Typography } from '@material-ui/core';
import React from 'react';
import { TypographyView } from '../styledFont';
import Ordermanagement1Controller, { Props } from '../Ordermanagement1Controller';
import CloseIcon from '@material-ui/icons/Close';
import { getTranslationConfig } from '../../../../components/src/helpers';

export default class CancelModal extends Ordermanagement1Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    handleCancelBooking = async () => {
        const { bookedID, onCancelBooking } = this.props;
        if (bookedID !== undefined && onCancelBooking) {
            await onCancelBooking(bookedID);
        }
    };

    render() {
        const { t, dir, isArabic } = getTranslationConfig();
        const { serviceName } = this.props
        return <Modal
            data-test-id='review-modal'
            open={!!this.props.openCancelModal}
            onClose={() => this.props.setOpenCancelModal?.()}
        >
            <TypographyView>
                <Box
                    dir={dir}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '45%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px 8px 32px 8px',
                        outline: 'none',
                    }}
                >
                    <Box p={2} borderBottom='1px solid #E2E8F0'>
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            {...(isArabic && {
                                width: 'fit-content',
                                marginRight: 'auto'
                            })}
                        >
                            <CloseIcon
                                data-test-id='review-close-btn'
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto'
                                }}
                                onClick={() => this.props.setOpenCancelModal?.()}
                            />
                        </Box>
                        {/* </Box> */}
                        <Box px={5} py={2}>
                            <Typography
                                style={{
                                    fontSize: '24px',
                                    fontWeight: 700,
                                    marginBottom: '8px'
                                }}
                            >
                                {t['bookings-cancel-modal-title']} {serviceName}?
                            </Typography>
                            <Typography
                                dir={dir}
                                style={{
                                    fontSize: '18px',
                                    paddingBottom: '2px'
                                }}
                            >
                                {t['bookings-cancel-modal-description']} {serviceName}
                            </Typography>
                        </Box>
                        <Box
                            dir='ltr'
                            p={3}
                        >
                            <Box
                                display='flex'
                                justifyContent='space-between'
                                width='50%'
                                marginLeft='auto'
                            >
                                <Button
                                    data-test-id='cancel-booking-btn'
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleCancelBooking}
                                    className="declineButton"
                                >
                                    {t['bookings-cancel-modal-confirm']}
                                </Button>
                                <Button
                                    data-test-id='refuse-booking-cancel-btn'
                                    type='button'
                                    variant="contained"
                                    color="primary"
                                    className="agreeButton"
                                    onClick={() => this.props.setOpenCancelModal?.()}
                                >
                                    {t['bookings-cancel-modal-cancel']}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </TypographyView>
        </Modal>
    }
}
