import { Typography, styled } from '@material-ui/core';

export const TypographyView = styled(Typography)({
    '& *': {
        fontFamily: 'Ubuntu',
        fontWeight: 400,
    },
    '& .venue-carousel': {
        position: 'relative'
    },
    '& .carousel__dot': {
        borderRadius: '50%',
        border: 'none',
        padding: 0
    },
    '& .carousel__dot--selected': {
        backgroundColor: '#398378'
    },
    '& .MuiButton-root': {
        textTransform: 'none'
    },
    '& .MuiButton-label': {
        fontWeight: 700
    },
    '& .venue-service-carousel button': {
        border: 'none',
        background: 'none'
    },
    '& .venue-service-carousel button[disabled] div': {
        background: '#CBD5E1'
    },
    '& .venue-service-carousel button div': {
        background: '#398378'
    },
    '& .venue-service-carousel': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '& .buttons-carosel-tray': {
        width: '100%'
    },
    '& .agreeButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #398378)',
        fontFamily: 'Ubuntu',
        textTransform: 'none',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    },
    '& .declineButton': {
        display: 'flex',
        width: '45%',
        height: '56px',
        padding: '16px 36.5px',
        borderRadius: '8px',
        background: 'var(--Primary, #D9EEEB)',
        color: '#398378',
        fontFamily: 'Ubuntu',
        textTransform: 'none',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    },
    '& .MuiBox-root-19': {
        color: '#1B5950 !important'
    },
    '& .test-label': {
        color: 'initial',
        fontWeight: 700
    },
    '& .test-star': {
        color: '#DC2626',
        fontWeight: 700,
        marginLeft: '4px'
    },
    '& .txtErrorMsg': {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Ubuntu',
        paddingTop: '5px'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #398378'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
    },
    '& .MuiCheckbox-root': {
        padding: 0,
        marginRight: '8px'
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
        color: '#94A3B8'
    }
})

export const styles = {
    SafeAreaView: {
        backgroundColor: '#e8f6f4ff',
    },
    dotsOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    actionButton: {
        borderRadius: '8px',
        fontSize: '16px',
        background: 'var(--Primary, #398378)',
        fontFamily: 'Ubuntu',
        textTransform: 'capitalize',
        padding: '8px 24px',
        margin: '16px 0'
    },
    paymentItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        width: '140px',
        borderRadius: '16px',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box'
    },
    paymentImage: {
        objectFit: 'contain',
        maxHeight: '100%'
    },
    inputBlock: {
        width: '48%'
    },
    inputTitle: {
        display: 'flex',
        flexDirection: 'row',
        padding: '16px 0 8px'
    },
    modeButton: {
        heigth: '56px',
        width: '50%',
        border: '1px solid #7EC4B9',
        boxShadow: 'none'
    },
    shevron: {
        width: '24px',
        height: '30px',
    }
};
