import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { getTransContent, getTranslationConfig } from '../../../components/src/helpers';
import { AppHeader } from "../../../components/src/AppHeader.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const styles = {
  paperContnr: {
    background: 'var(--Primary-green-100, #E8F6F4)',
    minHeight: 'full'
  },
  'backButton': {
    color: '#0F172A',
    cursor: 'pointer',
    marginBottom: '12px'
  },
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const TermAndConditionView = styled(Typography)({
  padding: "50px",
  '& .label': {
    color: `var(--Neutrals-Cool-gray-900, #0F172A)`,
    fontFamily: 'Ubuntu',
    fontSize: '48px',
    fontWeight: 700,
    paddingTop: '10px',
    padding: '20px',
  },
  '& .description': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    fontWeight: 400,
    paddingTop: '10px',
    overflowY: 'auto',
    maxHeight: '500px',
    scrollSnapType: "y mandatory",
    scrollbarColor: "#398378 #f1f1f1",
    padding: '20px',
    marginRight: '20px',
    '&::-webkit-scrollbar': {
      width: '200px',
      backgroundColor: '#E8F6F4',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#398378',
      borderRadius: '7px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E8F6F4',
    },

  }
});
const ButtonComponent = styled(Button)({
  display: "flex",
  flexDirection: "row",
  alignSelf: 'end',
  alignItems: "end",
  justifyContent: 'space-between',
  padding: "30px 0px",
  "&.MuiButtonBase-root:hover": {
    backgroundColor: "transparent"
  },
  '& .agreeButton': {
    display: 'flex',
    width: '120px',
    height: '56px',
    padding: '16px 36.5px',
    borderRadius: '8px',
    background: 'var(--Primary, #398378)',
    marginRight: '30px',
    fontFamily: 'Ubuntu',
    textTransform: 'none'
  },
  '& .declineButton': {
    display: 'flex',
    width: '120px',
    height: '56px',
    padding: '16px 36.5px',
    borderRadius: '8px',
    background: 'var(--Primary, #D9EEEB)',
    color: '#398378',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    marginRight: '30px',
  }
})
let t: Record<string, string>;
let dir: string;
let isArabic: boolean
// Customizable Area End

import TermsandconditionsController, {
  Props,
  configJSON,
} from "./TermsandconditionsController";

export default class Termsandconditions extends TermsandconditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  setTranslations = () => {
    t = getTranslationConfig().t;
    dir = getTranslationConfig().dir;
    isArabic = getTranslationConfig().isArabic
  }

  getTranslatedDescription() {
    const joinUsObject = getTransContent(this.state.details);

    return joinUsObject.description;
  }

  getTranslatedButtonName = (t: Record<string, string>) => 
    this.state.redirectFromSignUp ? t['terms-and-conditions-decline-button'] : t?.['terms-and-conditions-close-button'];

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.setTranslations()}
        <>
          <AppHeader 
            context={this}
            onClickEvent={this.goToLogin}
            showLogin={this.state.redirectFromSignUp}
            forceUpdate={this.forceUpdate.bind(this)}
          />
        </>
        <div style={styles.paperContnr}>
          <Box dir={dir} sx={webStyle.mainWrapper}>
            {this.state.redirectFromSignUp && <ArrowBackIcon style={styles.backButton} onClick={this.goBack} />}
            <TermAndConditionView>
              <Typography data-testId="termsandConditionTitle" variant="h6" className="label">{t['terms-and-conditions-main-title']}</Typography>
              <Typography className="description">{this.getTranslatedDescription()}</Typography>
            </TermAndConditionView>
            <ButtonComponent>
              {this.state.redirectFromSignUp && <Button
                data-test-id={"btnAcceptTerms"}
                variant="contained"
                color="primary"
                onClick={this.agree}
                className="agreeButton"
              >
                {t['terms-and-conditions-agree-button']}
              </Button>}
              <Button
                data-test-id={"btnDecline"}
                variant="contained"
                color="primary"
                onClick={this.decline}
                className="declineButton"
              >
                {this.getTranslatedButtonName(t)}
              </Button>
            </ButtonComponent>

          </Box>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Ubuntu",
    flexDirection: "column",
    paddingBottom: "30px",
    paddingTop: '48px',
    paddingLeft: '60px',
    paddingRight: '60px'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", 
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
