import { Box, Modal, styled, Typography } from '@material-ui/core';
import { closeIcon } from '../../blocks/user-profile-basic/src/assets';
import React from 'react';
import { getTranslationConfig } from './helpers';

export const ModalWrapperComponent = (props: any) => {
    const {
        isOpen,
        children,
        handleClose,
        modalHeading,
        width,
        withoutHeader,
        styles,
        closeStyles
    } = props;
    const { dir } = getTranslationConfig();

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <ModalWrapper dir={dir} style={{ width, height: "auto", paddingTop: width === "50%" ? 0 : 20, ...(styles || {}) }} >
                <Box id="form-dialog-title" {...(!withoutHeader && { className: "ModalHeader" })} style={closeStyles || {}}>
                    <Typography className="modalHeadingText" style={{ borderBottom: 0 }}>{modalHeading}</Typography>
                    <Box
                        style={{
                            cursor: 'pointer',
                            ...(withoutHeader && {
                                position: 'absolute',
                                right: 0
                            })
                        }}
                        onClick={handleClose}
                    >
                        <img src={closeIcon} />
                    </Box>
                </Box>
                {children}
            </ModalWrapper>
        </Modal>
    )
}

export const ModalWrapper = styled(Box)({
    position: "absolute",
    top: '50%',
    left: "50%",
    transform: 'translate(-50%, -50%)',
    width: "auto",
    maxWidth: '55%',
    boxShadow: '0px 4px 8px 0px #00000008',
    borderRadius: "8px 8px 32px 8px",
    padding: "20px 0px",
    background: '#fff',
    fontFamily: 'Ubuntu',
    outline: 'none',
    overflow: 'auto',
    '& .rdp-column-container:nth-child(2) .rdp-cell:not(:nth-child(10n + 1))': {
        height: 0,
        visibility: 'hidden',
    },
    '& .rdp-text-overlay': {
        '& div': {
            display: "none"
        }
    },
    '& .rdp-column-container:nth-child(3)': {
        display: 'none'
    },
    '& .rdp-reticule': {
        display: 'none'
    },

    '& .MuiLinearProgress-bar': {
        backgroundColor: '#D97706',
    },
    "& .addTimingOpen:hover": {
        background: '#E8F6F4'
    },
    '& .sametimeAllDay': {
        width: 20,
        height: 20,
        borderRadius: 6,
        color: '#398378 !important'
    },
    '& .MuiSnackbarContent-root': {
        background: '#FEE2E2',
        color: '#DC2626',
        fontSize: '12px',
        fontFamily: 'Ubuntu',
        borderLeft: '4px solid #DC2626',
        borderRadius: '4px',
        width: '100%',
    },
    '& .MuiSnackbar-anchorOriginBottomCenter': {
        transform: 'translateX(0%)',
        position: 'inherit',
        justifyContent: 'start',
        marginTop: '20px'
    },
    '& .multiButtonContainer': {
        borderTop: "1px solid #E2E8F0",
        height: "85px",
        borderRadius: "0px 0px 32px 8px",
        padding: "24px",
        display: "flex",
        justifyContent: 'end',
        boxSizing: 'border-box'
    },
    '& .buttonCursor': {
        cursor: 'pointer'
    },
    '& .locationView[disabled]': {
        backgroundColor: '#d7ded6'
    },
    '& .locationView[disabled] p': {
        color: '#FFFFFF'
    },
    '& .locationView[disabled] svg': {
        color: '#FFFFFF'
    },
    '& .locationViewStyle': { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' },
    '& .fileView': { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
    '& .documentView': {
        display: 'flex', flexDirection: 'row', alignItems: 'center',
        justifyContent: 'space-between', backgroundColor: 'var(--Primary, #398378)', padding: '16px'
    },
    '& .uploadedDocument': {
        flexDirection: 'row',
        display: 'flex',
        borderRadius: '6px',
        boxShadow: "0px 8px 32px 0px #0000000F",
        background: "#FFFFFF",
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '4px 8px',
        padding: '10px',
    },
    '& .removeItem': {
        display: 'flex',
        backgroundColor: '#E8F6F4',
        width: '56px', height: '56px',
        borderRadius: '8px', border: '1px solid #E8F6F4',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .ModalHeader': {
        borderBottom: '1px solid #E2E8F0',
        boxSizing: 'border-box',
        display: 'flex',
        height: "80px",
        padding: "24px 16px 24px 40px",
        justifyContent: "space-between"
    },
    '& .modalHeadingText': {
        fontFamily: 'Ubuntu',
        fontSize: '24px',
        fontWeight: 700,
        textAlign: "left"
    },
    '& .modalBOdyContainer': {
        display: "flex", flexDirection: 'column', gap: 60
    },
    '& .modalBodyMain': {
        display: 'flex', flexDirection: "column", gap: 16, padding: '0px 40px 0px 40px'
    },
    '& .inputeStyle': {
        borderRadius: '8px',
        border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
        background: 'var(--Basic-White, #FFF)',
        '& .Mui-disabled': {
            color: "#334155",
            fontFamily: 'Ubuntu',
            fontSize: 16,
            lineHeight: 24
        }
    },
    '& .buttonContainer': {
        background: "#398378",
        height: "56px",
        padding: '16px',
        gap: "8px",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontFamily: 'Ubuntu',
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "none"
    },
    "& #inputinuserblock": {
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: "pointer",
        borderBottomRightRadius: '100%'
    },
    '& .testLabel': {
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: 700,
        color: '#334155',
        fontFamily: 'Ubuntu',
        fontSize: 14,
    },
    '& .testStar': {
        color: 'red',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '5px'
    },
})
