export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const startBookingHeader = require('../assets/start-booking-header.png');
export const arrowDown = require("../assets/arrow-down.png");
export const calendar = require("../assets/calendar.png");
export const imgPendingClockGrey = require('../assets/pending-clock.png');
export const defaultProfile = require('../assets/default-profile.png');
export const imgLocationSearch = require('../assets/location-search.svg');
export const imgPendingClockGreen = require('../assets/pending-clock.svg');
export const imgDollarSign = require('../assets/dollarSign.svg');
export const bookingSchedule = require('../assets/booking-schedule.png');
export const call = require('../assets/call.png');
export const message = require('../assets/message_.png');
export const customerImg = require('../assets/customer_img.png');
export const couponImg = require('../assets/coupon_img.png');
export const profileCover = require("../assets/profile_cover.jpeg");
export const notFound = require("../assets/no-booking.png");
export const notFoundAr = require("../assets/no-booking-ar.png");
