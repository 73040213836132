// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import RecoveryPassword from "../../blocks/forgot-password/src/PasswordRecovery.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualFullAnalytics.web";
import TermsConditions from "../../blocks/termsandconditions/src/Termsandconditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Download1 from "../../blocks/download1/src/Download1";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Calendar from "../../blocks/calendar/src/Calendar";
import Analytics1 from "../../blocks/analytics1/src/Analytics1";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Catalogue1 from "../../blocks/catalogue1/src/Catalogue1";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ContactUsPage from "../../blocks/contactus/src/ContactUsPage";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Ordermanagement1 from "../../blocks/ordermanagement1/src/Ordermanagement1.web";
import OrdermanagementForm from "../../blocks/ordermanagement1/src/OrdermanagementForm.web";
import OrdermanagementDetails from "../../blocks/ordermanagement1/src/OrdermanagementDetails.web";
import OrdermanagementSchedule from "../../blocks/ordermanagement1/src/OrdermanagementSchedule.web";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountRegistrationConfirmation from "../../blocks/email-account-registration/src/EmailAccountRegistrationConfirmation.web";
import EmailAccountSpaRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistrationAsSpa.web";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import Analytics from "../../blocks/analytics/src/Analytics";
import Settings1 from "../../blocks/settings1/src/Settings1";
import SettingsConfirmation from "../../blocks/settings1/src/Settings1Confirmation.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Paymentadmin from "../../blocks/paymentadmin/src/Paymentadmin";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import Reviews from "../../blocks/reviews/src/Reviews.web";
import AddReview from "../../blocks/reviews/src/AddReview";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import Payments from "../../blocks/payments/src/Payments";
import Subscriptionbilling from "../../blocks/subscriptionbilling/src/Subscriptionbilling";
import SubscriptionbillingPlans from "../../blocks/subscriptionbilling/src/SubscriptionbillingPlans.web";
import SubscriptionbillingPayment from "../../blocks/subscriptionbilling/src/SubscriptionbillingPayment.web";
import SubscriptionbillingCongrats from "../../blocks/subscriptionbilling/src/SubscriptionbillingCongrats.web";
import SubscriptionbillingInvitation from "../../blocks/subscriptionbilling/src/SubscriptionbillingInvitation.web";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OrderManagementBooking from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import SetLocation from '../../blocks/settings1/src/SetLocation.web';
import SetLocationProfile from '../../blocks/user-profile-basic/src/SetLocationProfile.web';
import LandingPageVenue from '../../blocks/landingpage/src/LandingPageVenue.web';
import SubCategories from '../../blocks/landingpage/src/SubCategories.web'
import SpaUserProfile from "../../blocks/user-profile-basic/src/SpaUserProfile.web";
import EditAvailabilities from "../../blocks/user-profile-basic/src/EditAvailabilities.web"
import BasicInformation from "../../blocks/user-profile-basic/src/BasicInformation.web";
import Coupons from "../../blocks/ordermanagement1/src/Coupons.web";
import CustomerInformation from "../../blocks/user-profile-basic/src/CustomerInformation.web";
import MyBookings from "../../blocks/user-profile-basic/src/MyBookings.web";
import CutomerSettings from "../../blocks/user-profile-basic/src/CutomerSettings.web";
import SpaOwnerSettings from "../../blocks/user-profile-basic/src/SpaOwnerSettings.web";
import CustomerCoupons from "../../blocks/user-profile-basic/src/CustomerCoupons.web";
import EditService from "../../blocks/user-profile-basic/src/EditService.web"
import SearchResults from '../../blocks/landingpage/src/SearchResults.web';
import NotificationsProfile from "../../blocks/user-profile-basic/src/NotificationsProfile.web";
import NewPasswordScreen from "../../blocks/forgot-password/src/NewPasswordScreen.web";
import PasswordResetRedirect from "../../blocks/forgot-password/src/components/PasswordResetRedirect";
import SpasByCountry from '../../blocks/landingpage/src/SpasByCountry.web'


import { loadScript } from '../../components/src/loadScript';
import { getLocalTranslations } from '../../components/src/helpers';

const routeMap = {
Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
RecoveryPassword:{
  component:RecoveryPassword,
 path:"/RecoveryPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Download1:{
 component:Download1,
path:"/Download1"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Signuplogin:{
 component:Signuplogin,
path:"/Signuplogin"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
Analytics1:{
 component:Analytics1,
path:"/Analytics1"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Catalogue1:{
 component:Catalogue1,
path:"/Catalogue1"},
Termsandconditions:{
 component:Termsandconditions,
path:"/Termsandconditions"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ContactUsPage:{
 component:ContactUsPage,
path:"/ContactUsPage"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
SpaBooking:{
 component:Ordermanagement1,
path:"/SpaBooking"},
SpaBookingForm:{
  component:OrdermanagementForm,
 path:"/SpaBookingForm"},
BookingDetails:{
component:OrdermanagementDetails,
path:"/BookingDetails/:navigationBarTitleText"},
Coupons:{
  component:Coupons,
  path:"/Coupons"},
BookingSchedule:{
component:OrdermanagementSchedule,
path:"/BookingSchedule"}, 
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
EmailAccountRegistrationConfirmation:{
  component:EmailAccountRegistrationConfirmation,
      path: "/EmailAccountRegistrationConfirmation"
    },
EmailAccountSpaRegistration:{
    component:EmailAccountSpaRegistration,
   path:"/EmailAccountSpaRegistration"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Dashboard4:{
  component:Dashboard4,
 path:"/Dashboard4"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Settings:{
 component:Settings1,
path:"/Settings"},
SettingsConfirmation:{
  component:SettingsConfirmation,
 path:"/SettingsConfirmation"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Paymentadmin:{
 component:Paymentadmin,
path:"/Paymentadmin"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
Reviews:{
 component:Reviews,
path:"/Reviews/:navigationBarTitleText"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
Payments:{
 component:Payments,
path:"/Payments"},
Subscriptionbilling:{
 component:Subscriptionbilling,
path:"/Subscriptionbilling"},
SubscriptionbillingPlans:{
  component:SubscriptionbillingPlans,
 path:"/SubscriptionbillingPlans"},
SubscriptionbillingPayment:{
component:SubscriptionbillingPayment,
path:"/SubscriptionbillingPayment"},
SubscriptionbillingCongrats:{
component:SubscriptionbillingCongrats,
path:"/SubscriptionbillingCongrats"},
SubscriptionbillingInvitation:{
  component:SubscriptionbillingInvitation,
  path:"/SubscriptionbillingInvitation"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OrderManagement:{
  component:OrderManagementBooking,
 path:"/OrderManagementBooking"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/Home',
  },
  OnBoaeding: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  SetLocation: {
    component: SetLocation,
    path: '/SetLocation'
  },
  SetLocationProfile: {
    component: SetLocationProfile,
    path: '/SetLocationProfile'
  },
  LandingPageVenue: {
    component: LandingPageVenue,
    path: '/LandingPageVenue/:navigationBarTitleText'
  },
  SubCategories: {
    component: SubCategories,
    path: '/SubCategories/:navigationBarTitleText'
  },
  SpaUserProfile: {
    component: SpaUserProfile,
    path: '/SpaUserProfile'
  },
  BasicInformation: {
    component: BasicInformation,
    path: '/BasicInformation'
  },
  EditAvailabilities: {
    component: EditAvailabilities,
    path: '/EditAvailabilities',
  },
  CustomerInformation: {
    component: CustomerInformation,
    path: '/CustomerInformation'
  },
  MyBookings: {
    component: MyBookings,
    path: '/MyBookings'
  },
  CutomerSettings: {
    component: CutomerSettings,
    path: '/CutomerSettings'
  },
  SpaOwnerSettings: {
    component: SpaOwnerSettings,
    path: '/SpaOwnerSettings',
  },
  EditService: {
    component: EditService,
    path: '/EditService',
  },
  CustomerCoupons: {
    component: CustomerCoupons,
    path: '/CustomerCoupons',
  },
  NotificationsProfile: {
    component: NotificationsProfile,
    path: '/NotificationsProfile',
  },
  SearchResults: {
    component: SearchResults,
    path: '/SearchResults',
  },
  NewPasswordScreen: {
    component: NewPasswordScreen,
    path: '/NewPasswordScreen',
  },
  PasswordResetRedirect: {
    component: PasswordResetRedirect,
    path: "/bx_block_forgot_password/NewPasswordScreen/:email?",
  },
  SpasByCountry: {
    component: SpasByCountry,
    path: "/SpasByCountry/:navigationBarTitleText",
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

// Get default translations
window.t = getLocalTranslations(localStorage.getItem('language'));

class App extends Component {
  async componentDidMount() {
    loadScript();
  };
   
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh' }}>
        <TopNav />
          {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;