import React from "react";
import { Box, Button, Modal, Typography } from '@material-ui/core';
import UserProfileBasicController, { Props } from '../UserProfileBasicController';
import { TypographyView } from '../../../ordermanagement1/src/styledFont';
import { profilePic } from "../assets";


export default class NotificationModal extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        const { notification } = this.props

        return <Modal
            data-test-id='notification-modal'
            open={!!this.props.openNotificationModal}
            onClose={() => this.props.setOpenNotificationModal?.(false)}
        >
            <TypographyView>
                <Box
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '45%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px 8px 32px 8px',
                        outline: 'none',
                        // padding: '16px'
                    }}
                >
                    <Box p={2} borderBottom='1px solid #E2E8F0'>
                        <Box px={5} py={2} >
                            <Box display="flex">
                                <img
                                    src={notification?.attributes.service_provider?.profile_photo || profilePic}
                                    style={{
                                        width: "48px",
                                        height: "48px",
                                        borderRadius: "50%",
                                        marginRight: "16px",
                                    }}
                                />
                                <Typography style={{ fontWeight: 700, marginTop: "20px" }}>
                                    {notification?.attributes.service_provider?.name}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography style={{ fontWeight: 700, marginTop: "20px" }}>
                                    {notification?.attributes.headings}
                                </Typography>
                                <Typography style={{
                                    fontSize: '18px',
                                    paddingBottom: '2px',
                                    marginTop: "20px"
                                }} >
                                    {notification?.attributes.contents}
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={3}>
                            <Box display='flex' justifyContent='flex-end'>
                                <Button
                                    data-test-id='cancel-notification-btn'
                                    variant="contained"
                                    color="primary"
                                    className="declineButton"
                                    style={{ width: "105px" }}
                                    onClick={() => this.props.setOpenNotificationModal?.(false)}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: "Ubuntu",
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            color:"#398378"
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </TypographyView>
        </Modal>
    }
}