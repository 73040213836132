import { Box, Button, InputLabel, Modal, Popover, TextField, Typography } from '@material-ui/core';
import React from 'react';
import dayjs from "dayjs";
import { PriceInput, TypographyView, styles } from '../styledFont';
import { Formik } from 'formik';
import Ordermanagement1Controller, { Props, RescheduleFormValues, initRescheduleFormValues } from '../Ordermanagement1Controller';
import * as Yup from "yup";
import { RegistrationFormView } from '../regView';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { arrowDown, imgDollarSign, imgPendingClockGreen } from '../assets';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { calculateSlots, generateDatesRange, getPureDateString } from '../../../../components/src/datesRangeHelper';
import { getTransDuration, getTranslationConfig } from '../../../../components/src/helpers';

export default class BookingModal extends Ordermanagement1Controller {
    sliderShown: any;

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.sliderShown = React.createRef()
        this.setDatesRange = this.setDatesRange.bind(this);
        this.renderDatarange = this.renderDatarange.bind(this);
        // Customizable Area End
    }

    renderDatarange(
        currentDate: string,
        index: number,
        scheduleSlots: {
            time: string,
            duration: string,
            isAvailable: boolean
        }[],
        setFieldValue: (arg: string, val: string) => void,
    ) {
        return (
            <Slide index={index} key={`${currentDate}_${index}`}>
                <Box
                    display='flex'
                    flexWrap='wrap'
                    gridGap='16px'
                    width='500px'
                    justifyContent='flex-start'
                >
                    {scheduleSlots.map((slot) =>
                        <>
                            <Button
                                data-test-id='slot-item-btn'
                                style={{
                                    display: 'block',
                                    padding: '4px 8px',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '6px',
                                    color: slot.isAvailable ? '#FFF' : '#64748B',
                                    minWidth: '28%',
                                    backgroundColor: slot.isAvailable ? '#398378' : '#FFFFFF',
                                    cursor: slot.isAvailable ? 'pointer' : 'none'
                                }}
                                disabled={!slot.isAvailable}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    const buttonElem = event.currentTarget;

                                    if (buttonElem.getAttribute('data-clicked')) {
                                        buttonElem.style.backgroundColor = '#FFFFFF';
                                        (buttonElem as any).firstChild.style.color = '#64748B';
                                        buttonElem.removeAttribute('data-clicked');
                                    } else {
                                        buttonElem.setAttribute('data-clicked', 'true');
                                        buttonElem.style.backgroundColor = '#398378';
                                        (buttonElem as any).firstChild.style.color = '#FFFFFF';
                                    }

                                    setFieldValue('slot', slot.time);
                                    setFieldValue("date", currentDate);

                                    this.setState({
                                        anchorEl: null,
                                        currentSlotIndex: 0
                                    });
                                }}
                            >
                                <Typography
                                    className='slot-name'
                                    style={{
                                        fontSize: '12px',
                                        marginBottom: '4px',
                                    }}
                                >
                                    {slot.time}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '10px',
                                        color: '#94A3B8',
                                        textTransform: 'none'
                                    }}
                                >
                                    {getTransDuration(slot.duration)}
                                </Typography>
                            </Button>
                        </>
                    )}
                </Box>
            </Slide>)
    }

    render() {
        const { t, dir, locale, isArabic } = getTranslationConfig();

        const {
            open_timing: openTiming = '',
            spa_timings: spaTimings = [{
                start_time: '',
                end_time: ''
            }],
        } = this.state.customerDetails?.attributes || {};

        const start_time = spaTimings?.[0]?.start_time;
        const end_time = spaTimings?.[0]?.end_time;
        const futureOpenTiming = `${start_time} - ${end_time}`;

        const resOpenTiming = this.getOpenTiming(openTiming, futureOpenTiming);

        const thisYear = new Date().getFullYear()
        const datesRange = generateDatesRange(`${thisYear}-01-01T00:00:00.000Z`, `${thisYear + 1}-12-31T00:00:00.000Z`);

        return <>
            <Modal
                data-test-id='review-modal'
                open={Boolean(this.props.openRescheduleModal)}
                onClose={() => this.props.setOpenRescheduleModal?.(false)}
            >
                <TypographyView>
                    <Formik
                        initialValues={this.props.modalData || initRescheduleFormValues}
                        validationSchema={Yup.object().shape({
                            name: Yup.string(),
                            serviceName: Yup.string(),
                            duration: Yup.string(),
                            slot: Yup.string(),
                            date: Yup.string(),
                            price: Yup.number()
                        })}
                        onSubmit={async (values) => {
                            const [start_time, end_time] = values.slot.split(" - ")
                            const data = {
                                start_time: getPureDateString(values.date, start_time),
                                end_time: getPureDateString(values.date, end_time),
                                booking_date: dayjs(values.date).format("YYYY-MM-DD"),
                                price: values.duration?.price,
                            }
                            await this.rescheduleBooking(data, this.props.id)
                            this.props.setOpenRescheduleModal?.(false)
                            this.props.setOpenSnack?.()
                        }}
                    >
                        {({
                            handleChange,
                            errors,
                            handleBlur,
                            setFieldValue,
                            values,
                            submitForm
                        }) => {
                            const isInitSlide = Boolean(this.state.anchorEl && !this.sliderShown.current);
                            const presentTime = values.slot;

                            const {
                                duration,
                                timeslots,
                                dates,
                                price,
                            } = values.duration || {};

                            const scheduleSlots = calculateSlots(
                                resOpenTiming,
                                duration,
                                {
                                    timeSlots: timeslots,
                                    dates,
                                    currentDate: datesRange[this.state.currentSlotIndex],
                                },
                                isInitSlide
                            );

                            if (isInitSlide) {
                                // Set initial slide for each of slots popover
                                const copyDatesRange: any = this.state.datesRange;
                                copyDatesRange[0] = this.renderDatarange(datesRange[0], 0, scheduleSlots, setFieldValue);
                                this.setDatesRange(copyDatesRange);
                                this.sliderShown.current = true;
                            }

                            return (
                                <RegistrationFormView
                                    dir={dir}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '45%',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '8px 8px 32px 8px',
                                        outline: 'none'
                                    }}
                                >
                                    <Box p={3} pl={5} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography
                                            style={{
                                                fontSize: '24px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {this.props.modalContent?.title}
                                        </Typography>
                                        <CloseIcon
                                            data-test-id='review-close-btn'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => this.props.setOpenRescheduleModal?.(false)}
                                        />
                                    </Box>
                                    <Box px={5} pb={2} borderBottom='1px solid #E2E8F0'>
                                        <Box >
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">{t['booking-reschedule-name-input']}</InputLabel>
                                            </Box>
                                            <TextField
                                                disabled
                                                name='name'
                                                value={values.name}
                                                fullWidth={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                className="inputeStyle"
                                                data-test-id="txtInputSpaName"
                                            />
                                        </Box>
                                        <Box>
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">{t['booking-reschedule-service-type-input']}</InputLabel>
                                            </Box>
                                            <Autocomplete
                                                disabled
                                                data-test-id="service-type-input"
                                                fullWidth
                                                forcePopupIcon={false}
                                                options={[]}
                                                className='inputeStyle'
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                disablePortal
                                                value={values.serviceName}
                                                onChange={(event: React.ChangeEvent<any>) => setFieldValue('serviceName', event.target.textContent)}
                                                onBlur={handleBlur('serviceName')}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true,
                                                            style: this.getAutocompleteStyle(false),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box width='100%'>
                                            <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                                                <InputLabel className="testLabel">{t['booking-reschedule-duration-input']}</InputLabel>
                                            </Box>
                                            <Autocomplete
                                                disabled
                                                data-test-id="duration-input"
                                                forcePopupIcon={false}
                                                options={this.props.durationSlots || []}
                                                getOptionLabel={(option) => getTransDuration(option.duration)}
                                                className='inputeStyle'
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                disablePortal
                                                value={values.duration}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder='Select duration'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true,
                                                            style: this.getAutocompleteStyle(false),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box
                                            data-test-id='select-slot-block'
                                            mt={2}
                                            p={1}
                                            borderRadius='30px'
                                            border='1px solid #E2E8F0'
                                            bgcolor='#ffffff'
                                            whiteSpace='nowrap'
                                            mb='14px'
                                            position='relative'
                                            display='flex'
                                            alignItems='center'
                                            style={{ cursor: "pointer" }}
                                            onClick={(event) => {
                                                this.setState({ anchorEl: event.currentTarget })
                                                const copyDatesRange = this.state.datesRange;
                                                const resIndex = this.state.currentSlotIndex
                                                copyDatesRange[resIndex] = this.renderDatarange(datesRange[resIndex], resIndex, scheduleSlots, setFieldValue);
                                                this.setDatesRange(copyDatesRange);
                                            }
                                            }
                                        >
                                            <Box
                                                p={1}
                                                borderRadius='50%'
                                                bgcolor='#D1FAE5'
                                                display='inline-block'
                                                mr='14px'
                                            >
                                                <img
                                                    style={{ display: 'block' }}
                                                    src={imgPendingClockGreen}
                                                    alt='map marker'
                                                />
                                            </Box>
                                            <Typography
                                                dir='ltr'
                                                style={{
                                                    ...styles.regularMiddle,
                                                    display: 'inline-block',
                                                    verticalAlign: 'text-bottom',
                                                    color: '#94A3B8',
                                                    marginRight: '8px',
                                                    ...(presentTime && {
                                                        color: '#334155',
                                                        fontWeight: 700
                                                    })
                                                }}>
                                                {presentTime}
                                            </Typography>
                                            <img
                                                src={arrowDown}
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    ...(isArabic ? {
                                                        left: '24px'
                                                    } : {
                                                        right: '24px'
                                                    })
                                                }}
                                                alt='arrow'
                                            />
                                        </Box>

                                        <Popover
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={() => {
                                                this.setState({
                                                    anchorEl: null,
                                                    currentSlotIndex: 0
                                                });
                                                this.sliderShown.current = false;
                                            }}
                                            anchorEl={this.state.anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 21,
                                            }}
                                            style={styles.popperStyle}
                                            PaperProps={{
                                                style: {
                                                    width: '500px',
                                                },
                                            }}
                                        >
                                            <Box p={3} >
                                                <CarouselProvider
                                                    dragEnabled={false}
                                                    naturalSlideWidth={500}
                                                    naturalSlideHeight={164}
                                                    totalSlides={datesRange.length}
                                                    visibleSlides={1}
                                                    isIntrinsicHeight
                                                    className='venue-schedule-carousel'
                                                >
                                                    <Box display='flex' gridGap='5px' alignItems='center'>
                                                        <Box>
                                                            <ButtonBack
                                                                onClick={
                                                                    () => this.state.currentSlotIndex > 0 && this.setState({
                                                                        currentSlotIndex: this.state.currentSlotIndex - 1
                                                                    })}
                                                                style={styles.scheduleArrow}
                                                            >
                                                                <KeyboardArrowLeft style={{ width: '18px' }} />
                                                            </ButtonBack>
                                                            <ButtonNext
                                                                onClick={
                                                                    () => {
                                                                        if (this.state.currentSlotIndex < datesRange.length) {
                                                                            this.setState({ currentSlotIndex: this.state.currentSlotIndex + 1 });
                                                                            const resIndex = this.state.currentSlotIndex + 1;

                                                                            // Set each next slide separatelly to prevent performance issue
                                                                            const copyDatesRange = this.state.datesRange;
                                                                            copyDatesRange[resIndex] = this.renderDatarange(datesRange[resIndex], resIndex, scheduleSlots, setFieldValue);
                                                                            this.setDatesRange(copyDatesRange);
                                                                        }
                                                                    }
                                                                } style={styles.scheduleArrow} >
                                                                <KeyboardArrowRight style={{ width: '18px' }} />
                                                            </ButtonNext>
                                                        </Box>
                                                        <Typography
                                                            style={{
                                                                ...styles.boldSmall,
                                                                color: '#000000'
                                                            }}
                                                        >
                                                            {this.formatDate(datesRange[this.state.currentSlotIndex], locale)}
                                                        </Typography>
                                                    </Box>

                                                    <Slider
                                                        classNameTray='schedule-tray'
                                                        style={{
                                                            display: 'flex',
                                                            margin: '28px 0'
                                                        }}>
                                                        {this.state.datesRange}
                                                    </Slider>
                                                </CarouselProvider>
                                            </Box>
                                        </Popover>

                                        <Box
                                            mt={2}
                                            p={1}
                                            borderRadius='30px'
                                            border='1px solid #E2E8F0'
                                            bgcolor='#ffffff'
                                            whiteSpace='nowrap'
                                            mb='14px'
                                            style={{
                                                cursor: 'pointer',
                                                background: "#F8FAFC"
                                            }}>
                                            <PriceInput
                                                name='price'
                                                value={`${this.currency} ${price}`}
                                                disabled
                                                fullWidth={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                variant="standard"
                                                data-test-id="txtInputSpaName"
                                                placeholder={"Enter price"}
                                                InputProps={{
                                                    startAdornment: (
                                                        <Box
                                                            p={1}
                                                            borderRadius='50%'
                                                            bgcolor='#FEF3C7'
                                                            mr='14px'
                                                        >
                                                            <img
                                                                style={{ display: 'block' }}
                                                                src={imgDollarSign}
                                                                alt='map marker'
                                                            />
                                                        </Box>
                                                    ),
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>
                                        <Typography className="txtErrorMsg">{(errors as any).name}</Typography>
                                    </Box>
                                    <Box p={3}>
                                        <Box
                                            display='flex'
                                            justifyContent='space-between'
                                            width='100%'>
                                            <Button
                                                data-test-id='cancel-btn'
                                                variant="contained"
                                                type='submit'
                                                color="primary"
                                                className="declineButton"
                                                style={{
                                                    marginLeft: 'auto',
                                                    width: 'auto',
                                                    padding: '6px 16px'
                                                }}
                                                onClick={submitForm}
                                            >
                                                {this.props.modalContent?.buttonTitle}
                                            </Button>
                                        </Box>
                                    </Box>

                                </RegistrationFormView>
                            )
                        }}
                    </Formik>
                </TypographyView>
            </Modal>
        </>
    }
}
