export const bookingColorTable = [
  '#059669',
  '#6000E6',
  '#DC2626',
  '#64748B',
  '#75573345',
  '#D97706',
  '#6100E724',
  '#00A1FB47',
  '#C71585',
  '#800080',
  '#B22222',
  '#FF4500',
  '#BDB76B',
  '#006400',
  '#191970',
  '#8B4513',
];
