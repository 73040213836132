import { Box, Button, Typography } from '@material-ui/core'
import { Slide } from 'pure-react-carousel'
import React from 'react'

export const renderDatarange = (index: number, scheduleSlots: {
    time: string,
    duration: string,
    isAvailable: boolean
}[]) => (
    <Slide index={index}>
        <Box
            display='flex'
            flexWrap='wrap'
            gridGap='16px'
            justifyContent='flex-start'
            width='500px'
            border='none'
        >
            {scheduleSlots.map((slot, index) =>
                <>
                    <Button
                        key={index}
                        style={{
                            display: 'block',
                            padding: '4px 8px',
                            border: '1px solid #E2E8F0',
                            borderRadius: '6px',
                            color: '#64748B',
                            maxHeight: '3.2rem',
                            minWidth: '28%',
                            textAlign: 'left',
                            backgroundColor: '#FFFFFF',
                            ...(slot.isAvailable && {
                                backgroundColor: '#398378',
                                color: '#FFFFFF'
                            })
                        }}
                    >
                        <Typography
                            className='slot-name'
                            style={{
                                fontSize: '12px',
                                marginBottom: '4px',
                            }}
                        >
                            {slot.time}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '10px',
                                color: '#94A3B8',
                                textTransform: 'none'
                            }}
                        >
                            {slot.duration}
                        </Typography>
                    </Button>
                </>
            )}
        </Box>
    </Slide>)
