import React from "react";
// Customizable Area Start
import { Box, Button, CircularProgress, Popover, Typography } from '@material-ui/core';
import { CarouselProvider, Slide, Slider, Image, Dot, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { AppHeader } from '../../../components/src/AppHeader.web';
import {
  imgArrowDownTriangle,
  imgCarouselArrowBack,
  imgCarouselArrowNext,
  imgDollarSign,
  imgLocationSearch,
  imgPendingClock,
  imgVenue01,
} from './assets';
import { AppFooter } from '../../../components/src/AppFooter.web';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LandingPageController, { SubCategory, configJSON, S } from './LandingPageController';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { TypographyView } from './styledFont';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { calculateSlots, generateDatesRange } from '../../../components/src/datesRangeHelper';
import ReviewsView from '../../../components/src/Reviews.web';
import { getStars, getTransContent, getTransDuration, getTranslationConfig } from '../../../components/src/helpers';
import { getStorageData } from '../../../framework/src/Utilities';
import MapLocation from "../../../components/src/MapLocation";
import { styles as outerStyles } from '../../../components/src/styles';

interface Props {
  navigation: any;
  id: string;
}

interface TimeSlot {
  time: string;
  duration: string;
  ordered: boolean;
}

interface TimeSlotButtonProps {
  slot: TimeSlot;
  isSelected: boolean;
  isAvailable: boolean;
  onClick: () => void;
}

export default class LandingPageVenue extends LandingPageController {
  backBtnRef: any;
  nextBtnRef: any;
  isLeftClick: boolean = false;
  prevIsAvailable: boolean | null = null;

  constructor(props: Props) {
    super(props);

    this.backBtnRef = React.createRef();
    this.nextBtnRef = React.createRef();
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getUserDetails();
    await this.initializeSelectedSubcategory();
    await this.detectTimeZone();
    await this.checkIfOpen();

    const selectedTimeSlot = await getStorageData('selectedTimeSlotPrice');
    const parsedTimeSlot = JSON.parse(selectedTimeSlot);
    this.setState({ selectedTimeSlotPrice: parsedTimeSlot });
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (!prevState.userDetails && this.state.userDetails) {
      this.initializeSelectedSubcategory();
    }

    if (JSON.stringify(prevState.selectedCatalogue) !== JSON.stringify(this.state.selectedCatalogue)) {
      this.setCatalogueChanged(true);
      this.updateReorderedDates();
    }

    if (this.state.selectedCatalogue !== prevState.selectedCatalogue) {
      this.updateReorderedDates();
    }
  }
  shouldComponentUpdate(nextProps: Props, nextState: S): boolean {
    return (
      nextProps !== this.props ||
      nextState !== this.state
    );
  }


  renderDatarange = (
    currentDate: string,
    index: number,
    scheduleSlots: {
      time: string,
      duration: string,
      ordered: boolean
    }[],
    id: number | null,
    availableSlots: string[],
    newDate: string,
    openTiming: string | undefined
  ) => {
    const availableDurations = [...new Set(this.state.selectedCatalogue?.availabilities.map(availability =>
      availability.duration.duration
    ))];

    const filteredScheduleSlots = this.filterPassedSlots(scheduleSlots, newDate, openTiming)
      .filter(slot => availableDurations.includes(slot.duration));

    if (filteredScheduleSlots.length === 0) {
      return (
        <Slide index={index} key={`${currentDate}${JSON.stringify(availableSlots)}`}>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='420px'
            height='100px'
          >
            <Typography>No slots available for this duration</Typography>
          </Box>
        </Slide>
      );
    }

    const resultScheduleSlots = Array.isArray(filteredScheduleSlots) ? filteredScheduleSlots.map((slot) => ({
      ...slot,
      ordered: availableSlots.includes(slot.time) && !this.isSlotBooked(slot.time, newDate)
    })) : [];


    const isSelectedDate = this.state.selectedTimeSlot?.date || "";

    return (
      <Slide index={index} key={`${currentDate}${JSON.stringify(availableSlots)}`}>
        <Box
          display='flex'
          flexWrap='wrap'
          gridGap='16px'
          justifyContent='flex-start'
          width='420px'
        >
          {resultScheduleSlots.map((slot, index) => this.renderSlot(currentDate, isSelectedDate, slot, availableSlots, newDate, index))}

        </Box>
      </Slide>
    );
  };

  renderSlot = (currentDate: string, isSelectedDate: string, slot: any, availableSlots: string[], newDate: string, index: number) => {
    const key = `${currentDate}-${slot.time}-${index}`;
    const isSelected = this.isSlotSelected(isSelectedDate, slot.time);
    const isAvailable = availableSlots.includes(slot.time) && !this.isSlotBooked(slot.time, newDate);
    slot.ordered = isAvailable;

    if (isAvailable !== this.prevIsAvailable) {
      this.prevIsAvailable = isAvailable;
    }

    return <TimeSlotButton key={key} slot={slot} isSelected={isSelected} isAvailable={isAvailable} onClick={() => this.selectTimeSlot(currentDate, slot)} />;
  };

  selectTimeSlot = (date: string, slot: { time: string; duration: string; ordered: boolean } | null) => {
    this.setState(prevState => {
      const isSelectedDate = prevState.selectedTimeSlot?.date || null;
      return {
        selectedTimeSlot: slot ? { date, slot: { time: slot.time, duration: slot.duration, ordered: slot.ordered } } : null
      };
    });
  };

  isSlotSelected = (date: string, slotTime: string): boolean => {
    const { selectedTimeSlot } = this.state;
    return Boolean(selectedTimeSlot && selectedTimeSlot.date === date && selectedTimeSlot.slot.time === slotTime);
  };

  handlePrevDate = () => {
    this.setState(prevState => {
      const newIndex = Math.max(0, prevState.currentSlotIndex - 1);
      const newDate = prevState.reorderedDates[newIndex];
      return { currentSlotIndex: newIndex, currentDate: newDate };
    });
  }

  handleNextDate = () => {
    this.setState(prevState => {
      const newIndex = Math.min(prevState.reorderedDates.length - 1, prevState.currentSlotIndex + 1);
      const newDate = prevState.reorderedDates[newIndex];
      return {
        ...prevState,
        currentSlotIndex: newIndex,
        currentDate: newDate
      };
    });
  }

  formatDate = (dateStr: string | undefined, isArabic?: boolean) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return '';
    const day = date.getDate();
    const locale = isArabic ? 'ar-eg' : 'default';
    const month = date.toLocaleString(locale, { month: 'long' });
    return `${day} ${month}`;
  }

  getCategoryNavigationMap = (categoryId: number, categoryName: string) => ({
    'Home': 'LandingPage',
    [categoryName]: `SubCategories/${categoryId}`,
  });

  render() {
    const { t, dir, isArabic } = getTranslationConfig();
    const {
      open_timing: openTiming,
      profile_data: profileData,
      average_review: avgReview,
      carousal_images: carouselImages,
      full_name: SPAVenueName,
      services,
      image_url
    } = this.state.userDetails?.attributes || {};

    const { averageReviewString } = getTransContent(this.state.userDetails?.attributes);

    const availableSlots = this.state.selectedCatalogue?.availabilities[0]?.timeslots || [];

    const SPAVenueAddress = profileData?.attributes?.address;

    const resultRating = Number(avgReview)
    const resultRatingString = avgReview ? avgReview.toFixed(1) : '';
    const venueServices: SubCategory[] = [];

    services?.forEach((service) => {
      venueServices.push(...service.subcategories_with_slots)
    });

    const dotsArray = this.calculateDotsDimensions();

    const wideScreen = window.innerWidth > 1366;
    const showServicesCarouselArrows = venueServices.length > (wideScreen ? 3 : 2);

    const currentSlots = venueServices[this.state.buttonIndex]?.slots;
    const slotsLength = currentSlots?.length;
    const servicesLength = services?.length;

    const time = this.state.selectedTimeSlotPrice?.time
    const price = this.state.selectedTimeSlotPrice?.price
    const subcategoryName = this.state.selectedTimeSlotPrice?.subcategoryName

    const { selectedCatalogue } = this.state;
    const newSlotsAvailable = selectedCatalogue ? selectedCatalogue.availabilities.map(availability => availability.timeslots).flat() : [];

    const reorderedDates = this.state.reorderedDates
    const newDate = this.state.currentDate

    const categoryBreadcrumbs = services?.[0].category.name
    const categoryId = services?.[0]?.category?.id || 0;
    const categoryName = services?.[0]?.category?.name || '';
    const dynamicNavigationMap = this.getCategoryNavigationMap(categoryId, categoryName);


    // Custom style sheets
    const styles = {
      SafeAreaView: {
        backgroundColor: '#e8f6f4ff',
      },
      inputSearch: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSize: '24px',
        color: '#334155',
        padding: '10px 8px',
        display: 'block',
        width: 'auto'
      },
      pageTile: {
        backgroundColor: '#ffffff',
        cursor: 'pointer'
      },
      boldSmall: {
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        fontWeight: 700,
        color: '#000000'
      },
      regularMiddle: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
        color: '#000000'
      },
      tileParagraph: {
        padding: '13px 0'
      },
      dotsOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      venueStatus: {
        color: '#34D399',
        marginRight: '10px'
      },
      serviceBlock: {
        marginTop: '20px',
        backgroundColor: '#ffffff',
        padding: '16px'
      },
      carouselButton: {
        borderRadius: '100px',
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.25)'
      },
      scheduleArrow: {
        background: 'none',
        border: 'none',
        padding: 0
      }
    };
    const popperStyle = {
      bgcolor: 'background.paper',
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
      marginTop: '2px'
    };
    return (
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader
            onClickEvent={() => this.goToLogin()}
            onProfileClick={this.handleProfileClick}
            forceUpdate={this.forceUpdate.bind(this)}
            context={this}
          />
          <Box
            className='search-location-block'
            py={6}
            px='140px'
            dir={dir}
          >
            <BreadCrumbs
              items={[t['breadcrumbs-home'], categoryBreadcrumbs, String(SPAVenueName)]}
              navigationMap={dynamicNavigationMap}
              navigate={this.handleNavigate}
              targetColor='#398378'
            />
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: '700',
                padding: '30px 0'
              }}
            >{SPAVenueName}</Typography>
            <Box p='12px' width='35%' borderRadius='30px' bgcolor='#ffffff' whiteSpace='nowrap' mb='14px'>
              <Box
                {...outerStyles.roundSlug}
                bgcolor='#FEF3C7'
              >
                <img src={imgLocationSearch} alt='map marker' />
              </Box>
              <Typography
                style={{
                  ...styles.regularMiddle,
                  ...styles.dotsOverflow,
                  display: 'inline-block',
                  width: '80%',
                  marginRight: '16px'
                }}>
                {SPAVenueAddress}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' mb='60px'>
              <Box p='12px' width='35%' borderRadius='30px' bgcolor='#ffffff' whiteSpace='nowrap' mb='14px'>
                <Box
                  {...outerStyles.roundSlug}
                  bgcolor='#D1FAE5'
                >
                  <img src={imgPendingClock} alt='map marker' />
                </Box>
                <Typography
                  style={{
                    ...styles.regularMiddle,
                    ...styles.dotsOverflow,
                    display: 'inline-block',
                    width: '80%',
                    marginRight: '16px'
                  }}>
                  {openTiming !== 'Close' ? (
                    <>
                      <Typography display='inline'>
                        {t['landing-page-venues-opened']}{' '}
                      </Typography>
                      <span
                        dir='ltr'
                        style={{ fontWeight: 'bold' }}
                      >
                        {openTiming}
                      </span>
                    </>
                  ) : (
                    <span style={{ color: 'red' }}>{t['landing-page-venues-closed']}</span>
                  )}
                </Typography>
              </Box>
              {/* Reviews */}
              <Box>
                <Box
                  display='flex'
                  {...isArabic && {
                    justifyContent: 'flex-end'
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '32px',
                      color: '#000000',
                      paddingRight: '8px',
                    }}>
                    {resultRatingString}
                  </Typography>
                  {/* stars */}
                  <Box
                    display='flex'
                    alignItems='center'
                  >
                    {getStars(resultRating)}
                  </Box>
                </Box>
                <Typography
                  style={{
                    color: '#64748B',
                    textAlign: 'end'
                  }}
                >
                  {averageReviewString}
                </Typography>
              </Box>
            </Box>
            {/* venue carousel */}
            <CarouselProvider
              touchEnabled
              dragEnabled
              naturalSlideWidth={1162}
              naturalSlideHeight={558}
              totalSlides={Number(carouselImages?.length)}
              className='venue-carousel'
            >
              <Slider>
                {(carouselImages)?.map((image, index) =>
                  <Slide
                    index={index}
                    key={image.id}
                    onClick={
                      (event) => this.onSliderClick(event, this.backBtnRef.current, this.nextBtnRef.current)
                    }>
                    <Image
                      hasMasterSpinner={true}
                      src={image.url}
                      alt='venue image'
                      style={{
                        width: 'fit-content',
                        margin: 'auto'
                      }} />
                  </Slide>)}
              </Slider>
              {/* <DotGroup /> */}
              <Box style={{
                position: 'absolute',
                bottom: '43px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                alignItems: 'center',
                gridGap: '8px'
              }} >
                {carouselImages?.map((_, index) => {
                  const dimension = `${dotsArray[index]}px`;
                  return <Dot
                    slide={index}
                    onClick={() => this.setState({ venueCarouselIndex: index })}
                    style={{
                      width: dimension,
                      height: dimension,
                    }} />
                })}
              </Box>
              <Box display='flex' style={{ opacity: '0' }} className='venue-search'>
                <ButtonBack>
                  <div ref={this.backBtnRef} />
                </ButtonBack>
                <ButtonNext>
                  <div ref={this.nextBtnRef} />
                </ButtonNext>
              </Box>
            </CarouselProvider>

            <Box mt='60px' display='flex' justifyContent='space-between'>
              <Box width='60%'>
                <Box>
                  <Typography
                    style={{
                      fontSize: '36px',
                      fontWeight: '700',
                      color: '#000000'
                    }}
                  >
                    {t['landing-page-venues-services-title']}
                  </Typography>
                  <Box style={styles.serviceBlock}>
                    <CarouselProvider
                      touchEnabled
                      dragEnabled
                      naturalSlideWidth={659}
                      naturalSlideHeight={68}
                      totalSlides={venueServices.length}
                      visibleSlides={wideScreen ? 3 : 2}
                      isIntrinsicHeight
                      className='venue-service-carousel'
                    >
                      <Slider
                        classNameTrayWrap='buttons-carosel-tray'
                        style={{
                          display: 'flex',
                          width: '100%'
                        }}>
                        {servicesLength ? venueServices.map((service, index) => {
                          const isActive = index === this.state.buttonIndex;
                          const { id, name } = service?.subcategory || {};

                          const subCatName = getTransContent(service?.subcategory).name;

                          return (
                            <Slide index={index} key={id}>
                              <Button
                                onClick={() => {
                                  this.handleButtonClick(index, id, { id, name });
                                }}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  fontWeight: '700',
                                  fontSize: '16px',
                                  padding: '16px',
                                  borderRadius: '50px',
                                  border: '1px solid #FFFFFF',
                                  color: isActive ? '#398378' : '#64748B',
                                  ...(isActive && {
                                    backgroundColor: '#E8F6F4',
                                    border: '1px solid #D9EEEB',
                                  })
                                }}
                              >{subCatName}</Button>
                            </Slide>)
                        }) :
                          <Typography
                            style={{ minWidth: '300px' }}
                          >
                            {t['landing-page-venues-services-not-available']}
                          </Typography>}
                      </Slider>
                      {showServicesCarouselArrows && <Box whiteSpace='nowrap'>
                        <ButtonBack>
                          <Box style={styles.carouselButton}>
                            <img src={imgCarouselArrowBack} alt='arrow-back' />
                          </Box>
                        </ButtonBack>
                        <ButtonNext>
                          <Box style={styles.carouselButton}>
                            <img src={imgCarouselArrowNext} alt='arrow-next' />
                          </Box>
                        </ButtonNext>
                      </Box>}
                    </CarouselProvider>
                  </Box>

                  {slotsLength && openTiming !== 'Close' ? currentSlots?.map((slot, index) => {
                    const {
                      id,
                      price,
                      start_time,
                      end_time,
                      duration
                    } = slot || {};

                    const durationViewString = getTransDuration(duration);
                    const copyDatesRange = this.state.datesRange;

                    const scheduleSlots = calculateSlots(String(openTiming), duration);
                    const datesRange = generateDatesRange(start_time, end_time);

                    if (!this.state.datesRange[this.state.buttonIndex][index] || this.state.catalogueChanged) {
                      // Prevent internal loop to return service as not changed
                      this.setCatalogueChanged(false);
                      // Set initial slide for each of slots popover
                      copyDatesRange[this.state.buttonIndex][index] = [this.renderDatarange(datesRange[0], 0, scheduleSlots, this.state.selectedSubcategoryId, availableSlots, newDate, openTiming)];
                      this.setState({ datesRange: copyDatesRange })
                    }

                    return <Box key={slot.id} style={styles.serviceBlock}>
                      <Box style={{
                        font: 'Ubuntu',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '22px',
                        marginBottom: '8px'
                      }}>
                        {getTransContent(this.state.selectServiceName).name?.toUpperCase()}
                      </Box>
                      <Box pt='12px' display='flex' justifyContent='flex-start'>
                        <Box display='flex' alignItems='center' flexBasis='25%'>
                          <Box
                            {...outerStyles.roundSlug}
                            bgcolor='#D1FAE5'
                          >
                            <img src={imgPendingClock} alt='map marker' />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#0F172A',
                              marginRight: '14px'
                            }}
                          >
                            {durationViewString}
                          </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' ml={3}>
                          <Box
                            {...outerStyles.roundSlug}
                            bgcolor='#FEF3C7'
                          >
                            <img src={imgDollarSign} alt='map marker' />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '14px',
                              color: '#0F172A',
                              marginRight: '14px'
                            }}
                          >
                            {`${this.currency} ${price}`}
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        endIcon={
                          <img src={imgArrowDownTriangle} alt='arrow down' />
                        }
                        style={{
                          marginTop: '16px',
                          padding: '5px 12px',
                          fontSize: '12px',
                          color: '#fff',
                          borderRadius: '8px',
                          backgroundColor: '#398378',
                          fontWeight: '700'
                        }}
                        onClick={(event) => {
                          this.setState({ anchorEl: event.currentTarget, currentPopperIndex: index });
                        }}
                      >
                        {t['landing-page-venues-book-slots']}
                      </Button>
                      <Popover
                        open={Boolean(this.state.anchorEl) && this.state.currentPopperIndex === index}
                        onClose={() => {
                          this.setState({ anchorEl: null });
                          this.setState({ currentSlotIndex: 0 });
                        }}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 21,
                        }}
                        style={popperStyle}
                        PaperProps={{
                          style: { width: '460px' },
                        }}
                      >
                        <Box p={3} >
                          <CarouselProvider
                            touchEnabled
                            dragEnabled
                            naturalSlideWidth={410}
                            naturalSlideHeight={164}
                            totalSlides={reorderedDates.length}
                            currentSlide={this.state.currentSlotIndex}
                            visibleSlides={1}
                            isIntrinsicHeight
                            className='venue-schedule-carousel'
                          >
                            <Box display='flex' gridGap='5px' alignItems='center'>
                              <Box>
                                <ButtonBack onClick={this.handlePrevDate} style={styles.scheduleArrow} >
                                  <KeyboardArrowLeft style={{ width: '18px' }} />
                                </ButtonBack>
                                <ButtonNext onClick={this.handleNextDate} style={styles.scheduleArrow}>
                                  <KeyboardArrowRight style={{ width: '18px' }} />
                                </ButtonNext>
                              </Box>
                              <Typography
                                style={{
                                  ...styles.boldSmall,
                                  color: '#000000'
                                }}
                              >
                                {reorderedDates && reorderedDates.length > this.state.currentSlotIndex
                                  ? this.formatDate(reorderedDates[this.state.currentSlotIndex], isArabic)
                                  : t['landing-page-venues-book-no-dates']}
                              </Typography>
                            </Box>
                            <Slider
                              classNameTray='schedule-tray'
                              style={{
                                display: 'flex',
                                margin: '28px 0'
                              }}>
                              {reorderedDates.map((date, index) =>
                                this.renderDatarange(
                                  date,
                                  index,
                                  scheduleSlots,
                                  this.state.selectedSubcategoryId,
                                  newSlotsAvailable,
                                  newDate, openTiming
                                )
                              )}
                            </Slider>
                            <Box display='flex' gridGap='12px'>
                              <Button
                                style={{
                                  padding: '10px 16px',
                                  fontSize: '16px',
                                  color: '#398378',
                                  borderRadius: '8px',
                                  backgroundColor: '#E8F6F4',
                                  fontWeight: '700',
                                  width: '90px',
                                  textTransform: 'none'
                                }}
                                onClick={() => this.setState({ anchorEl: null, selectedTimeSlot: null })}
                              >
                                {t['landing-page-venues-book-cancel']}
                              </Button>
                              <Button
                                style={{
                                  padding: '10px 16px',
                                  fontSize: '16px',
                                  color: '#ffffff',
                                  borderRadius: '8px',
                                  backgroundColor: '#398378',
                                  fontWeight: '700',
                                  width: '90px',
                                  textTransform: 'none'
                                }}
                                onClick={() => {
                                  this.storeSelectedTimeSlot(price, id);
                                  this.setState({ anchorEl: null });
                                }}
                              >
                                {t['landing-page-venues-book-apply']}
                              </Button>
                            </Box>
                          </CarouselProvider>
                        </Box>
                      </Popover>
                    </Box>
                  }) :
                    <Typography
                      style={{
                        width: 'fit-content',
                        margin: '35px auto auto',
                      }}
                    >
                      {t['landing-page-venues-slots-not-available-text']}
                    </Typography>}
                </Box>

                <hr color='#398378' style={{
                  height: '1.5px',
                  margin: '35px 0'
                }} />

                <Typography
                  style={{
                    fontSize: '36px',
                    fontWeight: '700',
                    color: '#000000'
                  }}
                >
                  {t['landing-page-venues-reviews']}
                </Typography>

                <ReviewsView
                  getUserReviews={this.getUserReviews.bind(this)}
                  createUserReview={this.createUserReview.bind(this)}
                  pathParam={this.pathParam}
                  gotoLogin={this.gotoLogin.bind(this)}
                  reviewsList={this.state.reviewsList}
                  goToReviews={this.goToReviews.bind(this)}
                  showCreateReviewModal={this.state.showCreateReviewModal}
                  setShowCreateReviewModal={this.setShowCreateReviewModal.bind(this)}
                />
              </Box>

              {/* Next block */}
              <Box width='38%'>
                <Box p='12px' border='1px solid #E2E8F0'>
                  <Box display='flex'>
                    <img
                      src={image_url || imgVenue01}
                      alt='venue-avatar'
                      style={{
                        maxHeight: '100%',
                        maxWidth: '81px',
                        borderRadius: '8px',
                        flex: 1
                      }} />
                    <Box mx={3}>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: '700',
                          color: '#000000',
                          marginBottom: '6px'
                        }}
                      >
                        {SPAVenueName}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '14px',
                          color: '#334155',
                          marginBottom: '6px'
                        }}
                      >
                        {SPAVenueAddress}
                      </Typography>
                      {/* stars */}
                      <Box display='flex'>
                        {getStars(resultRating)}
                      </Box>
                    </Box>
                  </Box>
                  {this.state.selectedTimeSlotPrice && (
                    <Box mt={2}>
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: '700',
                          color: '#000000',
                          marginBottom: '6px'
                        }}
                      >
                        {t['landing-page-venues-overview']}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: "24px",
                          color: '#334155',
                          marginBottom: '6px',
                          font: "Ubuntu"
                        }}
                      >
                        {getTransContent(subcategoryName).name}
                      </Typography>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          bgcolor='#E8F6F4'
                          p={1}
                          borderRadius='10px'
                        >
                          <span
                            dir='ltr'
                            style={{
                              color: '#398378',
                              fontSize: '14px',
                              fontWeight: 'bold',
                            }}
                          >
                            {time}
                          </span>
                        </Box>
                      </Box>
                      <hr style={{ borderColor: "#f5f5f5" }} />
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography
                          style={{
                            color: '#0F172A',
                            fontWeight: 700,
                            marginTop: '14px',
                          }}
                        >
                          {t['landing-page-venues-total']}
                        </Typography>
                        <Typography
                          style={{
                            color: '#0F172A',
                            fontWeight: 700,
                            marginTop: '14px',
                          }}
                        >
                          {this.currency} {price}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Button
                    style={{
                      display: 'flex',
                      margin: 'auto',
                      marginTop: '32px',
                      fontSize: '16px',
                      fontWeight: '700',
                      color: '#398378',
                      padding: '7px 16px',
                      width: '80%',
                      border: '1px solid #398378',
                      backgroundColor: '#ffffff'
                    }}
                    onClick={() => this.gotoBookingDetail()}
                  >
                    {t['landing-page-venues-book-continue']}
                  </Button>
                </Box>
                <Typography
                  style={{
                    ...styles.boldSmall,
                    marginBottom: '4px',
                    marginTop: '40px',
                  }}>
                  {t['landing-page-venues-location']}
                </Typography>
                <Box p='12px' borderRadius='30px' bgcolor='#ffffff' whiteSpace='nowrap' mb='14px'>
                  <Box
                    {...outerStyles.roundSlug}
                    bgcolor='#FEF3C7'
                  >
                    <img src={imgLocationSearch} alt='map marker' />
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.dotsOverflow,
                      display: 'inline-block',
                      width: '85%',
                      marginRight: '16px'
                    }}>
                    {SPAVenueAddress}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center' height='400px'>
                  {SPAVenueAddress ?
                    <MapLocation address={SPAVenueAddress} /> :
                    <CircularProgress style={{ color: '#398378' }} />}
                </Box>
                <hr color='#398378' style={{
                  height: '1.5px',
                  margin: '35px 0'
                }} />
                <Box>
                  <Typography
                    style={{
                      ...styles.boldSmall,
                      color: '#0F172A',
                      marginBottom: '8px'
                    }}>
                    {t['landing-page-venues-about']}
                  </Typography>
                  <Typography
                    style={{
                      marginTop: '16px',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#334155',
                      fontFamily: "Ubuntu"
                    }}>
                    {getTransContent(profileData?.attributes).bio}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView >
    )
  }
}

const TimeSlotButton: React.FC<TimeSlotButtonProps> =
  ({ slot, isSelected, isAvailable, onClick }) => (
    <Button
      style={{
        display: 'block',
        padding: '4px 8px',
        border: '1px solid #E2E8F0',
        borderRadius: '6px',
        color: isAvailable ? (isSelected ? '#FFFFFF' : '#64748B') : '#94A3B8',
        width: '30%',
        backgroundColor: isAvailable ? (isSelected ? '#398378' : '#FFFFFF') : '#E2E8F0',
      }}
      disabled={!isAvailable && !isSelected}
      onClick={onClick}
    >
      <Typography
        className='slot-name'
        style={{
          fontSize: '12px',
          marginBottom: '4px',
        }}
      >
        {slot.time}
      </Typography>
      <Typography
        style={{
          fontSize: '10px',
          color: '#94A3B8',
          textTransform: 'none'
        }}
      >
        {slot.duration}
      </Typography>
    </Button>
  )
// Customizable Area End