import { Box, Button, Modal, styled } from '@material-ui/core';
import React from 'react';
import Ordermanagement1Controller, { Props } from '../Ordermanagement1Controller';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { profileCover } from '../assets';
import { getTranslationConfig } from '../../../../components/src/helpers';

const ModalOverlay = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const ModalContent = styled(Box)({
    backgroundColor: 'white',
    width: '400px',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px 8px 32px 8px',

});

const BackgroundImage = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '170px',
    backgroundImage: `url(${profileCover})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 1,
});

const CheckCircleWrapper = styled(Box)({
    width: '85px',
    height: '85px',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    position: 'relative',
    zIndex: 2,
    marginTop: "70px"
});

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
    fontSize: '90px',
    color: '#398378',
});

const StyledButton = styled(Button)({
    backgroundColor: '#D9EEEB',
    color: "#398378",
    fontSize: '16px',
    fontWeight: 700,
    marginBottom: '10px',
    fontFamily: "Ubuntu",
    lineHeight: "24px",
    padding: "10px",
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#D9EEEB',
    },
});

export default class BookingConfirmationModal extends Ordermanagement1Controller {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { t } = getTranslationConfig();
        const { showConfirmationModal } = this.state;

        return (
            <Modal
                data-test-id='confirmation-modal'
                open={showConfirmationModal}
                onClose={() => this.setOpenBookingConfirmationModal(false)}
            >
                <ModalOverlay data-test-id="modal-overlay"
                    onClick={() => this.setOpenBookingConfirmationModal(false)}>
                    <ModalContent>
                        <BackgroundImage />
                        <Box
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                cursor: 'pointer',
                                zIndex: 4,
                            }}
                        >
                            <CloseIcon
                                data-test-id='update-close-btn'
                                onClick={() => this.setOpenBookingConfirmationModal(false)}
                            />
                        </Box>
                        <Box sx={{ padding: '50px 20px 20px' }}>
                            <CheckCircleWrapper>
                                <StyledCheckCircleIcon />
                            </CheckCircleWrapper>
                            <Box sx={{ marginBottom: '20px' }}>
                                <Box sx={{
                                    fontSize: '32px', fontWeight: 500, marginBottom: '10px',
                                    fontFamily: "Ubuntu",
                                    lineHeight: "64px"
                                }}>
                                    {t['booking-confirmation-title']}
                                </Box>
                                <Box sx={{
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    marginBottom: '10px',
                                    fontFamily: "Ubuntu",
                                }}>
                                    {t['booking-confirmation-description']}
                                </Box>
                            </Box>
                            <StyledButton
                                data-test-id="goto-mybookings"
                                variant="contained"
                                color="primary"
                                onClick={() => this.goToCustomerDetails()}
                            >
                                {t['booking-confirmation-btn']}
                            </StyledButton>
                        </Box>
                    </ModalContent>
                </ModalOverlay>
            </Modal>
        )
    }
}
