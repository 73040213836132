const addEntityByType = (
    formData: FormData,
    apiKey: string,
    entityItem: Blob & { name: string }
) =>
    entityItem?.name ?
        formData.append(apiKey, entityItem, entityItem.name) :
        formData.append(apiKey, String(entityItem))

export const getFormDataFromPayload = (APIPayload: Record<string, any>, extForm?: FormData, skipMultiple?: boolean) => {
    const formData = extForm || new FormData();

    for (let apiKey in APIPayload) {
        const entity = APIPayload[apiKey];
        if (Array.isArray(entity) && !skipMultiple) {
            entity.forEach((entityItem) => {
                if (entityItem) {
                    if (entityItem.constructor.name === 'Object') {
                        for (let entityKey in entityItem) {
                            addEntityByType(formData, entityKey, entityItem[entityKey])
                        }
                    } else {
                        addEntityByType(formData, apiKey, entityItem)
                    }
                }
            })
        } else if (entity) {
            addEntityByType(formData, apiKey, entity)
        }
    }
    return formData
}