import { Message } from '../../../framework/src/Message';
import { runEngine } from '../../../framework/src/RunEngine';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { getStorageData } from '../../../framework/src/Utilities';
import { CategorySlot } from '../../../blocks/settings1/src/Settings1Controller';
import { getFormDataFromPayload } from './handle-form';
export const configJSON = require('./config');

type AvailClassInstance = {
  setState: ({ loading }: { loading: true }) => void;
  apiAvailabilityCallId: string;
};

const createUpdateAvailability = async (
  classInstance: AvailClassInstance,
  formData: any,
  isUpdate?: boolean
) => {
  classInstance.setState({ loading: true });
  const authToken = await getStorageData('authToken');
  const headers = {
    token: authToken,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  classInstance.apiAvailabilityCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    isUpdate
      ? configJSON.availabilityUpdateApiEndPoint
      : configJSON.availabilityApiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    isUpdate ? configJSON.updateApiMethod : configJSON.saveApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

export const callAvailability = (
  settingsForm: Record<string, CategorySlot[] | any>,
  classInstance: AvailClassInstance
) => {
  const formData = new FormData();
  const updateFormData = new FormData();
  settingsForm.categorySlots.forEach((categorySlot: CategorySlot) => {
    const availabilities = categorySlot.availabilities || [];

    const { date, capacity, catalogueId } = categorySlot;

    const createAvailsList: Record<string, any>[] = [];
    const updateAvailsList: Record<string, any>[] = [];

    availabilities.forEach((avail) => {
      avail.availabilityId
        ? updateAvailsList.push({
            'availability[][id]': avail.availabilityId,
            'availability[][dates]': JSON.stringify(date),
            'availability[][timeslots]': JSON.stringify(avail.timeSlots),
            'availability[][capacity]': capacity,
          })
        : createAvailsList.push({
            'availability[][catalogue_id]': catalogueId,
            'availability[][dates]': JSON.stringify(date),
            'availability[][bx_block_categories_slot_id]': avail.slotId,
            'availability[][timeslots]': JSON.stringify(avail.timeSlots),
            'availability[][capacity]': capacity,
          });
    });

    updateAvailsList.length &&
      getFormDataFromPayload({ payload: updateAvailsList }, updateFormData);
    createAvailsList.length &&
      getFormDataFromPayload({ payload: createAvailsList }, formData);
  });
  const isFormData = !formData.entries().next().done;
  const isUpdFormData = !updateFormData.entries().next().done;

  isFormData && createUpdateAvailability(classInstance, formData);
  isUpdFormData &&
    createUpdateAvailability(classInstance, updateFormData, true);
};
