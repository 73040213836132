import React, { Component } from 'react';

interface PasswordResetRedirectState {
  email: string | null;
  redirected: boolean;
}

class PasswordResetRedirect extends Component<{}, PasswordResetRedirectState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      email: null,
      redirected: false,
    };

    console.log('PasswordResetRedirect component initialized');
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email');

    if (email) {
      window.location.href = `/NewPasswordScreen?email=${email}`;
    } else {
      console.log('Email missing');
    }
  }

  render() {
    console.log('PasswordResetRedirect component rendering');
    return null;
  }
}

export default PasswordResetRedirect;