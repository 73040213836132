export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgSilverBg = require("../assets/silver-bg.jpg");
export const imgGoldBg = require("../assets/gold-bg.jpg");
export const imgBankCard = require("../assets/bank-card.png");
export const imgVisaLogo = require("../assets/visa-logo.png");
export const imgMasterLogo = require("../assets/master-logo.png");
export const imgDiscoverLogo = require("../assets/discover-logo.png");
export const imgBasicPlan = require("../assets/basic-plan-confirm-img.png");
export const imgAdvancedPlan = require("../assets/advanced-plan-confirm-img.png");
export const imgCheckbox = require("../assets/checkbox-icon.png");
export const imgCheckboxChecked = require("../assets/checkbox-checked-icon.png");
export const imgSpaWarrior = require("../assets/spa-warrior.png");
