import React from "react";

import {
  Box,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
// Customizable Area End

import EmailAccountRegistrationController, { Props } from './EmailAccountRegistrationController';
import { sandClock } from './assets';

export default class EmailAccountRegistrationConfirmation extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box position='relative' className='landing-venue-block' style={{ backgroundColor: '#E8F6F4' }}>
        <AppHeader context={this} />
        <Box
          className='search-location-block'
          height='calc(100vh - 56px)'
          boxSizing='border-box'
          display='flex'
          justifyContent='center'
          alignItems='center'
          py={6}
          px='140px'
        >
          <Box width='360px' textAlign='center' position='relative' height='100%'>
            <Box mt={14}>
              <img src={sandClock} alt='subscription plan' style={{ width: '180px', height: '155px' }} />
              <Typography style={{ fontWeight: 700, margin: '32px 0 16px', fontFamily: 'Ubuntu' }}>
                Application Submitted!
              </Typography>
              <Typography style={{ fontFamily: 'Ubuntu' }}>
                Your application is currently being evaluated. We'll review and keep you posted via email.
              </Typography>
            </Box>
            <Box
              style={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              <Box mt={8} mb={2} style={{ fontFamily: 'Ubuntu' }}>
                <Typography
                  className="account"
                  style={{
                    color: '##334155'
                  }}
                >Back to <span
                  className="signUp"
                  style={{
                    color: '#145249',
                    fontWeight: 700,
                    cursor: 'pointer'
                  }}
                  onClick={this.gotoLogin}
                >Login</span></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* footer */}
        <AppFooter navigation={this.props.navigation} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
