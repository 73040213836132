import React from 'react';
import { Box, Typography, TextField, Slider, Checkbox, FormControlLabel } from '@mui/material';
import { Button, styled } from '@material-ui/core';
import { getTranslationConfig } from '../../../../components/src/helpers';

interface FilterScreenProps {
    onClose: () => void;
    onApply: (filters: FilterValues) => void;
    onClear: () => void;
    maxPrice: number;
    currentFilters: FilterValues;
}

export interface FilterValues {
    priceRange: [number, number];
    selectedServices: string[];
    city: string;
}

interface FilterScreenState {
    priceRange: [number, number];
    selectedServices: string[];
    city: string;
}

const ClearButton = styled(Button)({
    width: '170px',
    height: '45px',
    padding: '16px',
    gap: '8px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    textAlign: 'left',
    fontFamily: 'Ubuntu',
    textTransform: "none",
    marginRight: "20px",
    border: "none",
    '&:hover': {
        border: '1px solid #398378',
        borderColor: '#398378',
    },
});

const saveFiltersToSessionStorage = (filters: FilterValues) => {
    sessionStorage.setItem('appliedFilters', JSON.stringify(filters));
};

const getFiltersFromSessionStorage = (): FilterValues | null => {
    const savedFilters = sessionStorage.getItem('appliedFilters');
    return savedFilters ? JSON.parse(savedFilters) : null;
};

const clearFiltersFromSessionStorage = () => {
    sessionStorage.removeItem('appliedFilters');
};

class FilterScreen extends React.Component<FilterScreenProps, FilterScreenState> {
    constructor(props: FilterScreenProps) {
        super(props);
        const savedFilters = getFiltersFromSessionStorage();
        this.state = savedFilters || {
            priceRange: [0, props.maxPrice],
            selectedServices: [],
            city: '',
        };
    }

    handlePriceRangeChange = (event: Event, value: number | number[], activeThumb: number) => {
        if (Array.isArray(value)) {
            this.setState({ priceRange: value as [number, number] });
        }
    };

    handleServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const service = event.target.name;
        this.setState((prevState) => {
            const { selectedServices } = prevState;
            if (event.target.checked) {
                return { selectedServices: [...selectedServices, service] };
            } else {
                return { selectedServices: selectedServices.filter((s) => s !== service) };
            }
        });
    };

    handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ city: event.target.value });
    };

    handleApply = () => {
        const filters = {
            priceRange: this.state.priceRange,
            selectedServices: this.state.selectedServices,
            city: this.state.city,
        };
        saveFiltersToSessionStorage(filters);
        this.props.onApply(filters);
    };

    handleClear = () => {
        const clearedState: FilterScreenState = {
            priceRange: [0, this.props.maxPrice],
            selectedServices: [],
            city: '',
        };
        this.setState(clearedState);
        clearFiltersFromSessionStorage();
        this.props.onClear();
    };

    render() {
        const { t, dir } = getTranslationConfig();
        const { maxPrice } = this.props;
        const { priceRange, selectedServices, city } = this.state;

        const serviceTypes = [
            t['search-results-filter-01'],
            t['search-results-filter-02'],
            t['search-results-filter-03'],
            t['search-results-filter-04'],
        ];

        const buffer = Math.max(maxPrice * 0.2, 100);
        const sliderMax = maxPrice + buffer;

        return (
            <Box
                dir={dir}
                sx={{ bgcolor: "#FFFFF" }}
            >
                <Typography sx={{ mb: 2 }}>{t['search-results-filter-location']}</Typography>
                <TextField
                    data-test-id='city-filter'
                    fullWidth
                    placeholder={t['search-results-filter-city-placeholder']}
                    variant="outlined"
                    value={city}
                    onChange={this.handleCityChange}
                    sx={{ mb: 4 }}
                />
                <Typography sx={{ mb: 1 }}>{t['search-results-filter-price-range']}</Typography>
                <Typography
                    style={{
                        marginBottom: "20px",
                        fontFamily: 'Ubuntu',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '22px',
                    }}
                >
                    ${priceRange[0]} - ${priceRange[1]}
                </Typography>
                <Slider
                    getAriaLabel={() => t['search-results-filter-price-range']}
                    value={priceRange}
                    min={0}
                    max={sliderMax}
                    valueLabelDisplay="auto"
                    sx={{ mb: 3, color: '#398378', width: 500 }}
                    onChange={this.handlePriceRangeChange}
                />
                <Typography sx={{ mb: 2 }}>{t['search-results-filter-service-type']}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {serviceTypes.map((service) => (
                        <FormControlLabel
                            key={service}
                            control={
                                <Checkbox
                                    sx={{
                                        '& .MuiCheckbox-svg': {
                                            stroke: 'black',
                                        },
                                        '&.Mui-checked': {
                                            color: '#398378',
                                        },
                                    }}
                                    checked={selectedServices.includes(service)}
                                    onChange={this.handleServiceChange}
                                    name={service}
                                />
                            }
                            label={service}
                        />
                    ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginLeft: '20px' }}>
                    <ClearButton
                        data-test-id='clear-btn'
                        variant="outlined"
                        onClick={this.handleClear}
                    >
                        {t['search-results-filter-clear-filters']}
                    </ClearButton>
                    <Button
                        data-test-id='apply-btn'
                        variant="contained"
                        onClick={this.handleApply}
                        style={{
                            width: '170px',
                            height: '45px',
                            padding: '16px',
                            gap: '8px',
                            borderRadius: '8px',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            textAlign: 'left',
                            fontFamily: 'Ubuntu',
                            color: '#398378',
                            backgroundColor: "#E8F6F4",
                            textTransform: "none"
                        }}
                    >
                        {t['search-results-filter-apply']}
                    </Button>
                </Box>
            </Box>
        );
    }
}

export default FilterScreen;
