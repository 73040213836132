export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImg = require("../assets/backimage_.png");
export const backgroundSpaImg = require("../assets/backimage_spaowner.jpg");
export const headerLogoImg = require("../assets/headerLogo.png");
export const clodUploadImg = require("../assets/image_cloud_upload.png");
export const avtarImg = require("../assets/avatar.png");

export const buttonImg = require("../assets/button.png");
export const deleteImg = require("../assets/image_button_icon_button_.svg");
export const arrowDown = require("../assets/arrow-down.png");
export const sandClock = require("../assets/sand-clock.png");
