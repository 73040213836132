import React from "react";
import { Box, Card, Button, CardMedia, Typography, Grid, TextField, InputAdornment, IconButton } from "@mui/material";
import { image_venue, image_star, startBooking } from "./assets";
import LandingPageController, { S } from "./LandingPageController";
import Loader from "../../../components/src/Loader.web";
import SearchIcon from '@material-ui/icons/Search';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterScreen from "./components/FilterScreen";
import { styled } from "@material-ui/core";
import { getTransContent, getTranslationConfig } from '../../../components/src/helpers';
import { AppHeader } from '../../../components/src/AppHeader.web';

interface Props {
  navigation: any;
  id: string;
}
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#398378',
  width: 'auto',
  minWidth: '170px',
  height: '40px',
  boxShadow: 'none',
  borderRadius: '4px',
  padding: '8px 16px',
  fontFamily: 'Ubuntu, sans-serif',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  textTransform: 'none',
  marginBottom: "500px !important",
  color: '#FFFFFF !important',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: '-12px',
    top: '50%',
    transform: 'translateY(-50%)',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderRight: '12px solid #398378',
  },
  '&:disabled': {
    backgroundColor: '#398378 !important',
    color: '#FFFFFF !important',
  },
  '&:hover': {
    backgroundColor: '#2c665d',
    boxShadow: 'none',
  },
}));

export default class SearchResults extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }


  async componentDidMount(): Promise<void> {
    const storedSearchResults = sessionStorage.getItem("searchResults");
    const storedFilters = sessionStorage.getItem("appliedFilters");

    if (storedSearchResults) {
      const searchData = JSON.parse(storedSearchResults);

      const prices = searchData.flatMap((item: any) =>
        item.data.attributes.services?.map((service: any) => service.price) || []
      );

      const maxPrice = Math.max(...prices, 0);

      if (storedFilters) {
        const filterData = JSON.parse(storedFilters);
        this.setState({
          searchData,
          originalSearchData: searchData,
          loading: false,
          maxPrice,
          priceRange: filterData.priceRange,
          selectedServices: filterData.selectedServices,
          city: filterData.city,
          showAllResults: false,
        }, () => {
          this.setState({ filteredData: this.filterData() });
        });
      } else {
        this.setState({
          searchData,
          originalSearchData: searchData,
          filteredData: searchData,
          loading: false,
          maxPrice,
          priceRange: [0, maxPrice],
          showAllResults: true,
        });
      }
    }
  }


  toggleFilterScreen = () => {
    this.setState((prevState) => ({ showFilterScreen: !prevState.showFilterScreen }));
  };


  render() {
    const { t, dir, isArabic } = getTranslationConfig();
    const { searchData, showFilterScreen, searchName, showAllResults } = this.state;

    const spaFilterData = this.state.filteredData;

    return (
      <>
        <AppHeader
          context={this}
          forceUpdate={this.forceUpdate.bind(this)} />
        <Box
          dir={dir}
          style={{
            backgroundColor: "#E8F6F4",
            padding: "50px 100px",
            flexGrow: 1
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t['search-results-input-placeholder']}
              value={searchName}
              onChange={this.handleSpaNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "100%", backgroundColor: "#FFFFFF", borderRadius: 1 }}
            />
            <IconButton
              data-test-id='filter-icon'
              onClick={this.toggleFilterScreen}
              sx={{
                ml: 2,
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                height: 56,
                width: 56,
                boxShadow: 1,
                ...(isArabic && {
                  margin: 0,
                  marginRight: '16px'
                })
              }}
            >
              <Box sx={{ color: '#398378' }}>
                <FilterListIcon />
              </Box>
            </IconButton>
          </Box>
          {showFilterScreen && (
            <Card style={{ marginBottom: "20px", padding: "20px", border: "none" }}>
              <FilterScreen
                onClose={this.toggleFilterScreen}
                onApply={this.handleApplyFilters}
                onClear={this.handleClearFilters}
                maxPrice={this.state.maxPrice}
                currentFilters={{
                  priceRange: this.state.priceRange,
                  selectedServices: this.state.selectedServices,
                  city: this.state.city,
                }}
              />
            </Card>
          )}
          {this.state.loading ? (
            <Loader loading />
          ) : (
            <Grid container>
              {((showAllResults ? searchData : spaFilterData).length === 0) ? (
                <Box style={{
                  minHeight: '300px',
                  position: 'relative',
                  marginLeft: "200px",
                  zIndex: 21,
                }}>
                  <img src={startBooking} alt="startBooking" />
                  <StyledButton
                    data-test-id={"backBtn"}
                    variant="contained"
                    disabled
                  >
                    {t['search-results-not-found-title']}
                  </StyledButton>
                </Box>
              ) : (
                <Grid container>
                  {(showAllResults ? searchData : spaFilterData).map((item, index) => (
                    <Grid item xs={12} key={index}>
                      {item.data && (
                        <Card style={{ padding: "20px", border: "none", marginBottom: "20px" }}>
                          <Box>
                            <Box style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                              <Typography
                                data-test-id='venue-link'
                                style={{
                                  font: 'Ubuntu',
                                  fontWeight: 700,
                                  fontSize: '14px',
                                  lineHeight: '22px',
                                  marginLeft: '5px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => this.goToVenue(item?.data?.id)}
                              >
                                {getTransContent(item.data?.attributes?.account[0]).name}
                              </Typography>
                              <Box
                                dir='ltr'
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "5px",
                                  marginLeft: "20px", marginBottom: "5px",
                                  backgroundColor: "#FFFFFF",
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                                  padding: "3px",
                                  paddingRight: '20px',
                                  marginRight: '32px',
                                  borderRadius: "5px",
                                  width: "7%"
                                }}>
                                <img src={image_star} alt="Star Icon" style={{ width: 20, height: 20, marginBottom: "5px", marginRight: "2px" }} />
                                <Typography variant="body2" style={{ lineHeight: "18px", font: 'Ubuntu', fontWeight: 700, fontSize: '12px', marginLeft: '5px' }}>
                                  {item.data?.attributes?.account[0]?.rating?.toFixed(1)}
                                </Typography>
                                <Typography style={{
                                  borderLeft: '2px solid grey',
                                  height: '16px',
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                  marginBottom: "3px"
                                }}></Typography>
                                <Typography variant="body2" style={{
                                  lineHeight: "18px",
                                  font: 'Ubuntu',
                                  fontWeight: 700,
                                  fontSize: '12px',
                                  marginLeft: '5px',
                                  color: '#64748B',
                                }}>{item.data?.attributes?.account[0]?.rating_votes}</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "-7x" }}>
                              <RoomOutlinedIcon style={{ fontSize: "18px", marginRight: "5px", marginTop: "5px" }} />
                              <Typography style={{
                                font: 'Ubuntu',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: "#757575",
                                marginTop: "10px"
                              }}>
                                {item.data?.attributes?.account[0]?.address}, {item.data?.attributes?.account[0]?.city}
                              </Typography>
                            </Box>
                            <Box style={{
                              marginTop: "10px", color: "#ff6f00", alignSelf: "flex-start",
                              marginBottom: "20px"
                            }}>
                              <Typography
                                variant="body2"
                                style={{ ...(isArabic && { dir }) } as React.CSSProperties}
                              >
                                20% {t['search-results-discount-text']} $49
                              </Typography>
                            </Box>
                          </Box>

                          <Grid container spacing={2} style={{ marginBottom: '10px', marginTop: '-50px' }}>
                            {item.data?.attributes?.services?.map((service, serviceIndex) => (
                              <Grid item xs={12} sm={6} md={4} key={serviceIndex}>
                                <Card
                                  data-test-id='venue-card'
                                  onClick={() => this.goToVenue(item.data?.id)}
                                  style={{
                                    display: "flex", padding: "16px", border: "none",
                                    backgroundColor: "white",
                                    marginTop: "40px",
                                    boxSizing: "border-box",
                                    cursor: 'pointer'
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <CardMedia
                                        component="img"
                                        height="70"
                                        image={service.image.image_url || image_venue}
                                        alt="Service Image"
                                        style={{
                                          borderRadius: '8px',
                                          width: '100%',
                                          height: '70px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={8} container direction="column" justifyContent="space-between">
                                      <Box>
                                        <Typography style={{
                                          font: 'Ubuntu',
                                          fontWeight: 700,
                                          fontSize: '12px',
                                          lineHeight: '18px',
                                          color: '#334155'
                                        }}>
                                          {getTransContent({
                                            service_name: service.service_name,
                                            arabic_service_name: (service as Record<string, any>).arabic_service_name
                                          }).serviceName}
                                        </Typography>
                                        <Box style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                        </Box>
                                        <Typography style={{
                                          font: 'Ubuntu',
                                          fontWeight: 400,
                                          fontSize: '12px',
                                          lineHeight: '18px',
                                          color: '#398378',
                                          marginTop: "10px",
                                        }}>
                                          {t['search-results-starting-price-text']} ${service?.price}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </>
    );
  }
}