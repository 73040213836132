import { Box, Button, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { styles } from './styles'
import { languageSwitchIcon } from './assets'
import { ArrowDropDown } from '@material-ui/icons'
import { CustomPopover } from '../../blocks/user-profile-basic/src/SpaUserProfile.web'
import { LanguageNames } from './consts'
import { getLocalTranslations, Languages } from './helpers'
import { getFormDataFromPayload } from '../../blocks/utilities/src/handle-form'
import { useRunEngineCustom } from '../../blocks/utilities/src/hooks/useRunEngineCustom'
import { useBlockHelpers } from '../../blocks/utilities/src/hooks/useBlockHelpers'
import { Message } from '../../framework/src/Message'
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum'

export const LanguageSwitchDropdown = ({ forceUpdate }: { forceUpdate?: () => void }) => {
    const {
        sendNetworkRequest,
        setReceiveCallback,
    } = useRunEngineCustom();

    const authToken = localStorage.getItem('authToken');
    const accountId = localStorage.getItem('user_id');

    const { extractNetworkResponse } = useBlockHelpers();

    const setLangCallId = useRef('');

    const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null);
    const [lang, setLang] = useState<Languages | null>(null);

    const updateLanguage = (lang: string) => {
        const formData = getFormDataFromPayload(
            {
                'account[profile][account_id]': accountId,
                'account[profile][language]': lang
            }
        )

        sendNetworkRequest(
            setLangCallId,
            'POST',
            'account_block/update_profile',
            { token: authToken },
            formData
        );
    }

    const updateTranslations = (language: string) => {
        (window as unknown as { t: Record<string, string> }).t = getLocalTranslations(language);
        localStorage.setItem('language', language);
        forceUpdate?.();
    }

    const receive = (from: string, message: Message, lang?: string | null) => {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, responseJson } =
                extractNetworkResponse(message);
            if (responseJson) {
                setLangCallId.current === apiRequestCallId && updateTranslations(String(lang));
            } else {
                alert('There is an error, please try later or contact technical support')
            }
        }
    };

    useEffect(() => {
        if (lang) {
            setReceiveCallback((from: string, message: Message) => receive(from, message, lang));
            authToken ? updateLanguage(lang) : updateTranslations(lang);
        }
    }, [lang])

    return <>
        <Box
            display='flex'
            flexWrap='no-wrap'
            ml={4}
            alignItems='center'
            style={{
                cursor: 'pointer'
            }}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                setPopoverAnchor(event?.currentTarget)
            }}
        >
            <Box width='50%' {...styles.centered}>
                <img src={languageSwitchIcon} alt='Language switch' />
            </Box>
            <Box width='50%' {...styles.centered} >
                <ArrowDropDown style={{ color: '#FFFFFF' }} />
            </Box>
        </Box>
        <CustomPopover
            data-test-id="edit-popover"
            open={Boolean(popoverAnchor)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            anchorPosition={{
                top: 0,
                left: 0
            }}
            anchorEl={popoverAnchor}
            onClick={() => setPopoverAnchor(null)}
            PaperProps={{
                style: {
                    width: "192px",
                    boxShadow: "none",
                    borderRadius: "8px",
                    borderColor: "#CBD5E1",
                },
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Button
                    data-test-id='update-btn'
                    className="popoverButton"
                    style={{
                        padding: "9px 20px 9px 20px",
                        textTransform: "none",
                        fontWeight: 400,
                        height: "36px",
                    }}
                    onClick={() => setLang(Languages.en)}
                >
                    <Typography style={{ marginLeft: 8 }}>{LanguageNames.EN}</Typography>
                </Button>
                <Button
                    data-test-id='delete-btn'
                    className="popoverButton"
                    style={{
                        padding: "9px 20px",
                        height: "36px",
                        textTransform: "none",
                        fontWeight: 400,
                    }}
                    onClick={() => setLang(Languages.ar)}
                >
                    <Typography
                        style={{
                            marginLeft: 8,
                            fontFamily: "Ubuntu",
                        }}
                    >
                        {LanguageNames.AR}
                    </Typography>
                </Button>
            </div>
        </CustomPopover>
    </>
}
