import React from "react";
// Customizable Area Start
import { profileCover, profilePic } from "./assets"
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { Box, IconButton, styled, Typography } from "@material-ui/core";
import { BookingWrapper } from "../../ordermanagement1/src/components/BookingWrapper";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NotificationModal from "./components/NotificationModal";
import { TypographyView } from '../../../components/src/styles';

const StyledWrapper = styled(Box)({
    'p': {
        fontFamily: 'Ubuntu'
    },
    'label': {
        fontFamily: 'Ubuntu'
    },
    '& .tab-button': {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        background: 'none',
        border: 'none',
        padding: '10px 12px',
        marginBottom: '12px',
        cursor: 'pointer',
        position: 'relative',
        transition: 'color 0.3s ease',
        color: '#64748B',
        borderRadius: '8px',
        minWidth: '70px',
        display: 'inline-block',
    },
    '& .tab-button::after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        bottom: 0,
        width: 0,
        height: '3px',
        backgroundColor: '#398378',
        transition: 'width 0.3s ease, left 0.3s ease',
    },
    '& .tab-button.active': {
        color: '#398378',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '28px',
    },
    '& .tab-button.active::after': {
        width: 'calc(100% - 32px)',
        left: '16px',
    },
    '& .notification-item': {
        display: 'flex',
        alignItems: 'cente',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E0E0E0',
        padding: '16px',
        backgroundColor: '#F8FFFE',
        marginBottom: '10px',
        borderLeft: '4px solid #397378',
        borderRadius: '8px 0 0 8px',
    }
})
// Customizable Area End


class NotificationsProfile extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        window.scrollTo(0, 0);
        // Customizable Area End
    }

    // Customizable Area Start
    handleButtonClick = (buttonName: string) => {
        this.setState({ activeButton: buttonName });
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { notifications } = this.state
        const profileName = localStorage.getItem('userRole') === 'Customer' ? 'My Profile' : 'Spa Profile';

        const customStyles = {
            background: `linear-gradient(
                rgba(0, 0, 0, 0.2), 
                rgba(0, 0, 0, 0.5)
              ), url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
        };

        return (
            <TypographyView >
                <StyledWrapper>
                    <Box bgcolor="#D9EEEB">
                        <BookingWrapper
                            navigation={this.props.navigation}
                            backgroundImage={profileCover}
                            heading="Notifications"
                            breadcrumbs={["Home", profileName, "Notifications"]}
                            customStyles={customStyles}
                            context={this}
                        >
                            <Box
                                marginTop={1}
                                data-testid="toggle-button-box"
                            >
                                <Box
                                    display="flex"
                                    mt={2}
                                >
                                    <button
                                        className={this.getTernaryValue(this.state.activeButton === 'all', 'tab-button active', 'tab-button')}
                                        onClick={() => this.handleButtonClick('all')}
                                        data-testid="all-button"
                                    >
                                        All
                                    </button>
                                    <button
                                        className={this.getTernaryValue(this.state.activeButton === 'unread', 'tab-button active', 'tab-button')}
                                        onClick={() => this.handleButtonClick('unread')}
                                        data-testid="unread-button"
                                    >
                                        Unread
                                    </button>
                                </Box>

                                <Box
                                    mt={2}
                                    height='450px'
                                    overflow='auto'
                                >
                                    <Box style={{ paddingRight: '20px' }}>
                                        {this.state.activeButton === 'all'
                                            ? notifications.map((notification) => (
                                                <Box
                                                    key={notification.id}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    borderBottom="1px solid #E0E0E0"
                                                    py={2}
                                                    bgcolor="#F8FFFE"
                                                    style={{
                                                        marginBottom: "10px",
                                                        borderLeft: this.getTernaryValue(notification.attributes.is_read === true, "4px solid #94A3B8", "4px solid #397378"),
                                                        borderRadius: "8px 0 0 8px",
                                                    }}
                                                    onClick={() => this.handleNotificationClick(notification)}
                                                    data-testid="notification-all-item"
                                                >
                                                    <Box display="flex" alignItems="center" style={{ marginLeft: "10px" }}>
                                                        <img
                                                            src={notification.attributes.service_provider?.profile_photo || profilePic}
                                                            style={{
                                                                width: "48px",
                                                                height: "48px",
                                                                objectFit: 'cover',
                                                                borderRadius: "50%",
                                                                marginRight: "16px",
                                                                opacity: this.getTernaryValue(notification.attributes.is_read === true, 0.5, 1)
                                                            }}
                                                        />
                                                        <Box>

                                                            <Typography style={{
                                                                fontWeight: 700,
                                                                color: this.getTernaryValue(notification.attributes.is_read === true, "#94A3B8", "#0F172A")
                                                            }}>
                                                                {notification.attributes.headings}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: this.getTernaryValue(notification.attributes.is_read === true, "#94A3B8", "#000000"),
                                                                    fontSize: "14px"
                                                                }}>
                                                                {notification.attributes.contents}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <IconButton
                                                        data-testid="deleteNotification"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            this.deleteNotification(notification.id);
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon color="secondary" />
                                                    </IconButton>
                                                </Box>
                                            ))
                                            : notifications.filter((notification) => !notification.attributes.is_read).map((notification) => (
                                                <Box
                                                    data-test-id='unread-notification'
                                                    key={notification.id}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    borderBottom="1px solid #E0E0E0"
                                                    py={2}
                                                    bgcolor="#F8FFFE"
                                                    style={{
                                                        marginBottom: "10px",
                                                        borderLeft: "4px solid #397378",
                                                        borderRadius: "8px 0 0 8px",
                                                    }}
                                                    onClick={() => this.handleNotificationClick(notification)}
                                                >
                                                    <Box display="flex" alignItems="center" style={{ marginLeft: "10px" }}>
                                                        <img
                                                            src={notification.attributes.service_provider?.profile_photo || profilePic}
                                                            style={{
                                                                width: "48px",
                                                                height: "48px",
                                                                objectFit: 'cover',
                                                                borderRadius: "50%",
                                                                marginRight: "16px",
                                                            }}
                                                        />
                                                        <Box>
                                                            <Typography style={{ fontWeight: 700 }}>
                                                                {notification.attributes.headings}
                                                            </Typography>
                                                            <Typography style={{ color: "#6A6A6A", fontSize: "14px" }}>
                                                                {notification.attributes.contents}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <IconButton
                                                        data-testid="deleteNotificationUnread"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            this.deleteNotification(notification.id);
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon color="secondary" />
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        <NotificationModal
                                            openNotificationModal={this.state.openNotificationModal}
                                            setOpenNotificationModal={this.setOpenNotificationModal}
                                            navigation={this.props.navigation}
                                            notification={this.state.selectedNotification}
                                            id="1"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </BookingWrapper>
                    </Box>
                </StyledWrapper>
            </TypographyView>
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
export default NotificationsProfile;
// Customizable Area End


