export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgMap = require("../assets/map.jpg");
export const imgPendingClockGrey = require('../assets/pending-clock.png');
export const imgPendingClock = require('../assets/pending-clock.svg');
export const imgDollarSign = require('../assets/dollar-sign.png');
export const imgRemove = require('../assets/remove.png');
export const imgPlaceholder = require('../assets/image-placeholder.png');
export const imgUploadGreen = require('../assets/upload.png');
export const imgSpaEmpty = require('../assets/spa-images-empty.png');
export const imgConfirm = require('../assets/confirm-screen.png');
export const imgCalendar = require('../assets/calendar.png');
export const timeRangeArrow = require('../assets/timeRangeArrow.png');
export const arrowDown = require('../assets/arrow-down.png');
export const clockIcon = require('../assets/clockIcon.png');
export const addTimingIcon = require('../assets/AddTimingIcon.png');
export const calendarIcon = require('../assets/calenderIcon.png');
export const timerClock = require('../assets/timer-clock.png');
