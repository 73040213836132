import React from "react";

// Customizable Area Start

import { Box, InputLabel, TextField, ThemeProvider, Typography, createTheme, styled, Button, Snackbar } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { arrowDown, avtarImg, buttonImg, clodUploadImg, deleteImg } from "./assets";
export const configJSON = require("./config");
import { getTranslationConfig } from '../../../components/src/helpers';


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const CustomTypography = styled(Typography)({
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .addLocation': {
    color: '#fff',
    fontFamily: 'Ubuntu',
    fontSize: '14px',
  },
  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .readAndAgree': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '12px'
  },
  '& .businessLicense': {
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#334155',
    alignSelf: 'center',
    textAlign: 'center'
  },
  '& .businessLicenseDesc': {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Ubuntu',
    color: '#334155',
    alignSelf: 'center'
  },
  '& .browseFile': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#F59E0B',
    marginLeft: '5px'
  },
  '& .addOther': {
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    color: '#398378',
    paddingLeft: '5px'
  },
  '& .licenseTxt': {
    fontSize: '12px',
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    color: '#0F172A',
  },
  '& .fileSize': {
    fontSize: '12px',
    fontFamily: 'Ubuntu',
    fontWeight: '400',
    color: '#64748B'
  }

})
const ButtonComponent = styled(Button)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center !important",
  justifyContent: 'space-between',
  padding: "30px 0px",
  '& .submitButton': {
    display: 'flex',
    width: '120px',
    height: '56px',
    padding: '16px 36.5px',
    borderRadius: '8px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none'
  },
  '& .backButton': {
    display: 'flex',
    width: '120px',
    height: '56px',
    padding: '16px 36.5px',
    borderRadius: '8px',
    background: 'var(--Primary, #D9EEEB)',
    color: '#398378',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    marginRight: '16px'
  }
})
const styles = {
  paperContainer: {
    width: '100%',
    backgroundColor: '#E8F6F4',
    "@media only screen and (min-width: 1280px)": {
      minWidth: '100%'
    }
  },
  iconStyle: {
    fontSize: '18px',
    color: '#34D399'
  },
  crossIcon: {
    fontSize: '18px',
    color: '#94A3B8',
    cursor: 'pointer'
  }
};
const RegistrationFormView = styled(Box)({
  borderRadius: '24px 24px 0px 0px',
  background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  '& .formHeader': {
    'display': 'flex',
    padding: '27px 172px 26px 172px',
    borderRadius: '24px 24px 0px 0px',
    background: 'var(--Primary, #398378)'
  },
  '& .logo': {
    width: '176px',
    height: '72px'
  },
  '& .MuiContainer-maxWidthLg': {
    width: '100%',
    "@media only screen and (min-width: 1280px)": {
      minWidth: '100%'
    }
  },
  '& .inputeStyle': {
    borderRadius: '8px',
    border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #398378 !important'
  },
  '& .button': {
    background: '#398378',
    padding: '16px',
    textTransform: 'none',
    width: '525px',
    color: '#fffff'
  },
  '& .spaOwnerBtn': {
    background: '#E8F6F4',
    textTransform: 'none',
    width: '525px',
    color: '#398378',
    borderLeft: '4px solid #398378 !important',
    borderRadius: '8px',
    marginTop: '10px'
  },
  '& .button .MuiButton-label': {
    color: 'white !important'
  },
  '& .spaOwnerBtn .MuiButton-label': {
    color: '398378 !important'
  },
  '& .txtRemember': {
    color: '#334155'
  },
  '& .MuiBox-root-19': {
    color: '#1B5950 !important'
  },
  '& .MuiBox-root-14': {
    color: '#334155 !important'
  },
  '& .PrivateSwitchBase-root-18': {
    paddingLeft: '0px'
  },
  '& .forgotPwdText': {
    fontSize: "14px",
    color: "#1B5950",
    fontWeight: "400",
    marginTop: "5px",
  },
  '& .mainView': {
    padding: '40px',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
    borderBottom: '1px solid #E2E8F0'
  },
  '& .testLabel': {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: '700',
    fontSize: '16px',
    color: '#334155',
    fontFamily: 'Ubuntu'
  },
  '& .testStar': {
    color: 'red',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '5px'
  },

  '& .remember': {
    fontSize: "14px",
    color: "#334155",
    fontWeight: "400",
    marginTop: "5px",
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  '& .accountText': {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: 'Ubuntu'

  },
  '& .signUp': {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 'bold',
    fontFamily: 'Ubuntu',
    paddingLeft: '5px'

  },
  '& .moreIcon': {
    color: '#fff',
    fontSize: '16px',
  },
  '& .AddIcon': {
    color: '#F59E0B',
    fontSize: '16px',
  },
  '& .avtarImg': {
    width: '100px',
    height: '100px'
  },
  '& .pencileIcon': {
    width: '32px',
    height: '32px',
    position: 'absolute',
    marginLeft: '75px'
  },
  '& .AddIconStyle': {
    color: '#398378',
    fontSize: '16px',
  },
  '& .spaOwner': {
    width: ' 520px',
    textAlign: 'center',
    textTransform: 'none',
    padding: '16px',
  },
  '& .arrow': {
    color: '#398378',
    fontSize: '16px',
    paddingRight: '20px'
  },
  '& .MuiSnackbarContent-root': {
    background: '#FEE2E2',
    color: '#DC2626',
    borderRadius: '4px',
    width: '100%',
  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    marginTop: '20px'
  },
  '& .MuiPaper-root': {
    left: '860px'
  },
  '& .PrivateSwitchBase-root-25': {
    paddingLeft: '0px'
  },
  '& .checkedIcon': {
    fontSize: '12px',
    color: '#34D399'
  },
  '& .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded': {
    top: '140px !important',
    left: '870px !important'
  },
  '& .businessLicenseView': {
    border: '2px',
    borderColor: '#CBD5E1',
    borderStyle: 'dashed',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    padding: '6px 20px',
    flexDirection: 'column', display: 'flex',
    marginTop: '8px'

  },
  '& .uploadImg': {
    width: '36px',
    alignSelf: 'center'
  },
  '& .webLink': { display: 'flex', paddingTop: '15px', flexDirection: 'row', },
  '& .locationView': {
    display: 'flex', flexDirection: 'row',
    alignItems: 'center', justifyContent: 'center',
    borderRadius: '8px', backgroundColor: '#E8F6F4', alignSelf: 'flex-end',
    padding: '5px',
    marginTop: '15px',
    cursor: 'pointer'
  },
  '& .locationView[disabled]': {
    backgroundColor: '#d7ded6'
  },
  '& .locationView[disabled] p': {
    color: '#FFFFFF'
  },
  '& .locationView[disabled] svg': {
    color: '#FFFFFF'
  },
  '& .locationViewStyle': { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' },
  '& .fileView': { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  '& .documentView': {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    justifyContent: 'space-between', backgroundColor: 'var(--Primary, #398378)', padding: '16px'
  },
  '& .uploadedDocument': {
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '6px',
    border: '1px solid #CBD5E1',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    padding: '10px',
    width: '427px'
  },
  '& .removeItem': {
    display: 'flex',
    backgroundColor: '#E8F6F4',
    width: '35px', height: '35px',
    borderRadius: '6px', border: '1px solid #E8F6F4',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .MuiAutocomplete-option:hover': {
    backgroundColor: '#D9EEEB'
  },
  '& .MuiAutocomplete-endAdornment': {
    right: '8px'
  },
  '& .MuiAutocomplete-popper': {
    backgroundColor: '#FFFFFF'
  },
  '& .MuiAutocomplete-option[data-focus="true"]': {
    color: '#398378',
    fontWeight: '700',
    background: '#E8F6F4'
  },
  '& *::-webkit-scrollbar': {
    width: '5px'
  },
  '& *::-webkit-scrollbar-track': {
    backgroundColor: '#D9EEEB',
  },
  '& *::-webkit-scrollbar-thumb': {
    backgroundColor: '#398378',
    outline: '1px solid slategrey'
  }
});

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { AppHeader } from "../../../../packages/components/src/AppHeader.web";
import { Autocomplete } from '@material-ui/lab';
import { createUploaders, validateFileInput } from '../../../../packages/blocks/utilities/src/handle-files';


export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    window.addEventListener('popstate', this.goBack.bind(this));
    // Customizable Area End
  }

  uploadFileItem(stateSetter: (file: File[]) => void, titleText: string, uploadedFiles: File[]) {
    const { t } = getTranslationConfig();
    return uploadedFiles?.length ?
      <>
        {uploadedFiles.map((file, index) => <Box key={file.name} className="uploadedDocument">
          <Box className="removeItem">
            <img src={deleteImg} alt={""} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />
          </Box>
          <CustomTypography style={{ width: '100%', paddingLeft: '0.5rem' }}>
            <Typography className="licenseTxt">{titleText}</Typography>
            <Typography className="fileSize">{`${file.size / 1000}KB`}</Typography>
          </CustomTypography>
          <Box>
            <ClearIcon
              data-test-id='clear-icon'
              style={styles.crossIcon}
              onClick={() => {
                uploadedFiles.splice(index, 1);
                stateSetter(uploadedFiles);
              }} />
          </Box>
        </Box>)}
      </> :
      <Box
        className="businessLicenseView"
        onDrop={(event: React.DragEvent) => {
          event.preventDefault();
          validateFileInput.call(this, event.dataTransfer.files, stateSetter, titleText);
        }}
        onDragOver={(event: React.DragEvent) => event.preventDefault()}
      >
        <img src={clodUploadImg} className="uploadImg" />
        <CustomTypography style={{ marginTop: '8px' }}>
          <Typography className="businessLicense">{titleText}</Typography>
          <Typography className="businessLicenseDesc">{t['spa-owner-registration-file-upload-drag-drop-text']}</Typography>
        </CustomTypography>

        <Button
          component='label'
          style={{
            textTransform: 'none',
            margin: 'auto'
          }}>
          <input
            data-test-id='file-input'
            multiple
            accept=".pdf, .jpg, .jpeg, .png"
            type="file"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => stateSetter(Array.from(event.target.files || []))}
            style={{
              visibility: 'hidden',
              position: 'absolute'
            }}
          />
          <AddCircleOutlineIcon className="AddIcon" />
          <CustomTypography>
            <Typography className="browseFile">{t['spa-owner-registration-file-upload-browse-btn']}</Typography>
          </CustomTypography>
        </Button>
      </Box>
  }

  render() {
    const { t, dir, isArabic } = getTranslationConfig();

    return (
      <ThemeProvider theme={theme}>
        <div style={styles.paperContainer}>
          <AppHeader
            context={this}
            showLogin={false}
            forceUpdate={this.forceUpdate.bind(this)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: 'center',
              padding: "50px 0px 50px 0px",
            }}
            dir={dir}
          >
            <RegistrationFormView>
              <Box className="mainView">
                <CustomTypography>
                  <Typography className="title">{t['spa-owner-registration-title']}</Typography>
                </CustomTypography>
                <Box pt={2} position={'inherit'} sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'flex-end',
                  position: 'relative',
                  width: 'fit-content'
                }}>
                  <Box borderRadius='50%' overflow='hidden' width={100} height={100}>
                    <input
                      type="image"
                      src={this.state.upldAvatarLink || avtarImg}
                      className="avtarImg"
                      style={{ width: 'auto' }}
                    />
                  </Box>
                  <Button
                    component='label'
                    style={{
                      padding: 0,
                      position: 'absolute'
                    }}
                  >

                    <input
                      accept="image/*"
                      data-test-id='avatar-file-input'
                      type="file"
                      onChange={(event) => this.handleAvatarUpload(event)}
                      className="avtarImg"
                      style={{
                        visibility: 'hidden'
                      }}
                    />
                    <img src={buttonImg} className="pencileIcon" style={{
                      cursor: 'pointer',
                      bottom: 0
                    }} />
                  </Button>
                </Box>
                <Box >
                  <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                    <InputLabel className="testLabel">{t['spa-owner-registration-spa-name-label']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <TextField
                    fullWidth={true}
                    value={this.state.spaName}
                    onChange={this.setSpaName}
                    variant="outlined"
                    className="inputeStyle"
                    data-test-id="txtInputSpaName"
                    placeholder={t['spa-owner-registration-spa-name-placeholder']}
                    error={this.state.spaNameError}
                  />
                  {this.state.spaNameError &&
                    <CustomTypography>
                      <Typography className="txtErrorMsg">
                        {t['spa-owner-registration-spa-name-error-message']}
                      </Typography>
                    </CustomTypography>}
                </Box>
                <Box >
                  <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                    <InputLabel className="testLabel">{t['spa-owner-registration-spa-summary-label']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <TextField
                    fullWidth={true}
                    value={this.state.spaSummary}
                    onChange={this.setSpaSummary}
                    variant="outlined"
                    className="inputeStyle"
                    data-test-id="txtspaSummary"
                    placeholder={t['spa-owner-registration-spa-summary-btn']}
                    error={this.state.spaSummaryError}
                    multiline={true}
                    size='medium'
                    inputProps={{
                      style: {
                        height: "40px",
                      },
                    }}
                  />
                  {this.state.spaSummaryError &&
                    <CustomTypography>
                      <Typography className="txtErrorMsg">
                        {t['spa-owner-registration-spa-summary-error-message']}
                      </Typography>
                    </CustomTypography>}

                </Box>
                <Box>
                  <Box
                    mt={2}
                    className="documentView">
                    <CustomTypography>
                      <Typography className="addLocation">{t['spa-owner-registration-add-location-btn']}</Typography>
                    </CustomTypography>
                    <MoreVertIcon className="moreIcon" />
                  </Box>
                  <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                    <InputLabel className="testLabel">{t['spa-owner-registration-country-label']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <Autocomplete
                    data-test-id="country-autocomplete"
                    fullWidth
                    options={this.state.countries.map((country) => country.name)}
                    className='inputeStyle'
                    autoComplete
                    autoHighlight
                    disableClearable
                    disablePortal
                    style={{ ...(this.state.countryError && { borderColor: '#DC2626' }) }}
                    onChange={(event) => this.onSelectCountry(event)}
                    popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t['spa-owner-registration-country-placeholder']}
                        error={this.state.countryError}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            padding: '12px 16px',
                          }
                        }}
                      />
                    )}
                  />
                  {this.state.countryError && <CustomTypography>
                    <Typography className="txtErrorMsg">{t['spa-owner-registration-country-error-message']}</Typography>
                  </CustomTypography>}
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                    <InputLabel className="testLabel">{t['spa-owner-registration-state-label']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <Autocomplete
                    data-test-id="state-autocomplete"
                    fullWidth
                    options={this.getStateOptions()}
                    className='inputeStyle'
                    autoComplete
                    autoHighlight
                    disableClearable
                    disablePortal
                    value={this.state.stateValue}
                    style={{ ...(this.state.stateError && { borderColor: '#DC2626' }) }}
                    onChange={(event) => this.onSelectState(event)}
                    popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t['spa-owner-registration-state-placeholder']}
                        error={this.state.stateError}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            padding: '12px 16px',
                          }
                        }}
                      />
                    )}
                  />
                  {this.state.stateError && <CustomTypography>
                    <Typography className="txtErrorMsg">{t['spa-owner-registration-state-error-message']}</Typography>
                  </CustomTypography>}
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                    <InputLabel className="testLabel">{t['spa-owner-registration-city-label']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <Autocomplete
                    data-test-id="city-autocomplete"
                    fullWidth
                    options={this.getCityOptions()}
                    value={this.state.city}
                    className='inputeStyle'
                    autoComplete
                    autoHighlight
                    disableClearable
                    disablePortal
                    style={{ ...(this.state.cityError && { borderColor: '#DC2626' }) }}
                    onChange={(event) => this.onSelectCity(event)}
                    popupIcon={<img width={25} height={25} src={arrowDown} alt="" />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t['spa-owner-registration-city-placeholder']}
                        error={this.state.cityError}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            padding: '12px 16px',
                          }
                        }}
                      />
                    )}
                  />
                  {this.state.cityError && <CustomTypography>
                    <Typography className="txtErrorMsg">{t['spa-owner-registration-city-error-message']}</Typography>
                  </CustomTypography>}
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                    <InputLabel className="testLabel">{t['spa-owner-registration-address-label']}{" "}</InputLabel>
                    <InputLabel className="testStar">{this.star}</InputLabel>
                  </Box>
                  <TextField
                    fullWidth={true}
                    value={this.state.address}
                    onChange={(event) => this.setAddress(event)}
                    variant="outlined"
                    className="inputeStyle"
                    data-test-id="txtInputEmail"
                    placeholder={t['spa-owner-registration-address-placeholder']}
                    error={this.state.addressError}
                  />
                  {this.state.addressError &&
                    <CustomTypography>
                      <Typography className="txtErrorMsg">
                        {t['spa-owner-registration-address-error-message']}
                      </Typography>
                    </CustomTypography>}
                </Box>
                <Box>
                  <Box
                    mt={2}
                    className="documentView">
                    <CustomTypography>
                      <Typography className="addLocation">{t['spa-owner-registration-add-documents-btn']}</Typography>
                    </CustomTypography>
                    <MoreVertIcon className="moreIcon" />
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                      <InputLabel className="testLabel">{t['spa-owner-registration-upload-documents-label']}{" "}</InputLabel>
                      <InputLabel className="testStar">{this.star}</InputLabel>
                    </Box>

                    <Box>
                      {createUploaders.call(this,
                        (number: number, resultFileError: string, resultSetter: any) =>
                          <Box key={number}>
                            <Snackbar
                              data-test-id='error-snackbar'
                              open={Boolean(resultFileError)}
                              message={resultFileError}
                              key={'top' + 'right'}
                              style={{
                                background: '#fee2e2',
                                borderLeft: this.getLanguageBasedStyle(isArabic, 'none', '4px solid #ff0000'),
                                borderRight: this.getLanguageBasedStyle(isArabic, '4px solid #ff0000', 'none'),
                                borderRadius: '8px'
                              }}
                            />
                            {this.uploadFileItem(resultSetter.setter, resultSetter.title, resultSetter.uploadedFile)}
                          </Box>)}
                    </Box>

                    <Box className="locationViewStyle">
                      <Button
                        data-test-id='add-image-button'
                        className="locationView"
                        disabled={this.state.addDisabled}
                        style={{ textTransform: 'none' }}
                        onClick={() => this.onAddButtonClick()}
                      >
                        <AddCircleOutlineIcon className="AddIconStyle" />
                        <CustomTypography>
                          <Typography className="addOther">{t['spa-owner-registration-add-other-documents-btn']}</Typography>
                        </CustomTypography>
                      </Button>
                    </Box>
                    <Box >
                      <Box className="webLink">
                        <InputLabel className="testLabel">{t['spa-owner-registration-website-link-label']}{" "}</InputLabel>
                      </Box>
                      <TextField
                        data-test-id='web-link'
                        fullWidth={true}
                        value={this.state.webLink}
                        onChange={(event) => this.setWebLink(event)}
                        variant="outlined"
                        className="inputeStyle"
                        placeholder={t['spa-owner-registration-website-link-placeholder']}
                      />
                    </Box>

                  </Box>
                </Box>

              </Box>
              <Box className="locationViewStyle" width={'94%'}>
                <ButtonComponent>
                  <Button
                    data-test-id={"backBtn"}
                    variant="contained"
                    color="primary"
                    onClick={() => this.goBack()}
                    className="backButton"
                  >
                    {t['spa-owner-registration-back-btn']} {/*UI Engine::From Sketch*/}
                  </Button>
                  <Button
                    data-test-id={"submitBtn"}
                    variant="contained"
                    color="primary"
                    onClick={() => this.submit()}
                    className="submitButton"
                  >
                    {t['spa-owner-registration-form-submit-btn']} {/*UI Engine::From Sketch*/}
                  </Button>
                </ButtonComponent>
              </Box>
            </RegistrationFormView>
          </Box>
        </div>
      </ThemeProvider>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

