import React from "react";

import {
  Box,
  Button,
  InputLabel,
  Typography,
  Modal,
  TextField,
  Checkbox,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import InputMask from 'react-input-mask';

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

import SubscriptionbillingController, {
  Props,
  configJSON,
} from "./SubscriptionbillingController";
import { imgBankCard, imgCheckbox, imgCheckboxChecked, imgDiscoverLogo, imgMasterLogo, imgVisaLogo } from './assets';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik } from 'formik';
import * as Yup from "yup";

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { title, buttonText } = this.getModalWindowDetails();
    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader context={this} />
          <Box className='search-location-block' py={6} px='140px'>
            <ArrowBackIcon
              onClick={this.props.navigation.goBack}
              style={{
                color: '#0F172A',
                cursor: 'pointer'
              }}
            />
            {/* Description */}
            <Box>
              <Typography
                style={{
                  color: '#0F172A',
                  fontFamily: 'Ubuntu',
                  fontSize: '48px',
                  fontWeight: 700,
                  padding: '30px 0'
                }}
              >Payment Details</Typography>
              <Typography>
                Complete your subscription purchase by providing payment details
              </Typography>
            </Box>
            {/* Payment */}
            <Box>
              <Typography
                style={{
                  color: '#334155',
                  fontFamily: 'Ubuntu',
                  fontSize: '24px',
                  fontWeight: 700,
                  padding: '32px 0 48px'
                }}
              >Payment Method</Typography>
              <Box display='flex' gridGap={32} height={140}>
                <Box py={4} style={{ ...(styles.paymentItem as React.CSSProperties), width: 'auto', display: 'block' }} >
                  <Box
                    width={56}
                    height={56}
                    borderRadius={8}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    style={{ backgroundColor: '#E8F6F4' }}>
                    <img src={imgBankCard} alt='bank-card' />
                  </Box>
                  <Box display='flex' pt={2} gridGap={16}>
                    <Typography
                      style={{
                        color: '#334155',
                        fontFamily: 'Ubuntu',
                        fontSize: '18px',
                        fontWeight: 700,
                      }}
                    >Credit/Debit Card</Typography>
                  </Box>
                </Box>
                <Box style={styles.paymentItem as React.CSSProperties}>
                  <img src={imgVisaLogo} alt='payment logo' style={styles.paymentImage as React.CSSProperties} />
                </Box>
                <Box style={styles.paymentItem as React.CSSProperties}>
                  <img src={imgMasterLogo} alt='payment logo' style={styles.paymentImage as React.CSSProperties} />
                </Box>
                <Box style={styles.paymentItem as React.CSSProperties}>
                  <img src={imgDiscoverLogo} alt='payment logo' style={styles.paymentImage as React.CSSProperties} />
                </Box>
              </Box>
              <Button
                data-test-id='booking-form-btn'
                variant="contained"
                color="primary"
                onClick={() => this.setPaymentModal(true)}
                style={{
                  padding: '16px',
                  borderRadius: '16px',
                  margin: '48px 0',
                  background: '#398378',
                  color: '#FFFFFF'
                }}
              >
                <AddCircleOutlineIcon
                  className="AddIconStyle" style={{
                    color: '#FFFFFF',
                    maxHeight: '22px'
                  }} />
                <Typography style={{
                  marginLeft: '4px',
                  fontWeight: 700
                }}>
                  Add Card
                </Typography>
              </Button>
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
        <Modal
          data-test-id='review-modal'
          open={this.state.showPaymentModal}
          onClose={() => this.setPaymentModal(false)}
        >
          <TypographyView>
            <Formik
              initialValues={this.state.paymentFormData}
              validationSchema={Yup.object().shape({
                card: Yup.string().required(configJSON.emptyCard),
                name: Yup.string().required(configJSON.emptyName),
                cvv: Yup.number()
                  .required(configJSON.emptyCVV)
                  .typeError(configJSON.typeErrorCVV)
                  .test('len', configJSON.numberErrorCVV, value => String(value).length === 3),
                expiry: Yup.string()
                  .required(configJSON.emptyExpiry)
                  .test('date', configJSON.invalidExpiry, value => {
                    if (value) {
                      const [month, year] = value.split('/');
                      const shortYear = new Date().getFullYear().toString().slice(2);

                      const validMonth = month > 0 && month < 13;
                      const validYear = +year >= +shortYear && +year < +shortYear + 6
                      return validMonth && validYear
                    }
                    return true
                  })
              })}
              onSubmit={(values = this.state.paymentFormData) => {
                const { card, expiry, cvv } = values;

                const [expMonth, expYear] = expiry.split('/');

                this.retrieveStripeToken({
                  card,
                  expMonth,
                  expYear,
                  cvv
                });
              }}
            >
              {({
                handleChange,
                submitForm,
                errors,
                touched,
                handleBlur,
                values
              }) => {
                const {
                  name,
                  card,
                  cvv,
                  expiry
                } = errors;
                const isCardError = this.getInputError(touched.name, name);
                const isNameError = this.getInputError(touched.card, card);
                const isCVVError = this.getInputError(touched.cvv, cvv);
                const isExpiryError = this.getInputError(touched.expiry, expiry);
                return (
                  <Box
                    data-test-id='review-form'
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '55%',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px 8px 32px 8px',
                      outline: 'none'
                    }}
                  >
                    <Box p={3} pl={5} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' alignItems='center'>
                      <Typography
                        style={{
                          fontSize: '24px',
                          fontWeight: 'bold'
                        }}
                      >
                        {title}
                      </Typography>
                      <CloseIcon
                        data-test-id='review-close-btn'
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setPaymentModal(false)}
                      />
                    </Box>
                    <Box px={5} py={2} borderBottom='1px solid #E2E8F0' display='flex' justifyContent='space-between' flexWrap='wrap'>

                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">Card Number</InputLabel>
                          <InputLabel className="test-star">*</InputLabel>
                        </Box>
                        <InputMask
                          data-test-id='date-input'
                          mask="9999 9999 9999 9999"
                          value={values.card}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={false}
                          maskChar=" "
                        >
                          {() => <TextField
                            name='card'
                            type="text"
                            fullWidth={true}
                            value={values.card}
                            variant="outlined"
                            className="inputeStyle"
                            placeholder={"Enter card number"}
                            error={isCardError}
                          />}
                        </InputMask>
                        <Typography className="txtErrorMsg">{touched.card && card}</Typography>
                      </Box>
                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">Card Holder Name</InputLabel>
                          <InputLabel className="test-star">{` `}*</InputLabel>
                        </Box>
                        <TextField
                          name='name'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          variant="outlined"
                          className="inputeStyle"
                          data-test-id="customer-name"
                          placeholder={"Enter card holder name"}
                          error={isNameError}
                        />
                        <Typography className="txtErrorMsg">{touched.name && name}</Typography>
                      </Box>
                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">CVV</InputLabel>
                          <InputLabel className="test-star">{` `}*</InputLabel>
                        </Box>
                        <TextField
                          data-test-id='cvv-input'
                          name='cvv'
                          type='password'
                          fullWidth={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cvv}
                          variant="outlined"
                          className="inputeStyle"
                          placeholder={"Enter CVV"}
                          error={isCVVError}
                        />
                        <Typography className="txtErrorMsg">{touched.cvv && cvv}</Typography>
                        {!this.state.paymentModalUpdateMode && <Box
                          className="remember"
                        >
                          <Checkbox
                            name='saveCard'
                            onChange={handleChange}
                            value={values.saveCard}
                            data-test-id='checkbox-save-item'
                            icon={<img src={imgCheckbox} alt='checkbox' />}
                            checkedIcon={<img src={imgCheckboxChecked} alt='checkbox' />}
                          />
                          <span style={{ fontSize: '14px', color: '#64748B' }}>Save my card details</span>
                        </Box>}
                      </Box>
                      <Box style={styles.inputBlock}>
                        <Box sx={styles.inputTitle}>
                          <InputLabel className="test-label">Expiration Date</InputLabel>
                          <InputLabel className="test-star">{` `}*</InputLabel>
                        </Box>

                        <InputMask
                          data-test-id='expiry-date-input'
                          mask="99/99"
                          value={values.expiry}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={false}
                          maskChar=" "
                        >
                          {() => <TextField
                            name='expiry'
                            fullWidth={true}
                            variant="outlined"
                            className="inputeStyle"
                            placeholder={"MM / YY"}
                            error={isExpiryError}
                          />}
                        </InputMask>
                        <Typography className="txtErrorMsg">{touched.expiry && expiry}</Typography>
                      </Box>
                    </Box>

                    <Box p={3}>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        width='50%'
                        marginLeft='auto'>
                        <Button
                          data-test-id='cancel-btn'
                          variant="contained"
                          color="primary"
                          onClick={() => this.setPaymentModal(false)}
                          className="declineButton"
                        >
                          Cancel
                        </Button>
                        <Button
                          data-test-id='submit-btn'
                          type='submit'
                          variant="contained"
                          color="primary"
                          className="agreeButton"
                          onClick={submitForm}
                        >
                          {buttonText}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )
              }}

            </Formik>
          </TypographyView>
        </Modal >
      </TypographyView >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
