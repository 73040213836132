import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';

export default class EmailAccountLogInAdapter {
  send: (message: Message) => void;

  constructor() {
    // const uuidv4 = require('uuid/v4');
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationEmailLogInMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SpaProfileSuccessMessage),
      getName(MessageEnum.NavigateReviewsMessage),
      getName(MessageEnum.NavigateLandingPageVenue)
    ]);
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));

    to.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      from.getData(getName(MessageEnum.NavigationScreenNameMessage))
    );

    if (from.id === getName(MessageEnum.AccoutLoginSuccess)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LandingPage'
      );
      }else if(from.id === getName(MessageEnum.SpaProfileSuccessMessage)) {
        to.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'SpaUserProfile'
        )
    } else if (from.id === getName(MessageEnum.NavigationEmailLogInMessage)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'EmailAccountLoginBlock'
      );
    } else if (from.id === getName(MessageEnum.NavigateReviewsMessage)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'Reviews'
      );
    } else if (from.id === getName(MessageEnum.NavigateLandingPageVenue)) {
      to.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'LandingPageVenue'
      );
    }

    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      from.getData(getName(MessageEnum.NavigationPropsMessage))
    );

    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(
      getName(MessageEnum.NavigationPayLoadMessage),
      from.getData(getName(MessageEnum.NavigationPayLoadMessage))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
