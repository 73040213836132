export const img1475048886HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048886HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048908HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048908HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048909HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048909HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048911HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048911HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048912HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048912HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048914HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048914HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048915HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048915HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048917HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048917HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048918HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048918HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048925HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048925HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048939HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048939HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048940HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048940HTpe556x7cBoLvN2ZuRRme.svg');
export const img1475048958HTpe556x7cBoLvN2ZuRRme = require('../assets/1475048958HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI14750489301945250HTpe556x7cBoLvN2ZuRRme = require('../assets/I14750489301945250HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI14750489311945250HTpe556x7cBoLvN2ZuRRme = require('../assets/I14750489311945250HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895236797486HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895236797486HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895336797485HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895336797485HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895336797486HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895336797486HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895436797485HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895436797485HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895436797486HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895436797486HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895536797486HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895536797486HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895636797486HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895636797486HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504895736797486HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504895736797486HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI1475048969142885542HTpe556x7cBoLvN2ZuRRme = require('../assets/I1475048969142885542HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI1475048970142885542HTpe556x7cBoLvN2ZuRRme = require('../assets/I1475048970142885542HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI1475048971142885542HTpe556x7cBoLvN2ZuRRme = require('../assets/I1475048971142885542HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI1475048972142885542HTpe556x7cBoLvN2ZuRRme = require('../assets/I1475048972142885542HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI14750489231266030708HTpe556x7cBoLvN2ZuRRme = require('../assets/I14750489231266030708HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI147504892311207122221944603HTpe556x7cBoLvN2ZuRRme = require('../assets/I147504892311207122221944603HTpe556x7cBoLvN2ZuRRme.svg');
export const imgI1475048923112071222196128147HTpe556x7cBoLvN2ZuRRme = require('../assets/I1475048923112071222196128147HTpe556x7cBoLvN2ZuRRme.svg');
export const img962c7d73186b2cd6f245df742bac938b = require('../assets/962c7d73186b2cd6f245df742bac938b.jpg');
export const imgArrowRight = require('../assets/arrow-right.png');
export const imgAdvLayer01 = require('../assets/adv-layer01.png');
export const imgAdvLayer02 = require('../assets/adv-layer02.png');
export const imgAdvLayer03 = require('../assets/adv-layer03.png');
export const imgLocationSearch = require('../assets/location-search.svg');
export const imgMapImage = require('../assets/map-image.png');
export const imgPendingClock = require('../assets/pending-clock.svg');
export const imgVenue01 = require('../assets/venue01.png');
export const imgVenue02 = require('../assets/venue02.png');
export const imgVenue03 = require('../assets/venue03.png');
export const imgVenue04 = require('../assets/venue04.png');
export const imgVenue05 = require('../assets/venue05.png');
export const imgCarouselArrowBack = require('../assets/carousel-arrow-back.svg');
export const imgCarouselArrowNext = require('../assets/carousel-arrow-next.svg');
export const imgDollarSign = require('../assets/dollarSign.svg');
export const imgArrowDownTriangle = require('../assets/arrow-down-triangle.svg');
export const imgMapVenue = require('../assets/map-venue.jpg');
export const image_venue = require('../assets/image_venue.svg');
export const image_venue_1 = require('../assets/image_venue (1).svg');
export const image_venue_2 = require('../assets/image_venue (2).svg');
export const image_venue_3 = require('../assets/image_venue (3).svg');
export const image_venue_4 = require('../assets/image_venue (4).svg');
export const image_venue_5 = require('../assets/image_venue (5).svg');
export const image_star = require("../assets/image.svg");
export const left_arrow = require('../assets/leftArrow.png');
export const right_arrow = require('../assets/rightArrow.png');
export const categoryDefaultImage = require('../assets/cat_03.jpg');
export const startBooking = require('../assets/start-booking.png');
