Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.txtBusinessLicense = "Business Licenses";
exports.txtCertification = "Certifications";
exports.txtAddOtherFiles = "Add Files";
exports.invalidFileErrorMsg = "Please upload a valid file type";
exports.updateApiMethod = "PUT";
exports.saveApiMethod = "POST";
exports.availabilityApiEndPoint = "bx_block_appointment_management/availabilities";
exports.availabilityUpdateApiEndPoint = "bx_block_appointment_management/update_availabilies"
// Customizable Area End
