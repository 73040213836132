import React from "react";
// Customizable Area Start
import { Box, Button, InputLabel, TextField, Typography, styled } from '@material-ui/core';
import { profileCover } from "./assets"
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { BookingWrapper } from "../../ordermanagement1/src/components/BookingWrapper";
import ConfirmationModal from "../../../components/src/ConfirmationModal";
import UpdateDetailsModal from "./components/UpdateDetailsModal";
import { UpdateType } from './SpaOwnerSettings.web';
import { TypographyView } from '../../../components/src/styles';
import { getTranslationConfig } from '../../../components/src/helpers';
import { AccountButtons } from './components/AccountButtons';

export const UpdateButton = styled(Button)({
    backgroundColor: '#E2E8F0',
    borderRadius: '20px',
    color: '#5500CC',
    padding: '5px 15px',
    textTransform: 'none',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Ubuntu",
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
});

export const DeleteButton = styled(Button)({
    backgroundColor: '#398378',
    borderRadius: '8px',
    color: 'white',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Ubuntu",
    padding: '16px',
    width: "20rem",
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#398378',
    },
});

export const LogoutButton = styled(Button)({
    backgroundColor: '#D1FAE5',
    borderRadius: '8px',
    color: '#047857',
    textTransform: 'none',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Ubuntu",
    padding: '16px',
    width: "20rem",
    '&:hover': {
        backgroundColor: '#A7F3D0',
    },
});

// Customizable Area End


class CutomerSettings extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t, dir } = getTranslationConfig();
        const customStyles = {
            background: `linear-gradient(
                rgba(0, 0, 0, 0.2), 
                rgba(0, 0, 0, 0.5)
              ), url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
            inputBlock: {
                display: "flex",
                alignItems: "center",
                gridGap: 56
            }
        };

        const { openConfirmationModal } = this.state;

        return (
            <TypographyView>
                <Box
                    bgcolor="#D9EEEB"
                >
                    <BookingWrapper
                        forceUpdate={this.forceUpdate.bind(this)}
                        navigation={this.props.navigation}
                        backgroundImage={profileCover}
                        heading={t['profile-settings-heading']}
                        breadcrumbs={[t['breadcrumbs-home'], t['breadcrumbs-my-profile'], t['breadcrumbs-settings']]}
                        customStyles={customStyles}
                        context={this}
                    >
                        <Box
                            dir={dir}
                            bgcolor="#F8FFFE"
                            marginTop={4}
                            data-testid="toggle-button-box"
                            p={6} px={12}>
                            <Box>
                                <Typography style={{
                                    fontFamily: "Ubuntu", fontWeight: 700, lineHeight: "32px",
                                    fontSize: "24px", letterSpacing: "-0.12px"
                                }}>
                                    {t['profile-settings-title']}
                                </Typography>
                            </Box>
                            <Box mt={4}>
                                <Box >
                                    <InputLabel style={{
                                        fontFamily: "Ubuntu", fontWeight: 700,
                                        lineHeight: "24px",
                                        fontSize: "16px",
                                        color: "#334155",
                                        marginBottom: "-9px"
                                    }}>
                                        {t['profile-settings-email']}
                                    </InputLabel>
                                    <Box {...customStyles.inputBlock} >
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            name="email"
                                            style={{
                                                width: "400px"
                                            }}
                                            value={
                                                this.state.email ||
                                                this.state.userDetails.attributes.email ||
                                                ''
                                            }
                                        />
                                        <UpdateButton
                                            data-test-id="email-input"
                                            style={{ marginLeft: "1rem", fontWeight: 400 }}
                                            onClick={() => this.handleOpenUpdateModal(UpdateType.email)}
                                        >
                                            <Typography style={{ fontWeight: 400 }}
                                            >{t['profile-settings-change-btn']}</Typography>

                                        </UpdateButton>
                                    </Box>
                                </Box>
                                <Box >
                                    <InputLabel
                                        style={{
                                            fontFamily: "Ubuntu",
                                            fontWeight: 700,
                                            lineHeight: "24px",
                                            fontSize: "16px",
                                            color: "#334155",
                                            marginTop: "1rem",
                                            marginBottom: "-9px"
                                        }}
                                    >
                                        {t['profile-settings-password']}
                                    </InputLabel>
                                    <Box {...customStyles.inputBlock}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            type="password"
                                            name="password"
                                            value={"*******"}
                                            style={{
                                                width: "400px"
                                            }}
                                        />
                                        <UpdateButton style={{ marginLeft: "1rem", fontWeight: 400 }}
                                            data-test-id="update-password-button"
                                            onClick={() => this.handleOpenUpdateModal(UpdateType.password)}
                                        >
                                            <Typography style={{ fontWeight: 400 }}
                                            >
                                                {t['profile-settings-upd-password-btn']}
                                            </Typography>
                                        </UpdateButton>
                                    </Box>
                                </Box>
                                <AccountButtons
                                    t={t}
                                    handleOpenModal={this.handleOpenModal}
                                    handleLogout={this.handleLogout}
                                    handleDeleteAccount={this.handleDeleteAccount}
                                />
                            </Box>
                        </Box>
                    </BookingWrapper>
                    <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={this.setOpenConfirmationModal}
                        modalConfig={this.state.modalConfig}
                    />
                    <UpdateDetailsModal
                        openUpdateModal={this.state.openUpdateModal}
                        setOpenUpdateModal={this.handleCloseUpdateModal}
                        updateType={this.state.updateType}
                        navigation={undefined}
                        id={""}
                    />
                </Box>
            </TypographyView>
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
export default CutomerSettings;
// Customizable Area End


